import { createSelector } from 'reselect'

import {
  getCompanyIdFromStorage,
} from '@utils/local-storage.utils'

import { State } from '@redux/modules/types'

import { DEFAULT_LOCALE_SHORT, SHORT_LOCALES } from '@constants/locales.constants'

import {
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,

  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  REASSIGN_USER,
  CHANGE_USER_LANGUAGE,

  CLONE_COMPANY,
  REQUEST_COMPANY_USERS_LIST,
  REQUEST_COMPANIES_LIST,
  REQUEST_COMPANY_ITEM,
} from './customer.action-types'

/**
 * @function getSelectedCustomerId Returns customer id
 *
 * @param {Object} state Redux State
 *
 * @return {String} Return customer id
 */
export const getSelectedCustomerId = (state: State) => {
  const userInfo = getCurrentUserInfo(state)
  const currentUserId = userInfo?.sub || ''

  return currentUserId
}

/**
 * @function getSelectedCompanyId Returns company id
 *
 * @param {Object} state Redux State
 *
 * @return {String} Return company id
 */
export const getSelectedCompanyId = (state: State) => {
  const userInfo = getCurrentUserInfo(state)
  const currentUserId = userInfo?.sub || ''
  const selectedCompanyId = getCompanyIdFromStorage(currentUserId)

  return selectedCompanyId || state.customer.companyId
}

/**
 * @function getSelectedLanguage Returns user language
 *
 * @param {Object} state Redux State
 *
 * @return {String} Returns user language
 */
export const getSelectedLanguage = (state: State) => {
  const userInfo = getCurrentUserInfo(state)
  const userLanguage = userInfo.language as SHORT_LOCALES

  return userLanguage || DEFAULT_LOCALE_SHORT
}

/**
 * @function getIntlMessages Returns react-intl messages
 *
 * @param {Object} state Redux State
 *
 * @return {String} react-intl messages
 */
export const getIntlMessages = (state: State) => {
  return state.customer.intlMessages
}

/**
 * @function getCurrentUserInfo Returns current user details
 *
 * @param {Object} state Redux State
 *
 * @return {Object} User details
 */
export const getCurrentUserInfo = (state: State) => {
  return state.customer.userInfo
}

/**
 * @function getCompanyDetails Returns company details
 *
 * @param {Object} state Redux State
 *
 * @return {Object} company details
 */
export const getCompanyDetails = (state: State) => {
  return state.customer.companyItem
}

/**
 * @function getIsAdmin Returns 'true', if user is admin
 *
 * @param {Object} state Redux State
 *
 * @return {Boolean} Returns 'true', if user is admin
 */
export const getIsAdmin = (state: State) => {
  return state.customer.userInfo.isAdmin
}

/**
 * @function getIsChatUser Returns 'true', if user allowed to use chat
 *
 * @param {Object} state Redux State
 *
 * @return {Boolean} Returns 'true' if user allowed to use chat
 */
export const getIsChatUser = (state: State) => {
  return state.customer.userInfo.isChatUser
}

/**
 * @function getCompaniesList Returns list of companies (ADMIN ONLY)
 *
 * @param {Object} state Redux State
 *
 * @return {Array} Companies List
 */
export const getCompaniesList = (state: State) => {
  return state.customer.companiesList
}

/**
 * @function getSortedCompaniesList Returns sorted list of companies (ADMIN ONLY)
 *
 * @param {Object} state Redux State
 *
 * @return {Array} Sorted companies List
 */
export const getSortedCompaniesList = (state: State) => {
  const list = [...(getCompaniesList(state) || [])]

  return list.sort((a, b) => {
    const inputA = a.name.toUpperCase()
    const inputB = b.name.toUpperCase()

    if (inputA < inputB) {
      return -1
    }
    if (inputA > inputB) {
      return 1
    }

    return 0
  })
}

/**
 * @function getCompanyUsersList Returns list of company users
 *
 * @param {Object} state Redux State
 *
 * @return {Array} Company User List
 */
export const getCompanyUsersList = (state: State) => {
  return state.customer.companyUsersList
}

/**
 * @function getCompanyDetailsById Returns company details by company id
 * Memoized selector that returns company details either from companyItem or companiesList
 *
 * @param {Object} state Redux State
 * @param {String} companyId Company id
 *
 * @return {Object} Company details
 */
export const getCompanyDetailsById = createSelector(
  [getCompanyDetails, getCompaniesList, (_state: State, companyId: string) => companyId],
  (companyItem, companiesList, companyId) => {
    if (companyItem && companyItem.companyId === companyId) {
      return companyItem
    }

    return companiesList.find((company) => company.companyId === companyId)
  },
)

/**
 * @function getCompanyNameById Returns company name by company id
 *
 * @param {Object} state Redux State
 * @param {String} companyId Company id
 *
 * @return {String} Company name
 */
export const getCompanyNameById = createSelector(
  [getCompanyDetailsById, (_state: State, companyId: string) => companyId],
  (companyDetails, companyId) => {
    return companyDetails?.name
  })

/**
 * @function getSelectedCompanyName Returns name of the selected company
 *
 * @param {Object} state Redux State
 *
 * @return {String} Selected company name
 */
export const getSelectedCompanyName = createSelector(
  [
    (state: State) => state,
    getSelectedCompanyId,
  ],
  (state, companyId) => getCompanyDetailsById(state, companyId)?.name,
)

/**
 * @function isFetching Returns 'true', if fetching
 *
 * @param {Object} state Redux State
 *
 * @return {Boolean} Returns 'true', if fetching
 */
export const isFetching = (state: State) => {
  const keysToOmit = [
    CREATE_COMPANY,
    UPDATE_COMPANY,
    DELETE_COMPANY,
    CLONE_COMPANY,
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER,
    REASSIGN_USER,
    CHANGE_USER_LANGUAGE,
  ]

  return state.customer.fetchingKeys.some((key) => !keysToOmit.includes(key))
}

/**
 * @function isSubmitting Returns 'true', if submitting
 *
 * @param {Object} state Redux State
 *
 * @return {Boolean} Returns 'true', if submitting
 */
export const isSubmitting = (state: State) => {
  const keys = [
    CREATE_COMPANY,
    UPDATE_COMPANY,
    DELETE_COMPANY,
    CLONE_COMPANY,
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER,
    REASSIGN_USER,
    CHANGE_USER_LANGUAGE,
  ]

  return state.customer.fetchingKeys.some((key) => keys.includes(key))
}

export const isFetchingCompanyUsersList = (state: State) => {
  return state.customer.fetchingKeys.includes(REQUEST_COMPANY_USERS_LIST)
}

export const isFetchingCompaniesList = (state: State) => {
  return state.customer.fetchingKeys.includes(REQUEST_COMPANIES_LIST)
}

export const isFetchingCompany = (state: State) => {
  return state.customer.fetchingKeys.includes(REQUEST_COMPANY_ITEM)
}
