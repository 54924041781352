import { cyan, green, orange } from '@mui/material/colors'
import { PaletteOptions } from '@mui/material/styles'

export interface PaletteOptionsExtended extends PaletteOptions {
  new: NewPalette,
  colorWay: {
    [key: string]: string,
  },
}

export interface NewPalette {
  black: string,
  black_a: string,
  black_b: string,
  black_d: string,
  pink: string,
  carret: string,
  violet: string,
  violet_a: string,
  pastel_violet: string,
  mixed_violet: string,
  light_violet: string,
  grey: string,
  grey_a: string,
  grey_c: string,
  grey_d: string,
  grey_e: string,
  grey_f: string,
  grey_g: string,
  grey_h: string,
  grey_j: string,
  grey_k: string,
  success: string,
  error: string,

  transparent: string,
  business_black: string,
  business_black_5: string,
  business_black_10: string,
  business_black_20: string,
  business_black_40: string,
  business_black_60: string,

  white: string,
  white_10: string,
  white_20: string,
  white_60: string,

  rebellious_red: string,
  rebellious_red_10: string,
  rebellious_red_40: string,

  smokey_silver: string,
  smokey_silver_5: string,

  versatile_violet: string,
  versatile_violet_5: string,
  versatile_violet_10: string,
  versatile_violet_20: string,

  youthful_yellow: string,
  youthful_yellow_10: string,
  gainful_grey: string,
  gainful_grey_20: string,

  popular_pink: string,
  popular_pink_10: string,

  generous_green: string,
  generous_green_10: string,

  talkative_turquoise: string,
  talkative_turquoise_10: string,
}

const palette: PaletteOptionsExtended = {
  primary: {
    main: '#000000',
  },
  secondary: {
    main: '#ff5252',
  },
  success: {
    main: green[500],
    light: green[300],
    dark: green[700],
  },
  warning: {
    main: orange[500],
    light: orange[300],
    dark: orange[700],
  },
  info: {
    main: cyan[500],
    light: cyan[300],
    dark: cyan[700],
  },
  error: {
    main: '#f44336',
    light: '#e57373',
    dark: '#d32f2f',
  },
  new: {
    black: '#000000',
    black_a: '#09121F',
    black_b: '#505050',
    black_d: '#999999',
    pink: '#F500E1',
    carret: '#F06DAC',
    violet: '#6031C8',
    violet_a: '#7B47EE',
    pastel_violet: '#BB9CFF',
    mixed_violet: '#4B22C8',
    light_violet: '#A67FFF',
    grey: '#F6F6F6',
    grey_a: '#C4C4C4',
    grey_c: '#707070',
    grey_d: '#F5F5F5',
    grey_e: '#D6D6D6',
    grey_f: '#F7F7F7',
    grey_g: '#E8E8E8',
    grey_h: '#666666',
    grey_j: '#D9D9D9',
    grey_k: '#E5E5E5',
    success: '#4caf50',
    error: '#ff5252',

    transparent: 'transparent',
    business_black_60: '#666666',
    business_black_40: '#999999',
    business_black_20: '#CCCCCC',
    business_black_10: '#0000001A',
    business_black_5: '#0000000D',
    business_black: '#000000',

    smokey_silver: '#F2F2F2',
    smokey_silver_5: '#F2F2F20D',

    white: '#FFFFFF',
    white_10: '#FFFFFF1A',
    white_20: '#FFFFFF33',
    white_60: '#FFFFFF99',

    versatile_violet: '#5F26E0',
    versatile_violet_5: '#F7F4FD',
    versatile_violet_10: '#EFE9FC',
    versatile_violet_20: '#DFD4F9',

    generous_green: '#00C04D',
    generous_green_10: '#00C04D1A',

    talkative_turquoise: '#51D7E6',
    talkative_turquoise_10: '#51D7E61A',

    rebellious_red: '#FB6C4A',
    rebellious_red_10: '#FFF0ED',
    rebellious_red_40: '#FEC4B7',

    gainful_grey: '#AAA3CC',
    gainful_grey_20: '#EFEEF5',

    youthful_yellow: '#FBB03B',
    youthful_yellow_10: '#FBB03B1A',

    popular_pink: '#F500E1',
    popular_pink_10: '#FEE5FC',
  },
  colorWay: {
    0: '#9F7DED',
    1: '#96E7F0',
    2: '#FDA792',
    3: '#66D994',
    4: '#FDD089',
    5: '#999999',
    6: '#FC896E',
    7: '#51D7E6',
    8: '#666666',
    9: '#33CD71',
  },
}

export default palette
