import { Reducer, combineReducers } from 'redux'
import { RouterState } from 'redux-first-history'

import artifacts from './artifacts/artifacts.index'
import hermes from './hermes/hermes.index'
import chat from './chat/chat.index'
import common from './common/common.index'
import customer from './customer/customer.index'
import parameters from './parameters/parameters.index'
import monitor from './monitor/monitor.index'
import pipelines from './pipelines/pipelines.index'
import useCase from './use-case/use-case.index'
import modalManager from './modal-manager/modal-manager.index'
import trainingFiles from './training-files/training-files.index'
import parametersTemplates from './parameters-templates/parameters-templates.index'
import fileService from './file-service/file-service.index'
import recommendationArtifacts from './recommendation-artifacts/recommendation-artifacts.index'
import analyze from './analyze/analyze.index'
import notifications from './notifications/notifications.index'
import snapshots from './snapshots/snapshots.index'
import insights from './insights/insights.index'

const createRootReducer = (routerReducer: Reducer<RouterState>) => combineReducers({
  monitor,
  artifacts,
  analyze,
  chat,
  hermes,
  parameters,
  common,
  pipelines,
  customer,
  useCase,
  router: routerReducer,
  modalManager,
  trainingFiles,
  parametersTemplates,
  fileService,
  recommendationArtifacts,
  notifications,
  snapshots,
  insights,
})

export default createRootReducer
