import {
  put,
  call, delay,
  takeLatest,
  take,
  race,
  select,
} from 'redux-saga/effects'

import { SAGA_USE_CASE_FETCHING_RACE_TIMEOUT_IN_MS, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload } from '@redux/modules/types'
import { NOTIFICATIONS_PULL_INTERVAL_IN_MS } from '@constants/notifications.constants'

import {
  SEND_NOTIFICATION,
  REQUEST_NOTIFICATIONS,
  REQUEST_DISPATCHED_NOTIFICATIONS,

  MARK_NOTIFICATIONS_AS_READ,
  DISMISS_NOTIFICATIONS,

  REQUEST_NOTIFICATIONS_SINKS,
  CREATE_NOTIFICATIONS_SINK,
  UPDATE_NOTIFICATIONS_SINK,
  REMOVE_NOTIFICATIONS_SINK,
  TEST_NOTIFICATIONS_SINK,

  REQUEST_NOTIFICATIONS_ROUTINGS,
  SETUP_NOTIFICATIONS_ROUTINGS,
  CREATE_NOTIFICATIONS_ROUTINGS,
  UPDATE_NOTIFICATIONS_ROUTINGS,
  REMOVE_NOTIFICATIONS_ROUTINGS,
  REQUEST_NOTIFICATIONS_ROUTING_GRAPH,
  REQUEST_UNREAD_NOTIFICATIONS_COUNT,

  REQUEST_NOTIFICATIONS_TEMPLATES,
  CREATE_NOTIFICATIONS_TEMPLATE,
  UPDATE_NOTIFICATIONS_TEMPLATE,
  REMOVE_NOTIFICATIONS_TEMPLATE,
  RECEIVE_NOTIFICATIONS_SINKS,
  RECEIVE_NOTIFICATIONS_ROUTINGS,
  RECEIVE_DISPATCHED_NOTIFICATIONS,
  CHANGE_NOTIFICATIONS_PAGINATION_META,
  MARK_NOTIFICATIONS_ALL_AS_READ,
  RECEIVE_NOTIFICATIONS_TEMPLATES,
} from './notifications.action-types'

import {
  startFetchingNotificationsAction,
  stopFetchingNotificationsAction,
  receiveUnreadNotificationsCountAction,
  receiveNotificationsAction,
  receiveDispatchedNotificationsAction,
  receiveNotificationsRoutingsAction,
  receiveNotificationsTemplatesAction,
  receiveNotificationsSinksAction,
  receiveNotificationsRoutingGraphAction,
  requestNotificationsSinksAction,
  requestNotificationsRoutingsAction,
  changeNotificationsPaginationMetaAction,
  requestDispatchedNotificationsAction,
  requestUnreadNotificationsCountAction,
  receiveNotificationsPaginationMetaAction,
  requestNotificationsTemplatesAction,
} from './notifications.actions'

import * as API from './notifications.api'

import { setPrimaryModalPageName } from '../modal-manager/modal-manager.actions'
import { RECEIVE_USE_CASES } from '../use-case/use-case.action-types'
import { getUseCasesList } from '../use-case/use-case.selectors'

export const MARK_NOTIFICATIONS_ALL_AS_READ_MESSAGE_ID = 'notifications.modal.overview.markAllAsReadSuccess'
export const DISMISS_NOTIFICATIONS_MESSAGE_ID = 'notifications.modal.overview.dismissSuccess'
export const MARK_NOTIFICATIONS_AS_READ_MESSAGE_ID = 'notifications.modal.overview.markAsReadSuccess'
export const SEND_NOTIFICATION_MESSAGE_ID = 'notifications.modal.overview.sendNotificationSuccess'
export const SETUP_NOTIFICATIONS_ROUTINGS_MESSAGE_ID = 'notifications.modal.overview.setupNotificationsRoutingsSuccess'
export const CREATE_NOTIFICATIONS_ROUTINGS_MESSAGE_ID = 'notifications.modal.overview.createNotificationsRoutingsSuccess'
export const UPDATE_NOTIFICATIONS_ROUTINGS_MESSAGE_ID = 'notifications.modal.overview.updateNotificationsRoutingsSuccess'
export const REMOVE_NOTIFICATIONS_ROUTINGS_MESSAGE_ID = 'notifications.modal.overview.removeNotificationsRoutingsSuccess'
export const CREATE_NOTIFICATIONS_TEMPLATE_MESSAGE_ID = 'notifications.modal.overview.createNotificationsTemplateSuccess'
export const UPDATE_NOTIFICATIONS_TEMPLATE_MESSAGE_ID = 'notifications.modal.overview.updateNotificationsTemplateSuccess'
export const REMOVE_NOTIFICATIONS_TEMPLATE_MESSAGE_ID = 'notifications.modal.overview.removeNotificationsTemplateSuccess'
export const CREATE_NOTIFICATIONS_SINK_MESSAGE_ID = 'notifications.modal.overview.createNotificationsSinkSuccess'
export const UPDATE_NOTIFICATIONS_SINK_MESSAGE_ID = 'notifications.modal.overview.updateNotificationsSinkSuccess'
export const REMOVE_NOTIFICATIONS_SINK_MESSAGE_ID = 'notifications.modal.overview.removeNotificationsSinkSuccess'
export const TEST_NOTIFICATIONS_SINK_MESSAGE_ID = 'notifications.modal.overview.testNotificationsSinkSuccess'

export function* changeNotificationsPaginationMetaGenerator({ payload }: ActionPayload<Notifications.NotificationsPaginationMetaAction>) {
  yield put(receiveNotificationsPaginationMetaAction(payload))
}

export function* markNotificationsAllAsReadGenerator({ payload }: ActionPayload<Notifications.NotificationsMarkAllAsReadRequest>) {
  try {
    yield put(startFetchingNotificationsAction(MARK_NOTIFICATIONS_ALL_AS_READ))

    if (payload.useCaseId) {
      yield call(API.markUserNotificationsAsRead, { useCaseId: payload.useCaseId })
    } else {
      yield call(API.markCompanyNotificationsAsRead, { companyId: payload.companyId })
    }

    yield put(changeNotificationsPaginationMetaAction({
      page: 1,
      filter: 'all',
    }))

    yield put(requestUnreadNotificationsCountAction({
      companyId: payload.companyId,
    }))

    yield put(changeToastAction({ message: MARK_NOTIFICATIONS_ALL_AS_READ_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(MARK_NOTIFICATIONS_ALL_AS_READ))
  }
}

export function* requestUnreadNotificationsCountGenerator({ payload }: ActionPayload<Notifications.APIGetUnreadNotificationsCountRequest>) {
  while (true) {
    try {
      yield put(startFetchingNotificationsAction(REQUEST_UNREAD_NOTIFICATIONS_COUNT))

      const count: number = yield call(API.getUnreadNotificationsCount, payload)

      yield put(receiveUnreadNotificationsCountAction(count))
    } catch (e) {
      const message = parseAndReportErrorResponse(e, payload)

      yield put(receiveUnreadNotificationsCountAction(0))

      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    } finally {
      yield put(stopFetchingNotificationsAction(REQUEST_UNREAD_NOTIFICATIONS_COUNT))

      yield delay(NOTIFICATIONS_PULL_INTERVAL_IN_MS)
    }
  }
}

export function* requestNotificationsGenerator({ payload }: ActionPayload<Notifications.RequestNotificationsAction>) {
  try {
    yield put(startFetchingNotificationsAction(REQUEST_NOTIFICATIONS))

    const response: Notifications.CompanyNotificationsResponse = (!payload.useCaseId || (payload.useCaseId === 'all')) ?
      yield call(API.listCompanyNotifications, payload) :
      yield call(API.listUseCaseNotifications, {
        useCaseId: payload.useCaseId,
        page: payload.page,
        pageSize: payload.pageSize,
        read: payload.read,
      })

    const unreadCount: number = yield call(API.getUnreadNotificationsCount, {
      companyId: payload.companyId,
      useCaseId: payload.useCaseId,
    })

    yield put(receiveNotificationsAction({
      items: response.items,
      total: response.total,
      unreadCount,
    }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(receiveNotificationsAction([]))

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(REQUEST_NOTIFICATIONS))
  }
}

export function* markNotificationsAsReadGenerator({ payload }: ActionPayload<Notifications.MarkNotificationsAsReadAction>) {
  try {
    yield put(startFetchingNotificationsAction(MARK_NOTIFICATIONS_AS_READ))

    yield call(API.markNotificationsAsRead, {
      notificationIds: payload.notificationIds,
    })

    yield put(requestUnreadNotificationsCountAction({
      companyId: payload.companyId,
    }))

    yield put(changeNotificationsPaginationMetaAction({
      page: 1,
      filter: 'all',
    }))

    yield put(changeToastAction({ message: MARK_NOTIFICATIONS_AS_READ_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(MARK_NOTIFICATIONS_AS_READ))
  }
}

export function* dismissNotificationsGenerator({ payload }: ActionPayload<Notifications.DismissNotificationsAction>) {
  try {
    yield put(startFetchingNotificationsAction(DISMISS_NOTIFICATIONS))

    yield call(API.dismissNotifications, {
      notificationIds: payload.notificationIds,
    })

    yield put(requestUnreadNotificationsCountAction({
      companyId: payload.companyId,
    }))

    yield put(changeNotificationsPaginationMetaAction({
      page: 1,
      filter: 'all',
    }))

    yield put(changeToastAction({ message: DISMISS_NOTIFICATIONS_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(DISMISS_NOTIFICATIONS))
  }
}

export function* requestNotificationsRoutingGraphGenerator({ payload }: ActionPayload<Notifications.GetRoutingGraphRequest>) {
  try {
    yield put(startFetchingNotificationsAction(REQUEST_NOTIFICATIONS_ROUTING_GRAPH))

    const response: Notifications.NotificationGraphResponse = yield call(API.getRoutingGraph, payload)

    yield put(receiveNotificationsRoutingGraphAction(response))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(receiveNotificationsRoutingGraphAction([]))

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(REQUEST_NOTIFICATIONS_ROUTING_GRAPH))
  }
}

export function* setupNotificationsRoutingsGenerator({ payload }: ActionPayload<Notifications.SetupNotificationsRoutingsAction>) {
  try {
    yield put(startFetchingNotificationsAction(SETUP_NOTIFICATIONS_ROUTINGS))

    yield call(API.setupDefaultRoutings, payload)

    yield put(requestNotificationsRoutingsAction({
      companyId: payload.companyId,
      useCaseId: payload.useCaseId,
      page: 1,
    }))

    yield take(RECEIVE_NOTIFICATIONS_ROUTINGS)

    yield put(setPrimaryModalPageName(''))

    yield put(changeToastAction({ message: SETUP_NOTIFICATIONS_ROUTINGS_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(SETUP_NOTIFICATIONS_ROUTINGS))
  }
}

export function* createNotificationsRoutingsGenerator({ payload }: ActionPayload<Notifications.CreateRoutingAction>) {
  try {
    yield put(startFetchingNotificationsAction(CREATE_NOTIFICATIONS_ROUTINGS))

    yield call<any>(API.createRouting, payload)

    yield put(requestNotificationsRoutingsAction({
      companyId: payload.companyId,
      useCaseId: payload.useCaseId,
      page: 1,
    }))

    yield take(RECEIVE_NOTIFICATIONS_ROUTINGS)

    yield put(setPrimaryModalPageName(''))

    yield put(changeToastAction({ message: CREATE_NOTIFICATIONS_ROUTINGS_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(CREATE_NOTIFICATIONS_ROUTINGS))
  }
}

export function* updateNotificationsRoutingsGenerator({ payload }: ActionPayload<Notifications.UpdateRoutingAction>) {
  try {
    yield put(startFetchingNotificationsAction(UPDATE_NOTIFICATIONS_ROUTINGS))

    const { companyId, useCaseId, ...rest } = payload

    yield call(API.updateRouting, rest)

    yield put(requestNotificationsRoutingsAction({
      companyId,
      useCaseId,
      page: 1,
    }))

    yield take(RECEIVE_NOTIFICATIONS_ROUTINGS)

    yield put(setPrimaryModalPageName(''))

    yield put(changeToastAction({ message: UPDATE_NOTIFICATIONS_ROUTINGS_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(UPDATE_NOTIFICATIONS_ROUTINGS))
  }
}

export function* removeNotificationsRoutingsGenerator({ payload }: ActionPayload<Notifications.RemoveRoutingAction>) {
  try {
    yield put(startFetchingNotificationsAction(REMOVE_NOTIFICATIONS_ROUTINGS))

    const { companyId, useCaseId, ...rest } = payload

    yield call(API.deleteRouting, rest)

    yield put(requestNotificationsRoutingsAction({
      companyId,
      useCaseId,
      page: 1,
    }))

    yield take(RECEIVE_NOTIFICATIONS_ROUTINGS)

    yield put(changeToastAction({ message: REMOVE_NOTIFICATIONS_ROUTINGS_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(REMOVE_NOTIFICATIONS_ROUTINGS))
  }
}

export function* requestDispatchedNotificationsGenerator({ payload }: ActionPayload<Notifications.DispatchedNotificationsRequest>) {
  try {
    yield put(startFetchingNotificationsAction(REQUEST_DISPATCHED_NOTIFICATIONS))

    const response: Notifications.DispatchedNotificationsResponse = yield call(API.getDispatchedNotifications, payload)

    yield put(receiveDispatchedNotificationsAction(response.items))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(receiveDispatchedNotificationsAction([]))

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(REQUEST_DISPATCHED_NOTIFICATIONS))
  }
}

export function* requestNotificationsRoutingsGenerator({ payload }: ActionPayload<Notifications.NotificationsRoutingsRequest>) {
  try {
    yield put(startFetchingNotificationsAction(REQUEST_NOTIFICATIONS_ROUTINGS))

    const response: Notifications.NotificationsRoutingsResponse = yield call(API.listRoutings, payload)

    yield put(receiveNotificationsRoutingsAction(response.items))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(receiveNotificationsRoutingsAction([]))

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(REQUEST_NOTIFICATIONS_ROUTINGS))
  }
}

export function* requestNotificationsTemplatesGenerator({ payload }: ActionPayload<Notifications.NotificationsTemplatesRequest>) {
  try {
    yield put(startFetchingNotificationsAction(REQUEST_NOTIFICATIONS_TEMPLATES))

    const response: Notifications.NotificationsTemplatesResponse = yield call(API.listTemplates, payload)

    yield put(receiveNotificationsTemplatesAction(response.items))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(receiveNotificationsTemplatesAction([]))

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(REQUEST_NOTIFICATIONS_TEMPLATES))
  }
}

export function* requestNotificationsTemplatesCreateGenerator({ payload }: ActionPayload<Notifications.CreateNotificationTemplate>) {
  try {
    yield put(startFetchingNotificationsAction(CREATE_NOTIFICATIONS_TEMPLATE))

    yield call(API.createTemplate, payload)

    yield put(requestNotificationsTemplatesAction({
      page: 1,
    }))

    yield take(RECEIVE_NOTIFICATIONS_TEMPLATES)

    yield put(changeToastAction({ message: CREATE_NOTIFICATIONS_TEMPLATE_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))

    yield put(setPrimaryModalPageName(''))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(CREATE_NOTIFICATIONS_TEMPLATE))
  }
}

export function* requestNotificationsTemplatesUpdateGenerator({ payload }: ActionPayload<Notifications.UpdateNotificationTemplate>) {
  try {
    yield put(startFetchingNotificationsAction(UPDATE_NOTIFICATIONS_TEMPLATE))

    yield call(API.updateTemplate, payload)

    yield put(requestNotificationsTemplatesAction({
      page: 1,
    }))

    yield take(RECEIVE_NOTIFICATIONS_TEMPLATES)

    yield put(changeToastAction({ message: UPDATE_NOTIFICATIONS_TEMPLATE_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))

    yield put(setPrimaryModalPageName(''))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(UPDATE_NOTIFICATIONS_TEMPLATE))
  }
}

export function* requestNotificationsTemplatesRemoveGenerator({ payload }: ActionPayload<Notifications.RemoveNotificationTemplate>) {
  try {
    yield put(startFetchingNotificationsAction(REMOVE_NOTIFICATIONS_TEMPLATE))

    yield call(API.deleteTemplate, payload)

    yield put(requestNotificationsTemplatesAction({
      page: 1,
    }))

    yield take(RECEIVE_NOTIFICATIONS_TEMPLATES)

    yield put(changeToastAction({ message: REMOVE_NOTIFICATIONS_TEMPLATE_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))

    yield put(setPrimaryModalPageName(''))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(REMOVE_NOTIFICATIONS_TEMPLATE))
  }
}

export function* requestNotificationsSinksGenerator({ payload }: ActionPayload<Notifications.NotificationsSinksRequestAction>) {
  try {
    yield put(startFetchingNotificationsAction(REQUEST_NOTIFICATIONS_SINKS))

    const { enrichWithUseCaseDetails, ...rest } = payload
    const response: Notifications.NotificationsSinksResponse = yield call(API.listSinks, rest)
    const flatSinksItems = response.items.map((item) => {
      return {
        ...item,
        ...item.config,
        useCaseName: '',
        useCaseSlug: '',
      } as Notifications.NotificationSinkItemEnriched
    })

    /**
     * If the use-case id is not provided, we need to wait for the use-case list to be fetched.
     * We need to enrich the response with the use-case name and slug.
     */
    if (payload.enrichWithUseCaseDetails) {
      yield race({
        action: take(RECEIVE_USE_CASES),
        timeout: delay(SAGA_USE_CASE_FETCHING_RACE_TIMEOUT_IN_MS),
      })

      const useCasesList: UseCase.DetailsExtended[] = yield select(getUseCasesList)

      flatSinksItems.forEach((item) => {
        const useCaseDetails = useCasesList.find((useCaseItem) => useCaseItem.useCaseId === item.useCaseId)

        Object.assign(item, {
          useCaseName: useCaseDetails?.name,
          useCaseSlug: useCaseDetails?.fileStorageBucket,
        })
      })
    }

    yield put(receiveNotificationsSinksAction(flatSinksItems))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(receiveNotificationsSinksAction([]))

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(REQUEST_NOTIFICATIONS_SINKS))
  }
}

export function* requestNotificationsSinksCreateGenerator({ payload }: ActionPayload<Notifications.CreateNotificationsSinkRequest>) {
  try {
    yield put(startFetchingNotificationsAction(CREATE_NOTIFICATIONS_SINK))

    yield call(API.createSink, payload)

    yield put(requestNotificationsSinksAction({
      page: 1,
      companyId: payload.companyId,
      useCaseId: payload.useCaseId,
    }))

    yield take(RECEIVE_NOTIFICATIONS_SINKS)

    yield put(setPrimaryModalPageName(''))

    yield put(changeToastAction({ message: CREATE_NOTIFICATIONS_SINK_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(CREATE_NOTIFICATIONS_SINK))
  }
}

export function* requestNotificationsSinksUpdateGenerator({ payload }: ActionPayload<Notifications.UpdateNotificationsSinkRequest>) {
  try {
    yield put(startFetchingNotificationsAction(UPDATE_NOTIFICATIONS_SINK))

    yield call(API.updateSink, payload)

    yield put(requestNotificationsSinksAction({
      page: 1,
      companyId: payload.companyId,
      useCaseId: payload.useCaseId,
    }))

    yield take(RECEIVE_NOTIFICATIONS_SINKS)

    yield put(setPrimaryModalPageName(''))

    yield put(changeToastAction({ message: UPDATE_NOTIFICATIONS_SINK_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(UPDATE_NOTIFICATIONS_SINK))
  }
}

export function* requestNotificationsSinksRemoveGenerator({ payload }: ActionPayload<Notifications.RemoveNotificationsSinkRequest>) {
  try {
    yield put(startFetchingNotificationsAction(REMOVE_NOTIFICATIONS_SINK))

    yield call(API.deleteSink, {
      id: payload.id,
    })

    yield put(requestNotificationsSinksAction({
      page: 1,
      companyId: payload.companyId,
      useCaseId: payload.useCaseId,
    }))

    yield take(RECEIVE_NOTIFICATIONS_SINKS)

    yield put(changeToastAction({ message: REMOVE_NOTIFICATIONS_SINK_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(REMOVE_NOTIFICATIONS_SINK))
  }
}

export function* requestNotificationsSinksTestGenerator({ payload }: ActionPayload<Notifications.TestNotificationsSinkRequest>) {
  try {
    yield put(startFetchingNotificationsAction(TEST_NOTIFICATIONS_SINK))

    yield call(API.testSink, payload)

    yield put(setPrimaryModalPageName(''))

    yield put(changeToastAction({ message: TEST_NOTIFICATIONS_SINK_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(TEST_NOTIFICATIONS_SINK))
  }
}

export function* sendNotificationGenerator({ payload }: ActionPayload<Notifications.SendNotificationRequest>) {
  try {
    yield put(startFetchingNotificationsAction(SEND_NOTIFICATION))

    yield call(API.sendNotification, payload)

    yield delay(1000)

    yield put(requestDispatchedNotificationsAction({
      page: 1,
      companyId: payload.companyId,
      useCaseId: payload.useCaseId,
    }))

    yield take(RECEIVE_DISPATCHED_NOTIFICATIONS)

    yield put(setPrimaryModalPageName(''))

    yield put(changeToastAction({ message: SEND_NOTIFICATION_MESSAGE_ID, useIntl: true, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingNotificationsAction(SEND_NOTIFICATION))
  }
}

function* watchRequestNotifications() {
  yield takeLatest(REQUEST_NOTIFICATIONS, requestNotificationsGenerator)
}

function* watchRequestDispatchedNotifications() {
  yield takeLatest(REQUEST_DISPATCHED_NOTIFICATIONS, requestDispatchedNotificationsGenerator)
}

function* watchRequestNotificationsRoutings() {
  yield takeLatest(REQUEST_NOTIFICATIONS_ROUTINGS, requestNotificationsRoutingsGenerator)
}

function* watchRequestNotificationsRoutingsSetup() {
  yield takeLatest(SETUP_NOTIFICATIONS_ROUTINGS, setupNotificationsRoutingsGenerator)
}

function* watchRequestNotificationsRoutingsCreate() {
  yield takeLatest(CREATE_NOTIFICATIONS_ROUTINGS, createNotificationsRoutingsGenerator)
}

function* watchRequestNotificationsRoutingsUpdate() {
  yield takeLatest(UPDATE_NOTIFICATIONS_ROUTINGS, updateNotificationsRoutingsGenerator)
}

function* watchRequestNotificationsRoutingsRemove() {
  yield takeLatest(REMOVE_NOTIFICATIONS_ROUTINGS, removeNotificationsRoutingsGenerator)
}

function* watchRequestNotificationsRoutingsGraph() {
  yield takeLatest(REQUEST_NOTIFICATIONS_ROUTING_GRAPH, requestNotificationsRoutingGraphGenerator)
}

function* watchRequestNotificationsTemplates() {
  yield takeLatest(REQUEST_NOTIFICATIONS_TEMPLATES, requestNotificationsTemplatesGenerator)
}

function* watchRequestNotificationsSinks() {
  yield takeLatest(REQUEST_NOTIFICATIONS_SINKS, requestNotificationsSinksGenerator)
}

function* watchRequestNotificationsTemplatesCreate() {
  yield takeLatest(CREATE_NOTIFICATIONS_TEMPLATE, requestNotificationsTemplatesCreateGenerator)
}

function* watchRequestNotificationsTemplatesUpdate() {
  yield takeLatest(UPDATE_NOTIFICATIONS_TEMPLATE, requestNotificationsTemplatesUpdateGenerator)
}

function* watchRequestNotificationsTemplatesRemove() {
  yield takeLatest(REMOVE_NOTIFICATIONS_TEMPLATE, requestNotificationsTemplatesRemoveGenerator)
}

function* watchRequestNotificationsSinksCreate() {
  yield takeLatest(CREATE_NOTIFICATIONS_SINK, requestNotificationsSinksCreateGenerator)
}

function* watchRequestNotificationsSinksUpdate() {
  yield takeLatest(UPDATE_NOTIFICATIONS_SINK, requestNotificationsSinksUpdateGenerator)
}

function* watchRequestNotificationsSinksRemove() {
  yield takeLatest(REMOVE_NOTIFICATIONS_SINK, requestNotificationsSinksRemoveGenerator)
}

function* watchRequestNotificationsSinksTest() {
  yield takeLatest(TEST_NOTIFICATIONS_SINK, requestNotificationsSinksTestGenerator)
}

function* watchRequestNotificationsMarkAsRead() {
  yield takeLatest(MARK_NOTIFICATIONS_AS_READ, markNotificationsAsReadGenerator)
}

function* watchRequestNotificationsDismiss() {
  yield takeLatest(DISMISS_NOTIFICATIONS, dismissNotificationsGenerator)
}

function* watchRequestNotificationsSend() {
  yield takeLatest(SEND_NOTIFICATION, sendNotificationGenerator)
}

function* watchRequestUnreadNotificationsCount() {
  yield takeLatest(REQUEST_UNREAD_NOTIFICATIONS_COUNT, requestUnreadNotificationsCountGenerator)
}

function* watchRequestNotificationsPaginationMeta() {
  yield takeLatest(CHANGE_NOTIFICATIONS_PAGINATION_META, changeNotificationsPaginationMetaGenerator)
}

function* watchRequestNotificationsMarkAllAsRead() {
  yield takeLatest(MARK_NOTIFICATIONS_ALL_AS_READ, markNotificationsAllAsReadGenerator)
}

export default {
  watchRequestNotifications,
  watchRequestUnreadNotificationsCount,
  watchRequestDispatchedNotifications,
  watchRequestNotificationsRoutings,
  watchRequestNotificationsRoutingsSetup,
  watchRequestNotificationsRoutingsCreate,
  watchRequestNotificationsRoutingsUpdate,
  watchRequestNotificationsRoutingsRemove,
  watchRequestNotificationsSinksTest,
  watchRequestNotificationsTemplates,
  watchRequestNotificationsSinks,
  watchRequestNotificationsTemplatesCreate,
  watchRequestNotificationsTemplatesUpdate,
  watchRequestNotificationsTemplatesRemove,
  watchRequestNotificationsSinksCreate,
  watchRequestNotificationsSinksUpdate,
  watchRequestNotificationsSinksRemove,
  watchRequestNotificationsRoutingsGraph,
  watchRequestNotificationsMarkAsRead,
  watchRequestNotificationsDismiss,
  watchRequestNotificationsSend,
  watchRequestNotificationsPaginationMeta,
  watchRequestNotificationsMarkAllAsRead,
}
