import {
  takeEvery, put, call,
} from 'redux-saga/effects'

import { CANCELED_REQUEST_ERROR } from '@constants/common.constants'
import { setSidebarState } from '@utils/local-storage.utils'
import { ActionPayload } from '@redux/modules/types'

import {
  INCREMENT_UNRESOLVED_REQUESTS,
  DECREMENT_UNRESOLVED_REQUESTS,
  CHANGE_SIDEBAR_STATE,
  CHANGE_CHATBOT_STATE,
  CHANGE_TOAST,
} from './common.action-types'

import {
  incrementUnresolvedRequestsActionDone,
  decrementUnresolvedRequestsActionDone,
  changeSidebarStateActionDone,
  changeChatbotStateActionDone,
  changeToastActionDone,
} from './common.actions'

function* incrementUnresolvedRequestsGenerator() {
  yield put(incrementUnresolvedRequestsActionDone())
}

function* decrementUnresolvedRequestsGenerator() {
  yield put(decrementUnresolvedRequestsActionDone())
}

function* changeSidebarStateGenerator({ payload }: ActionPayload<boolean>) {
  yield put(changeSidebarStateActionDone(payload))

  yield call(setSidebarState, payload)
}

function* changeChatbotStateGenerator({ payload }: ActionPayload<boolean>) {
  yield put(changeChatbotStateActionDone(payload))
}

function* changeToastGenerator({ payload }: ActionPayload<Common.ToastPayload>) {
  if (payload.message !== CANCELED_REQUEST_ERROR) {
    const isOpen = Boolean(payload.message)

    yield put(changeToastActionDone({
      open: isOpen,
      ...payload,
    }))
  }
}

export function* watchIncrementSubmittedRequests() {
  yield takeEvery(INCREMENT_UNRESOLVED_REQUESTS, incrementUnresolvedRequestsGenerator)
}

export function* watchDecrementSubmittedRequests() {
  yield takeEvery(DECREMENT_UNRESOLVED_REQUESTS, decrementUnresolvedRequestsGenerator)
}

export function* watchChangeSidebarStateRequests() {
  yield takeEvery(CHANGE_SIDEBAR_STATE, changeSidebarStateGenerator)
}

export function* watchChangeChatbotStateRequests() {
  yield takeEvery(CHANGE_CHATBOT_STATE, changeChatbotStateGenerator)
}

export function* watchToastRequests() {
  yield takeEvery(CHANGE_TOAST, changeToastGenerator)
}
