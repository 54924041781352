import {
  PARETOS_GRADIENT,
  PARETOS_GRADIENT_20,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_BUTTON_TRANSITION,
  DEFAULT_BUTTON_FONT_SIZE,
} from '@constants/ui.constants'

import ChevronIcon from '@icons/flow/chevron.icon'

import { Theme } from '@mui/material'

/**
 * Truncates a use case slug to a maximum of 16 characters
 *
 * @param slug The slug to truncate
 *
 * @returns The truncated slug, with at least 3 characters at the end
 */
export const truncateUseCaseSlug = (slug?: string) => {
  if (!slug) {
    return ''
  }

  if (slug.length > 16) {
    return `${slug.substring(0, 7)} … ${slug.substring(slug.length - 3, slug.length)}`
  }

  return slug
}

/**
 * Generates styles for buttons
 * @param theme MUI theme
 * @param color Button color
 * @param buttonType Button type
 * @param loading Loading state
 * @returns Button styles
 */
export const getButtonsStyles = (
  theme: Theme,
  color: 'primary' | 'secondary' | 'tertiary' | 'highlighted' | 'highlighted-secondary' | 'paretos',
  buttonType: 'regular' | 'icon',
  loading?: boolean,
) => {
  return {
    paretos: {
      background: loading ? PARETOS_GRADIENT_20 : PARETOS_GRADIENT,
      color: theme.palette.new.white,
      outline: loading ? `1px solid ${theme.palette.new.business_black_20}` : 'none',

      '&:disabled': {
        background: theme.palette.new.business_black_5,
        color: theme.palette.new.business_black_20,
      },

      '&:hover': {
        opacity: 0.6,
      },
      '&:focus-visible': {
        opacity: 0.6,
      },
      '&:active': {
        opacity: 1,
      },
    },
    primary: {
      background: loading ? PARETOS_GRADIENT_20 : theme.palette.new.business_black,
      color: theme.palette.new.white,
      outline: loading ? `1px solid ${theme.palette.new.business_black_20}` : 'none',

      '&:hover': {
        opacity: 0.6,
      },
      '&:focus-visible': {
        opacity: 0.6,
      },
      '&:active': {
        opacity: 1,
      },
    },
    secondary: {
      background: loading ? PARETOS_GRADIENT_20 : theme.palette.new.business_black_5,
      color: theme.palette.new.business_black,
      outline: loading ? `1px solid ${theme.palette.new.business_black_20}` : 'none',
      opacity: loading ? 1 : 0.6,

      '&:hover': {
        opacity: 0.8,
        backgroundColor: theme.palette.new.business_black_10,
      },
      '&:focus-visible': {
        opacity: 0.8,
        backgroundColor: theme.palette.new.business_black_10,
      },
      '&:active': {
        opacity: 1,
      },
    },
    tertiary: {
      background: (loading && buttonType === 'regular') ? PARETOS_GRADIENT_20 : theme.palette.new.transparent,
      outline: (loading && buttonType === 'regular') ? `1px solid ${theme.palette.new.business_black_20}` : 'none',
      color: theme.palette.new.business_black,

      '&:hover': {
        backgroundColor: theme.palette.new.business_black_5,
      },
      '&:focus-visible': {
        backgroundColor: theme.palette.new.business_black_5,
      },
      '&:active': {
        backgroundColor: theme.palette.new.business_black_10,
      },
    },
    highlighted: {
      background: loading ? PARETOS_GRADIENT_20 : theme.palette.new.versatile_violet,
      outline: loading ? `1px solid ${theme.palette.new.business_black_20}` : 'none',
      color: theme.palette.new.white,

      '&:hover': {
        backgroundColor: theme.palette.new.versatile_violet,
        opacity: 0.6,
      },
      '&:focus-visible': {
        backgroundColor: theme.palette.new.versatile_violet,
        opacity: 0.6,
      },
      '&:active': {
        backgroundColor: theme.palette.new.versatile_violet,
        opacity: 1,
      },
    },
    'highlighted-secondary': {
      background: loading ? PARETOS_GRADIENT_20 : theme.palette.new.white,
      color: theme.palette.new.business_black,
      outline: `1px solid ${theme.palette.new.business_black_20}`,

      '&:hover': {
        backgroundColor: theme.palette.new.white,
        outline: `1px solid ${theme.palette.new.business_black_60}`,
      },
      '&:focus-visible': {
        backgroundColor: theme.palette.new.white,
        outline: `1px solid ${theme.palette.new.business_black_60}`,
      },
      '&:active': {
        backgroundColor: theme.palette.new.smokey_silver,
        outline: `1px solid ${theme.palette.new.business_black}`,
      },
    },
  }[color || 'primary']
}

/**
 * Generates styles for text with a gradient background
 * @param background Color to use as background
 * @returns Styles for text with a gradient background
 */
export const getTextBackgroundFillStyles = (background = PARETOS_GRADIENT) => {
  return {
    background,
    backgroundClip: 'text',
    textFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    MozBackgroundClip: 'text',
    MozTextFillColor: 'transparent',
  }
}

/**
 * Generates styles for the end adornment button in the autocomplete component
 * @param theme MUI theme
 * @param withHelp Whether the autocomplete has a help icon
 * @returns Styles for the end adornment button
 */
export const getAutocompleteEndAdornmentButtonStyles = (theme: Theme, withHelp = false) => {
  return {
    position: 'absolute',
    right: withHelp ? '36px' : '12px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',

    '& .MuiIconButton-root': {
      backgroundColor: theme.palette.new.transparent,
      color: theme.palette.new.business_black,
      opacity: 0.6,
      padding: '4px',

      '&.Mui-disabled': {
        opacity: 0.4,
      },

      '&:hover': {
        opacity: 1,
        backgroundColor: theme.palette.new.transparent,
      },
      '&:focus-visible': {
        opacity: 1,
        backgroundColor: theme.palette.new.transparent,
      },
      '&:active': {
        opacity: 1,
        backgroundColor: theme.palette.new.transparent,
      },

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
  }
}

/**
 * @function getPaginationStyles Returns pagination styles
 *
 * @param {Theme} theme Theme
 *
 * @returns {Object} Pagination styles
 */
export const getPaginationStyles = (theme: Theme) => {
  const toolbarStyles = {
    '&.MuiTablePagination-root': {
      borderBottom: 'none',
    },
    '& .MuiTablePagination-selectLabel': {
      fontSize: DEFAULT_BUTTON_FONT_SIZE,
    },
    '& .MuiTablePagination-input': {
      ml: '10px',
      mr: '20px',
    },
    '& .MuiTablePagination-displayedRows': {
      fontSize: DEFAULT_BUTTON_FONT_SIZE,
    },
  }

  const slotProps = {
    select: {
      MenuProps: {
        sx: {
          '& .MuiPaper-root': {
            transform: 'translate(0px, 2px) !important',
          },
        },
      },
      IconComponent: ChevronIcon,
      sx: {
        '& .MuiSelect-select.MuiTablePagination-select.MuiInputBase-input[aria-expanded="true"]': {
          outline: `1px solid ${theme.palette.new.business_black}`,
          backgroundColor: theme.palette.new.smokey_silver,
        },
        '& .MuiSelect-select.MuiTablePagination-select.MuiInputBase-input': {
          fontSize: DEFAULT_BUTTON_FONT_SIZE,
          transition: DEFAULT_BUTTON_TRANSITION,
          borderRadius: DEFAULT_BORDER_RADIUS.SMALL,

          pt: '0px',
          pb: '0px',
          pl: '10px',
          pr: '40px',
          height: '36px',
          display: 'flex',
          alignItems: 'center',
          outline: `1px solid ${theme.palette.new.transparent}`,
          '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.new.business_black_10,
          },
          '&:focus-visible': {
            opacity: 0.8,
            backgroundColor: theme.palette.new.business_black_10,
          },
          '&:active': {
            opacity: 1,
          },
        },
        '& .MuiTablePagination-selectIcon': {
          top: 'unset',
          mr: '10px',

          '&.MuiSelect-iconOpen': {
            top: 'unset',
          },
        },
        '&.Mui-disabled': {
          pointerEvents: 'none',
          backgroundColor: theme.palette.new.business_black_5,
          borderRadius: DEFAULT_BORDER_RADIUS.SMALL,

          '& .MuiTablePagination-selectIcon > path': {
            stroke: theme.palette.new.business_black_20,
          },
        },
      },
    },
  }

  return {
    toolbarStyles,
    slotProps,
  }
}
