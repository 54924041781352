import React, { useCallback, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import {
  generatePath,
  RouteComponentProps,
  useRouteMatch,
} from 'react-router-dom'

import PageLayoutContainer from '@containers/application/PageLayout'
import LiveMonitoringContainer from '@containers/pages/(demand-usecase)/monitor/live-monitoring'
import BacktestingContainer from '@containers/pages/(demand-usecase)/monitor/back-testing'
import BaseLineComparisonContainer from '@containers/pages/(demand-usecase)/monitor/baseline-comparison'

import ActionButtonComponent from '@base/pagebar/ActionButton'
import ExportIcon from '@icons/export.icon'
import GearIcon from '@icons/gear.icon'
import ViewInfoDateComponent from '@base/pagebar/ViewInfoDate'
import InsightsLatestRunMessageComponent from '@components/insights/InsightsLatestRunMessage'

import {
  MONITOR_BASELINE_COMPARISON_PATH,
  MONITOR_LIVE_MONITORING_PATH,
  MONITOR_BACKTESTING_PATH,
} from '@constants/routes.constants'

import { handleExport } from '@utils/export.utils'
import { EXPORT_AS_PNG } from '@constants/export.constants'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { getDefaultMonitorTab, setDefaultMonitorTab } from '@utils/local-storage.utils'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getInsightsPipelineRunInfo, isFetchingPipelineRunInfo } from '@redux/modules/insights/insights.selectors'
import { EVALUATION_PROFILE_MODAL_NAME } from '@constants/modals.constants'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'

const EXPORT_ID = 'monitor'

/**
 * This component is used to redirect the user to the default monitor tab
 * when the user navigates to the monitor page
 */
export const MonitorViewRedirect: React.FC<{
  route: RouteComponentProps
}> = ({
  route,
}) => {
  const {
    history,
    match,
  } = route

  useEffect(() => {
    const defaultMonitorTab = getDefaultMonitorTab()

    history.push(generatePath(defaultMonitorTab, match.params))
  }, [history, match.params])

  return null
}

export const MonitorContainer: React.FC = () => {
  const intl = useIntl()
  const match = useRouteMatch<Common.RouterMatch>()
  const dispatch = useDispatch()
  const useCase = useSelector(getUseCaseItem)
  const isAdmin = useSelector(getIsAdmin)
  const pipelineInfo = useSelector(getInsightsPipelineRunInfo)
  const isFetching = useSelector(isFetchingPipelineRunInfo)

  const handleExportClick = useCallback(() => {
    handleExport({
      type: EXPORT_AS_PNG,
      fileName: intl.formatMessage({ id: 'monitor.live.browser_tab.title' }, { name: useCase?.name }),
      exportId: EXPORT_ID,
    })
  }, [useCase, intl])

  const handleEvaluationProfileSettingsClick = useCallback(() => {
    dispatch(setPrimaryModalPageName(EVALUATION_PROFILE_MODAL_NAME))
  }, [dispatch])

  const pageBarRightSideBlocks = useMemo(() => {
    const lastUpdateTooltip = isAdmin ? (
      <InsightsLatestRunMessageComponent
        pipelineInfo={pipelineInfo}
      />
    ) : null

    return (
      <>
        <ViewInfoDateComponent
          date={pipelineInfo.dateOfExecution}
          label={intl.formatMessage({ id: 'insights.view.last_update' })}
          tooltip={lastUpdateTooltip}
          loading={isFetching}
        />

        <ActionButtonComponent
          name='exportAsPng'
          onClick={handleExportClick}
          IconComponent={ExportIcon}
          label={intl.formatMessage({ id: 'common.button.export_png' })}
        />

        {
          isAdmin && match.path === MONITOR_BASELINE_COMPARISON_PATH && (
            <ActionButtonComponent
              name='evaluationProfileSettings'
              onClick={handleEvaluationProfileSettingsClick}
              IconComponent={GearIcon}
              label={intl.formatMessage({ id: 'insights.evaluationProfile' })}
            />
          )
        }
      </>
    )
  }, [
    intl,
    pipelineInfo,
    isFetching,
    isAdmin,
    match,
    handleExportClick,
    handleEvaluationProfileSettingsClick,
  ])

  const tabs = [
    {
      label: intl.formatMessage({ id: 'monitor.tabs.backtesting' }),
      path: MONITOR_BACKTESTING_PATH,
    },
    {
      label: intl.formatMessage({ id: 'monitor.tabs.live' }),
      path: MONITOR_LIVE_MONITORING_PATH,
    },
    {
      label: intl.formatMessage({ id: 'monitor.tabs.baseline_comparison' }),
      path: MONITOR_BASELINE_COMPARISON_PATH,
    },
  ]

  const onTabChange = (tab: Common.TabOption) => {
    setDefaultMonitorTab(tab.path!)
  }

  const content = useMemo(() => {
    switch (match.path) {
      case MONITOR_LIVE_MONITORING_PATH:
        return <LiveMonitoringContainer exportId={EXPORT_ID} />
      case MONITOR_BACKTESTING_PATH:
        return <BacktestingContainer exportId={EXPORT_ID} />
      case MONITOR_BASELINE_COMPARISON_PATH:
        return <BaseLineComparisonContainer exportId={EXPORT_ID} />
      default:
        return <LiveMonitoringContainer exportId={EXPORT_ID} />
    }
  }, [match])

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'monitor.live.browser_tab.title' }, { name: useCase?.name })}
      pageBarRightSideBlocks={pageBarRightSideBlocks}
      tabs={tabs}
      onTabChange={onTabChange}
    >
      {content}
    </PageLayoutContainer>
  )
}

export default MonitorContainer
