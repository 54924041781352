import React, { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'

import {
  Box, MenuItem,
  Typography, useTheme,
  MenuList,
} from '@mui/material'

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'
import { DEFAULT_BORDER_RADIUS, DEFAULT_SELECT_BOX_SHADOW } from '@constants/ui.constants'
import { getAutocompleteEndAdornmentButtonStyles } from '@utils/ui.utils'

import ChipComponent from '@base/chips/Chip'
import TextFieldComponent from '@base/forms/TextField'

import ChevronIcon from '@icons/flow/chevron.icon'
import CloseIcon from '@icons/close.icon'

export interface AutocompleteChipsFieldProps<T> extends Omit<AutocompleteProps<any, true, false, any>, 'renderInput' | 'options'> {

  /**
   * The input name. Same as id if not provided.
   * Some form libraries require a name attribute.
   */
  name: string,
  /**
   * The options to be shown
   */
  options: T[],
  /**
   * onChange callback
   * @param e Event
   * @param selectedValue Selected value
   * @param newValue All selected values
   */
  handleChangeCallback(e: React.ChangeEvent<any>, selectedValue: T, newValue: T[]): any,
  /**
   * Gets the label of the option
   * @param option Option
   * @returns Label
   */
  getOptionLabel: (option: T) => string,
  /**
   * The label content.
   */
  label: string
  /**
   * If true, the label will be hidden.
   */
  hideLabel?: boolean
  /**
   * The input placeholder.
   */
  placeholder?: string
  /**
   * Input validation errors.
   */
  errors?: string[]
  /**
   * Indicates if the input has been touched.
   */
  touched?: boolean
  /**
   * Help text to be displayed in a tooltip.
   */
  floatingHelp?: string | NonNullable<React.ReactNode>
}

const AutocompleteChipsFieldComponent = <T extends {}> ({
  name,
  value,
  className,
  handleChangeCallback,
  label,
  errors,
  options,
  getOptionLabel,
  disabled,
  floatingHelp,
  freeSolo = false,
  placeholder,
  touched,
  hideLabel = false,
  noOptionsText,
  ...rest
}: AutocompleteChipsFieldProps<T>) => {
  const theme = useTheme()
  const intl = useIntl()
  const { params } = useRouteMatch<Common.RouterMatch>()
  const errorMessages = errors || []
  const hasValues = Boolean(value && value.length > 0)

  const handleChange = (e: React.ChangeEvent<any>, newValue: T[], reason: any) => {
    const selectedValue = newValue[newValue.length - 1]

    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CHANGE,
    }, {
      selectedValue,
      router: params,
    })

    handleChangeCallback && handleChangeCallback(e, selectedValue, newValue)
  }

  const autocompleteStyles = useMemo(() => {
    return {
      '&.MuiAutocomplete-root': {
        '& .MuiChip-root.Mui-disabled': {
          opacity: 1,
        },
        '&.MuiAutocomplete-root.Mui-focused.Mui-expanded': {
          '& .MuiInputBase-root.Mui-focused:not(.Mui-error)': {
            borderColor: theme.palette.new.business_black,
          },
          '& .MuiInputBase-root.Mui-focused.Mui-error': {
            borderColor: theme.palette.new.rebellious_red,
          },
        },

        '& .MuiInputBase-root': {
          gap: '5px',
          paddingRight: `${floatingHelp ? '80px' : '40px'} !important`,
          minHeight: '36px',
          padding: hasValues ? '6px 10px 6px 10px' : '8px 10px 8px 10px',
        },

        '& .MuiAutocomplete-endAdornment': {
          ...getAutocompleteEndAdornmentButtonStyles(theme, Boolean(floatingHelp)),
        },
      },
    }
  }, [theme, floatingHelp, hasValues])

  const slotProps = useMemo(() => {
    return {
      paper: {
        sx: {
          outline: `1px solid ${theme.palette.new.business_black_20}`,
          boxShadow: DEFAULT_SELECT_BOX_SHADOW,
          borderRadius: DEFAULT_BORDER_RADIUS.SMALL,

          '& .MuiAutocomplete-noOptions': {
            backgroundColor: theme.palette.new.smokey_silver,
            padding: theme.spacing(0),
            height: '36px',
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
      popper: {
        sx: {
          marginTop: '1px !important',
          marginBottom: '1px !important',
        },
      },
    }
  }, [theme])

  return (
    <Box
      data-testid={AutocompleteChipsFieldComponent.name}
    >
      <Autocomplete
        id={name}
        multiple={true}
        autoHighlight={false}
        autoSelect={false}
        fullWidth={true}
        disablePortal={false}
        disableCloseOnSelect={false}
        freeSolo={freeSolo}
        options={options}
        value={value}
        disabled={disabled}
        filterSelectedOptions={false}
        onChange={handleChange}
        clearIcon={<CloseIcon />}
        popupIcon={<ChevronIcon />}
        noOptionsText={(
          <Typography
            variant='body1'
            px='12px'
            color={theme.palette.new.business_black_40}
          >
            {noOptionsText || intl.formatMessage({ id: 'common.dropdown.noOptions' })}
          </Typography>
        )}
        renderTags={(tagValues, getTagProps) => {
          return tagValues.map((option: T, index) => {
            const {
              onDelete,
              ...tagProps
            } = getTagProps({ index })

            const hasError = Boolean(errorMessages[index])

            return (
              <ChipComponent
                {...tagProps}
                variant='outlined'
                label={(getOptionLabel ? getOptionLabel(option) : option) as string}
                color={hasError ? theme.palette.new.rebellious_red : theme.palette.new.versatile_violet}
                EndIconComponent={CloseIcon}
                onEndIconClick={onDelete}
                disabled={disabled}
                tooltip={hasError ? errorMessages[index] : undefined}
              />
            )
          })
        }}
        ListboxComponent={MenuList}
        renderOption={((props: React.HTMLAttributes<HTMLLIElement>, option: T, { selected } : { selected: boolean }) => {
          return (
            <MenuItem
              {...props}
              selected={selected}
            >
              {getOptionLabel(option)}
            </MenuItem>
          )
        })}
        getOptionLabel={getOptionLabel}
        renderInput={(inputParams) => (
          <TextFieldComponent
            {...inputParams.InputProps}
            inputProps={inputParams.inputProps}
            name={name}
            label={label}
            disabled={disabled}
            hideLabel={hideLabel}
            touched={touched}
            errors={(touched && errors && errors.length > 0) ? errors.join(', ') : undefined}
            placeholder={disabled ? '' : placeholder}
            floatingHelp={floatingHelp}
          />
        )}
        sx={autocompleteStyles}
        slotProps={slotProps}
        {...rest}
      />
    </Box>
  )
}

export default AutocompleteChipsFieldComponent
