import React from 'react'
import { useDispatch, useSelector } from '@redux/hooks'

import SnackbarComponent from '@base/utils/Snackbar'

import { changeToastAction } from '@redux/modules/common/common.actions'
import { getToastMessage } from '@redux/modules/common/common.selectors'

import NotificationsOverviewModal from '@containers/modals/notifications/notifications-overview-modal'

const GlobalContainer = () => {
  const dispatch = useDispatch()
  const toastMessageObj = useSelector(getToastMessage)

  const handleToastClose = () => {
    /**
     * We do not want to reset the toast message.
     * Otherwise the toast will be empty during the animation.
     */
    dispatch(
      changeToastAction({ open: false, useIntl: false }),
    )
  }

  return (
    <>
      <SnackbarComponent
        open={toastMessageObj.open}
        handleClose={handleToastClose}
        toastMessage={toastMessageObj.message}
        severity={toastMessageObj.severity}
        useIntl={toastMessageObj.useIntl}
        intlParameters={toastMessageObj.intlParameters}
      />

      <NotificationsOverviewModal />
    </>
  )
}

export default GlobalContainer
