import { State } from '@redux/modules/types'

/**
 * @function getUnresolvedRequestsCount Returns unresolved requests count
 *
 * @param {Object} state Redux State
 *
 * @return {Number} Unresolved requests count
 */
export const getUnresolvedRequestsCount = (state: State) => {
  return state.common.unresolvedRequests
}

/**
 * @function anyUnresolvedRequestsPresent Determine whethere there is unresolved request
 *
 * @param {Object} state Redux State
 *
 * @return {Boolean} true in case when there are any requests pending
 */
export const anyUnresolvedRequestsPresent = (state: State) => {
  return state.common.unresolvedRequests > 0
}

/**
 * @function getSidebarState Returns sidebar state
 *
 * @param {Object} state Redux State
 *
 * @return {Boolean} Return sidebar state
 */
export const getSidebarState = (state: State) => {
  return state.common.sidebar
}

/**
 * @function getChatbotState Returns chatbot state
 *
 * @param {Object} state Redux State
 *
 * @return {Boolean} Return chatbot state
 * */
export const getChatbotState = (state: State) => {
  return state.common.chatbot
}

/**
 * @function getToastMessage Returns toast message
 *
 * @param {Object} state Redux State
 *
 * @return {String} Return user toast message
 */
export const getToastMessage = (state: State) => {
  return state.common.toast
}

/**
 * @function isFetching Returns 'true', if fetching
 *
 * @param {Object} state Redux State
 *
 * @return {Boolean} Returns 'true', if fetching
 */
export const isFetching = (state: State) => {
  return state.common.fetchingKeys.length > 0
}
