import { createAction } from 'redux-actions'

import {
  INCREMENT_UNRESOLVED_REQUESTS,
  INCREMENT_UNRESOLVED_REQUESTS_DONE,

  DECREMENT_UNRESOLVED_REQUESTS,
  DECREMENT_UNRESOLVED_REQUESTS_DONE,

  CHANGE_SIDEBAR_STATE,
  CHANGE_SIDEBAR_STATE_DONE,

  CHANGE_CHATBOT_STATE,
  CHANGE_CHATBOT_STATE_DONE,

  CHANGE_TOAST,
  CHANGE_TOAST_DONE,

  START_FETCHING_COMMON,
  STOP_FETCHING_COMMON,
} from './common.action-types'

export const incrementUnresolvedRequestsAction = createAction(INCREMENT_UNRESOLVED_REQUESTS)
export const incrementUnresolvedRequestsActionDone = createAction(INCREMENT_UNRESOLVED_REQUESTS_DONE)

export const decrementUnresolvedRequestsAction = createAction(DECREMENT_UNRESOLVED_REQUESTS)
export const decrementUnresolvedRequestsActionDone = createAction(DECREMENT_UNRESOLVED_REQUESTS_DONE)

export const changeSidebarStateAction = createAction<boolean>(CHANGE_SIDEBAR_STATE)
export const changeSidebarStateActionDone = createAction(CHANGE_SIDEBAR_STATE_DONE)

export const changeChatbotStateAction = createAction<boolean>(CHANGE_CHATBOT_STATE)
export const changeChatbotStateActionDone = createAction(CHANGE_CHATBOT_STATE_DONE)

export const changeToastAction = createAction<Common.ToastPayload>(CHANGE_TOAST)
export const changeToastActionDone = createAction(CHANGE_TOAST_DONE)

export const startFetchingCommonAction = createAction<string>(START_FETCHING_COMMON)
export const stopFetchingCommonAction = createAction<string>(STOP_FETCHING_COMMON)
