import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  Box,
  Slider,
  SliderThumb,
  SliderValueLabelProps,
  Typography,
  useTheme,
} from '@mui/material'

import TooltipComponent from '@base/tooltips/Tooltip'
import IntlFormatBoldComponent from '@base/utils/IntlFormatBold/IntlFormatBold.component'

import { SLIDER_TOOLTIP_ANIMATION_TRANSITION_PROPS } from '@constants/tooltips.constants'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'
import { useIntl } from 'react-intl'

const ThumbComponent = React.forwardRef((props, ref: any) => {
  const { children, ...other } = props

  return (
    <SliderThumb {...other} ref={ref}>
      {children}
      <Box component='span' className='bar' />
      <Box component='span' className='bar' />
      <Box component='span' className='bar' />
    </SliderThumb>
  )
}) as React.ElementType<React.HTMLAttributes<HTMLSpanElement>>

export interface ValueLabelComponentProps extends SliderValueLabelProps {
  children: React.ReactElement<any, any>,
  open: boolean,
  value: number,
}

const ValueLabelComponent: React.FC<ValueLabelComponentProps> = React.forwardRef((props, ref) => {
  const {
    children,
    open,
    value,
  } = props

  return (
    <TooltipComponent
      open={open}
      describeChild={true}
      enterTouchDelay={0}
      placement='top'
      title={defaultNumberFormatter(value)}
      ref={ref}
      TransitionProps={SLIDER_TOOLTIP_ANIMATION_TRANSITION_PROPS}
    >
      {children}
    </TooltipComponent>
  )
})

export interface TimeStepRangeSliderComponentProps {
  /**
   * Name of the component for tracking purposes
   */
  name: string
  /**
   * Overline text
   */
  overline?: string
  /**
   * If 'true' the component is disabled
   */
  disabled?: boolean
  /**
   * If 'true' the component is loading
   */
  loading?: boolean
  /**
   * On change callback
   * @param value New value
   */
  onChange: (value: number[]) => void
  /**
   * Current value
   */
  value?: number[]
  /**
   * Minimum value
   */
  min?: number | null
  /**
   * Maximum value
   */
  max?: number | null
  /**
   * Unit of the slider
   */
  unit?: string
}

export const TimeStepRangeSliderComponent: React.FC<TimeStepRangeSliderComponentProps> = ({
  name,
  disabled,
  overline,
  loading,
  value,
  min,
  max,
  unit = '',
  onChange,
}) => {
  const theme = useTheme()
  const intl = useIntl()

  const maxValue = max || 0
  const minValue = min || 0
  const minMaxEqual = minValue === maxValue
  const [sliderValue, setSliderValue] = useState<number[]>([minValue, maxValue])

  const handleChangeCommitted = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    onChange(newValue as number[])
  }

  const handleChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number[])
  }

  useEffect(() => {
    setSliderValue(value || [minValue, maxValue])
  }, [value, minValue, maxValue])

  const sliderStyles = useMemo(() => {
    return {
      color: theme.palette.new.black,
      height: '1px',
      padding: '13px 0',

      '& .MuiSlider-thumb': {
        height: '36px',
        zIndex: 1,
        width: '15px',
        backgroundColor: '#fff',
        borderRadius: '5px',
        border: `1px solid ${theme.palette.new.black}`,
        boxShadow: 'none',
        transition: 'none',
        '&.Mui-disabled': {
          height: '36px',
          width: '15px',
          borderRadius: '5px',
          backgroundColor: theme.palette.new.grey_f,
          cursor: 'not-allowed',
          pointerEvents: 'all',
        },
        '&:focus, &:hover, &$active': {
          boxShadow: 'none !important',
        },
        '&.Mui-active': {
          boxShadow: 'none !important',
        },
        '&.Mui-focusVisible': {
          boxShadow: 'none !important',
        },
        '&:before': {
          boxShadow: 'none !important',
        },
        '& .bar': {
          height: '13px',
          width: '1px',
          backgroundColor: theme.palette.new.black,
          marginLeft: '1px',
          marginRight: '1px',
        },
        '&:hover': {
          boxShadow: 'none !important',
        },
      },

      '& .MuiSlider-mark': {
        height: '7px',
        width: '1px',
        backgroundColor: theme.palette.new.business_black_20,
      },

      '& .MuiSlider-markActive': {
        opacity: 1,
        width: '1px',
        height: '7px',
        backgroundColor: theme.palette.new.business_black,
      },

      '& .MuiSlider-track': {
        border: 'none',
      },

      '& .MuiSlider-rail': {
      },
    }
  }, [theme])

  return (
    <Box
      data-testid={TimeStepRangeSliderComponent.name}
    >
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        width='100%'
        mb='10px'
      >
        <Typography>
          {
            intl.formatMessage({ id: 'insights.evaluationTimeFrame' }, {
              unit: <IntlFormatBoldComponent>{unit || ''}</IntlFormatBoldComponent>,
              start: <IntlFormatBoldComponent>{sliderValue[0]}</IntlFormatBoldComponent>,
              end: <IntlFormatBoldComponent>{sliderValue[1]}</IntlFormatBoldComponent>,
            })
          }
        </Typography>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        width='100%'
        mb='5px'
      >
        <Typography
          variant='caption'
          color={theme.palette.new.business_black_60}
          px='10px'
        >
          {intl.formatMessage({ id: 'common.start' })}
        </Typography>

        <Typography
          variant='caption'
          color={theme.palette.new.business_black_60}
          px='10px'
        >
          {intl.formatMessage({ id: 'common.end' })}
        </Typography>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='center'
        width='100%'
        gap={2}
        sx={{
          height: '36px',
          padding: '10px',
          backgroundColor: theme.palette.new.smokey_silver,
          borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
        }}
      >
        <Typography
          textAlign='left'
          minWidth='20px'
        >
          {sliderValue[0]}
        </Typography>

        <Slider
          name={name}
          getAriaLabel={() => overline || name}
          value={minMaxEqual ? [1, 2] : sliderValue}
          onChangeCommitted={handleChangeCommitted}
          onChange={handleChange}
          valueLabelDisplay='off'
          min={minValue}
          max={minMaxEqual ? maxValue + 1 : maxValue}
          marks={true}
          disabled={disabled || minMaxEqual}
          components={{
            Thumb: ThumbComponent,
            ValueLabel: ValueLabelComponent,
          }}
          sx={sliderStyles}
        />

        <Typography
          textAlign='right'
          minWidth='20px'
        >
          {sliderValue[1]}
        </Typography>
      </Box>
    </Box>
  )
}

export default TimeStepRangeSliderComponent
