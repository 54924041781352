import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'
import { Box } from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import SwitchFieldComponent from '@base/forms/SwitchField'
import TimeStepRangeSliderComponent from '@base/dates/TimeStepRangeSlider'

import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { EVALUATION_PROFILE_MODAL_NAME } from '@constants/modals.constants'
import { getForecastMetadata, isFetchingUseCaseMetaDataColumns } from '@redux/modules/use-case/use-case.selectors'
import { DEFAULT_TIME_RESOLUTION_LEVEL, MIN_PREDICTION_HORIZON, TIME_RESOLUTION_TO_UNIT_KEY_MAP } from '@constants/date.constants'
import { isSubmittingEvaluationProfile } from '@redux/modules/parameters/parameters.selectors'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { requestBaselineComparisonGridStateChangeAction } from '@redux/modules/monitor/monitor.actions'
import { State } from '@redux/modules/types'

export interface EvaluationProfileModalValues {
  timeStepRange: number[]
  itemCompensation: boolean
  timeCompensation: boolean
}

export interface EvaluationProfileModalProps {
  tableStateSelector: (state: State) => Monitor.BaselineComparisonTable
  chartStateSelector: (state: State) => Monitor.BaselineComparisonChart
}

const EvaluationProfileModalContainer: React.FC<EvaluationProfileModalProps> = ({
  tableStateSelector,
  chartStateSelector,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const tableState = useSelector(tableStateSelector)
  const chartState = useSelector(chartStateSelector)
  const isAdmin = useSelector(getIsAdmin)
  const forecastMetadata = useSelector(getForecastMetadata)
  const isSubmitting = useSelector(isSubmittingEvaluationProfile)
  const isFetchingUseCaseAvailableColumns = useSelector(isFetchingUseCaseMetaDataColumns)
  const modalPageName = useSelector(getOpenedModal)

  const { gridState } = tableState
  const { timeSteps } = chartState

  const open = modalPageName === EVALUATION_PROFILE_MODAL_NAME && isAdmin

  const timeResolution = useMemo(() => (forecastMetadata?.metaData?.baseline?.timeResolution || DEFAULT_TIME_RESOLUTION_LEVEL), [forecastMetadata])
  const maxTimeStepRange = useMemo(() => (timeSteps || MIN_PREDICTION_HORIZON), [timeSteps])

  const initialValues = useMemo(() => {
    if (gridState) {
      return {
        timeStepRange: gridState.timeStepRange,
        itemCompensation: gridState.itemCompensation || false,
        timeCompensation: gridState.timeCompensation || false,
      } as EvaluationProfileModalValues
    }

    return {
      timeStepRange: [MIN_PREDICTION_HORIZON, maxTimeStepRange],
      itemCompensation: true,
      timeCompensation: false,
    } as EvaluationProfileModalValues
  }, [
    gridState,
    maxTimeStepRange,
  ])

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName(''))
    }
  }

  const handleSubmitAction = (values: EvaluationProfileModalValues) => {
    dispatch(
      requestBaselineComparisonGridStateChangeAction({
        timeStepRange: values.timeStepRange,
        itemCompensation: values.itemCompensation,
        timeCompensation: values.timeCompensation,
      }),
    )

    dispatch(setPrimaryModalPageName(''))
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
  })

  const {
    handleSubmit,
    handleChange,
    resetForm,
    setFieldTouched,
    setFieldValue,
    values,
    dirty,
  } = formik

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <SidePanelComponent
      open={open}
      title={intl.formatMessage({ id: 'insights.evaluationProfile' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={isFetchingUseCaseAvailableColumns}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer divider={true} spacing={2}>
                <FormLayoutItem
                  xs={12}
                  container={true}
                >
                  <FormLayoutItem xs={12}>
                    <TimeStepRangeSliderComponent
                      name='timeStepRange'
                      overline={intl.formatMessage({ id: 'insights.timeStepRange' })}
                      onChange={(value) => {
                        setFieldValue('timeStepRange', value)

                        setFieldTouched('timeStepRange', true)
                      }}
                      value={values.timeStepRange}
                      min={MIN_PREDICTION_HORIZON}
                      max={maxTimeStepRange}
                      unit={timeResolution ? intl.formatMessage({ id: TIME_RESOLUTION_TO_UNIT_KEY_MAP[timeResolution] }) : ''}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
              </FormLayoutItemsContainer>

              <FormLayoutItemsContainer divider={true} spacing={2}>
                <FormLayoutItem
                  xs={12}
                  container={true}
                >
                  <FormLayoutItem xs={6}>
                    <SwitchFieldComponent
                      name='itemCompensation'
                      label={intl.formatMessage({ id: 'insights.itemCompensation' })}
                      checked={values.itemCompensation}
                      value={values.itemCompensation}
                      onChange={handleChange}
                      useLabelPlaceholder={true}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <SwitchFieldComponent
                      name='timeCompensation'
                      label={intl.formatMessage({ id: 'insights.timeCompensation' })}
                      checked={values.timeCompensation}
                      value={values.timeCompensation}
                      onChange={handleChange}
                      useLabelPlaceholder={true}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='evaluationProfileModalBackButton'
              onClick={() => handleCloseAction()}
              type='cancel'
            />

            <ModalButtonComponent
              name='evaluationProfileModalSubmitButton'
              onClick={(e) => handleSubmitAction(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !dirty}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default EvaluationProfileModalContainer
