import React from 'react'
import { useIntl } from 'react-intl'

import {
  gridClasses,
  GridColType,
  GridHeaderFilterCellProps,
  GridRenderHeaderFilterProps,
} from '@mui/x-data-grid-premium'

import {
  Box,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'

export type SupportedTypes = GridColType | 'float'

export interface TypeBadgeComponentProps {
  /**
   * Type of the column.
   */
  type: SupportedTypes
}

export const TypeBadgeComponent: React.FC<TypeBadgeComponentProps> = ({ type }) => {
  const theme = useTheme()
  const intl = useIntl()

  const defaultColor = theme.palette.new.youthful_yellow
  const defaultBackgroundColor = theme.palette.new.youthful_yellow_10

  const mapTypeToColor: Record<SupportedTypes, string> = {
    number: theme.palette.new.popular_pink,
    float: theme.palette.new.gainful_grey,
    dateTime: theme.palette.new.versatile_violet,
    date: theme.palette.new.versatile_violet,
    string: theme.palette.new.youthful_yellow,
    boolean: defaultColor,
    singleSelect: defaultColor,
    actions: defaultColor,
    custom: defaultColor,
  }

  const mapTypeToBackgroundColor: Record<SupportedTypes, string> = {
    number: theme.palette.new.popular_pink_10,
    float: theme.palette.new.gainful_grey_20,
    dateTime: theme.palette.new.versatile_violet_10,
    date: theme.palette.new.versatile_violet_10,
    string: theme.palette.new.youthful_yellow_10,
    boolean: defaultBackgroundColor,
    singleSelect: defaultBackgroundColor,
    actions: defaultBackgroundColor,
    custom: defaultBackgroundColor,
  }

  const mapTypeToLabel: Record<SupportedTypes, string> = {
    number: intl.formatMessage({ id: 'fileManager.preview.dialog.number' }),
    float: intl.formatMessage({ id: 'fileManager.preview.dialog.float' }),
    dateTime: intl.formatMessage({ id: 'fileManager.preview.dialog.dateTime' }),
    date: intl.formatMessage({ id: 'fileManager.preview.dialog.date' }),
    string: intl.formatMessage({ id: 'fileManager.preview.dialog.string' }),
    boolean: intl.formatMessage({ id: 'fileManager.preview.dialog.boolean' }),
    singleSelect: intl.formatMessage({ id: 'fileManager.preview.dialog.singleSelect' }),
    actions: intl.formatMessage({ id: 'fileManager.preview.dialog.actions' }),
    custom: intl.formatMessage({ id: 'fileManager.preview.dialog.custom' }),
  }

  const mapTypeToFormatLabel: Record<SupportedTypes, string> = {
    number: intl.formatMessage({ id: 'fileManager.preview.dialog.number.format' }),
    float: intl.formatMessage({ id: 'fileManager.preview.dialog.float.format' }),
    dateTime: intl.formatMessage({ id: 'fileManager.preview.dialog.dateTime.format' }),
    date: intl.formatMessage({ id: 'fileManager.preview.dialog.date.format' }),
    string: intl.formatMessage({ id: 'fileManager.preview.dialog.string.format' }),
    boolean: intl.formatMessage({ id: 'fileManager.preview.dialog.boolean.format' }),
    singleSelect: intl.formatMessage({ id: 'fileManager.preview.dialog.singleSelect.format' }),
    actions: intl.formatMessage({ id: 'fileManager.preview.dialog.actions.format' }),
    custom: intl.formatMessage({ id: 'fileManager.preview.dialog.custom.format' }),
  }

  const color = mapTypeToColor[type] || defaultColor
  const backgroundColor = mapTypeToBackgroundColor[type] || defaultBackgroundColor
  const label = mapTypeToLabel[type] || intl.formatMessage({ id: 'fileManager.preview.dialog.custom' })
  const formatLabel = mapTypeToFormatLabel[type] || intl.formatMessage({ id: 'fileManager.preview.dialog.custom.format' })

  const chipSx = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor,
    borderRadius: '3px',
    padding: '3px',
    color,

    fontVariantNumeric: 'lining-nums tabular-nums',
    fontFeatureSettings: "'ss02' on, 'ss07' on",
    fontFamily: 'Consolas',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '100%',
    letterSpacing: '-0.14px',
    textTransform: 'uppercase',
    width: 'fit-content',
  }

  return (
    <Box
      data-testid={TypeBadgeComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '5px',
        width: '100%',
      }}
    >
      <Typography
        sx={chipSx}
      >
        {label}
      </Typography>
      <Typography
        sx={chipSx}
        noWrap={true}
      >
        {formatLabel}
      </Typography>
    </Box>
  )
}

export interface DataGridColumnTypeDefinitionComponentProps {
  /**
   * DataGrid column params.
   */
  params: GridRenderHeaderFilterProps
  /**
   * Custom type for the column.
   * DataGrid column types sometimes are not enough to describe the column type.
   * Custom type will be used instead of the one provided in DataGrid params.
   */
  customType?: SupportedTypes
}
export const DataGridColumnTypeDefinitionComponent: React.FC<DataGridColumnTypeDefinitionComponentProps> = ({ params, customType }) => {
  const type = customType || params.colDef.type || 'string'

  return (
    <Box
      data-testid={DataGridColumnTypeDefinitionComponent.name}
      sx={{
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <TypeBadgeComponent type={type} />
    </Box>
  )
}

export const DataGridColumnTypeDefinitionHeaderFilter = (props: GridHeaderFilterCellProps) => {
  const {
    colDef, width, height, colIndex, tabIndex,
  } = props

  return (
    <Stack
      tabIndex={tabIndex}
      data-field={colDef.field}
      width={width}
      height={height}
      justifyContent='center'
      alignItems='center'
      role='columnheader'
      aria-colindex={colIndex + 1}
      className={gridClasses.columnHeader}
      aria-label={colDef.headerName ?? colDef.field}
      sx={{
        padding: '9px 10px',
        boxShadow: 'none',
      }}
    >
      <DataGridColumnTypeDefinitionComponent
        params={props as GridRenderHeaderFilterProps}
      />
    </Stack>
  )
}
