import React from 'react'
import {
  Box,
  BoxProps,
  useTheme,
} from '@mui/material'
import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'

export interface IntlFormatChipComponentProps extends BoxProps {
  /**
   * Children to be rendered
   */
  children?: React.ReactNode
  /**
   * Variant of the chip
   */
  variant?: 'code' | 'primary'
}

export const IntlFormatChipComponent: React.FC<IntlFormatChipComponentProps> = ({
  children,
  variant = 'primary',
}) => {
  const theme = useTheme()

  return (
    <Box
      component='span'
      display='inline'
      data-testid={IntlFormatChipComponent.name}
      sx={{
        borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
        padding: '2px 4px',
        ...(variant === 'code' && {
          color: theme.palette.new.business_black,
          backgroundColor: theme.palette.new.business_black_10,
          fontSize: '12px',
          fontWeight: '500',
          fontFamily: 'monospace',
        }),
        ...(variant === 'primary' && {
          color: theme.palette.new.versatile_violet,
          backgroundColor: theme.palette.new.versatile_violet_10,
          fontSize: '12px',
          fontWeight: '500',
          fontFamily: 'monospace',
        }),
      }}
    >
      {children}
    </Box>
  )
}

export default IntlFormatChipComponent
