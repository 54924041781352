import produce from 'immer'

import { CommonState, ReducerPayload } from '@redux/modules/types'
import { getSidebarState } from '@utils/local-storage.utils'

export const initialState: CommonState = {
  toast: {
    open: false,
    message: '',
    type: 'none',
  },
  sidebar: getSidebarState(),
  chatbot: false,
  unresolvedRequests: 0,
  fetchingKeys: [],
}

export const incrementUnresolvedRequestsReducer = (state: CommonState) => {
  const newState = { ...state }
  const unresolvedRequests = newState.unresolvedRequests

  newState.unresolvedRequests = unresolvedRequests + 1

  return newState
}

export const decrementUnresolvedRequestsReducer = (state: CommonState) => {
  const newState = { ...state }
  const unresolvedRequests = newState.unresolvedRequests

  newState.unresolvedRequests = unresolvedRequests - 1

  return newState
}

export const changeToastReducer = (state: CommonState, action: ReducerPayload<Common.ToastPayload>) => {
  const nextState = produce(state, (draftState) => {
    draftState.toast = {
      ...draftState.toast,
      ...action.payload,
    }
  })

  return nextState
}

export const changeSidebarStateReducer = (state: CommonState, action: ReducerPayload<boolean>) => {
  const newState = { ...state }

  newState.sidebar = action.payload

  return newState
}

export const changeChatbotStateReducer = (state: CommonState, action: ReducerPayload<boolean>) => {
  const newState = { ...state }

  newState.chatbot = action.payload

  return newState
}
