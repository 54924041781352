import React, { useMemo } from 'react'
import { Router } from 'react-router-dom'
import { History } from 'history'
import { CacheProvider } from '@emotion/react'
import { Store } from 'redux'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { GlobalStyles } from '@mui/material'
import createCache from '@emotion/cache'

import theme from '@configuration/theme.config'
import IntlProvider from '@decorators/ReactIntl.decorator'
import DatePickerProvider from '@decorators/DatePicker.decorator'
import AuthLoadingComponent from '@base/loading/AuthLoading'
import useAppInit from '@hooks/useAppInit.hook'
import ErrorBoundaryContainer, { ERROR_BOUNDARY_ERROR_TYPES } from '@containers/application/ErrorBoundary/ErrorBoundary.container'
import RouterContainer from '@containers/application/Router'
import GlobalContainer from '@containers/application/Global'

interface AppContainerProps {
  /**
   * History object
   */
  reduxHistory: History,
  /**
   * Redux store
   */
  store: Store,
}

const muiCache = createCache({
  key: 'mui',
  prepend: true,
})

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      body: {
        fontFeatureSettings: '"ss02" on, "dlig" on',
        fontVariantLigatures: 'discretionary-ligatures',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      },
      '* > .MuiTypography-root': {
        fontFamily: 'AeonikPro, Roboto, Helvetica, Arial, sans-serif',
        fontFeatureSettings: '"ss02" on, "dlig" on',
        fontVariantLigatures: 'discretionary-ligatures',
      },
    }}
  />
)

const AppContainer: React.FC<AppContainerProps> = ({ store, reduxHistory }) => {
  const {
    keycloak,
    loading,
    authError,
  } = useAppInit({
    store,
  })

  const itemsToRender = useMemo(() => {
    return loading ? (
      <AuthLoadingComponent error={authError} />
    ) : (
      <Router history={reduxHistory}>
        <RouterContainer keycloak={keycloak} />

        <GlobalContainer />
      </Router>
    )
  }, [loading, authError, keycloak, reduxHistory])

  return (
    <ErrorBoundaryContainer type={ERROR_BOUNDARY_ERROR_TYPES.GLOBAL}>
      <Provider store={store}>
        <IntlProvider>
          <ErrorBoundaryContainer type={ERROR_BOUNDARY_ERROR_TYPES.OUTER}>
            <CacheProvider value={muiCache}>
              <ThemeProvider theme={theme}>
                <DatePickerProvider>
                  {inputGlobalStyles}

                  {itemsToRender}
                </DatePickerProvider>
              </ThemeProvider>
            </CacheProvider>
          </ErrorBoundaryContainer>
        </IntlProvider>
      </Provider>
    </ErrorBoundaryContainer>
  )
}

export default AppContainer
