import throttle from 'lodash.throttle'
import { checkApiForError } from '@utils/api.utils'
import { AXIOS_UPLOAD_TIMEOUT_IN_MS, USE_CASE } from '@constants/api-provider.constants'
import { EMITTER_TYPES, DEFAULT_THROTTLE_TIMEOUT } from '@constants/emitter.constants'
import { isLegacyUploadCheck } from '@utils/use-cases.utils'
import eventEmitter, { axiosCancelTokenController } from '@utils/emitter.utils'
import apiProvider from '@redux/api-provider'

import {
  CreateFileIdentifiersPayload,
  DownloadTrainingDataPayload,
  ListTrainingDataPayload,
  RemoveTrainingDataPayload,
  RequestFileIdentifiersListActionPayload,
  UploadTrainingDataPayload,
  RemoveFileIdentifiersPayload,
  CreateFileIdentifierVersionActionPayload,
  RemoveFileIdentifierVersionActionPayload,
} from './training-files.types'

export const uploadTrainingData = (payload: UploadTrainingDataPayload = {} as UploadTrainingDataPayload) => {
  const isLegacyUpload = isLegacyUploadCheck(payload.meta.fileIdentifier)
  const URL = isLegacyUpload ? 'v7/training-data-file/general/upload' : 'v7/training-data-dataset/file/upload'

  return apiProvider.getApi(USE_CASE).post(URL, payload.data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: AXIOS_UPLOAD_TIMEOUT_IN_MS,
    signal: axiosCancelTokenController.controller.signal,
    onUploadProgress: throttle((progressEvent) => {
      eventEmitter.emit(
        EMITTER_TYPES.FILE_UPLOAD_PROGRESS,
        {
          name: payload.meta.fileName,
          total: progressEvent.total,
          loaded: progressEvent.loaded,
        },
      )
    }, DEFAULT_THROTTLE_TIMEOUT),
  }).then(({ data }) => {
    checkApiForError(data)

    const filesDetais = data.data || {}

    return filesDetais
  })
}

export const createFileIdentifier = (payload: CreateFileIdentifiersPayload = {} as CreateFileIdentifiersPayload) => {
  const URL = 'v7/training-data-dataset/create'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const removeFileIdentifier = (payload: RemoveFileIdentifiersPayload = {} as RemoveFileIdentifiersPayload) => {
  const URL = 'v7/training-data-dataset/delete'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const listFileIdentifiersData = (payload: RequestFileIdentifiersListActionPayload = {} as RequestFileIdentifiersListActionPayload) => {
  const URL = 'v8/training-data-dataset/list'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)

    return data.data.datasets || [] as TrainingFiles.FileIdentifierItem[]
  })
}

export const listTrainingData = (payload: ListTrainingDataPayload = {} as ListTrainingDataPayload) => {
  const isLegacyUpload = isLegacyUploadCheck(payload.fileIdentifier)
  const URL = isLegacyUpload ? 'v7/training-data-file/general/list' : 'v8/training-data-dataset/file/list'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)

    return isLegacyUpload ? data.data.generalFiles : data.data.files
  })
}

export const getTrainingDataFilesDownloadToken = ({
  useCaseId,
  trainingDataFileId,
} : DownloadTrainingDataPayload) => {
  const URL = 'v6/trainingDataFiles/download-token/get'
  const payload = {
    trainingDataFileId,
    useCaseId,
  }

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)
    return data.data.downloadToken
  })
}

export const removeTrainingData = ({
  useCaseId,
  trainingDataFileId,
} : RemoveTrainingDataPayload) => {
  const URL = 'v6/trainingDataFiles/remove'
  const payload = {
    trainingDataFileId,
    useCaseId,
  }

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const createVersion = ({
  useCaseId,
  fileIdentifier,
} : CreateFileIdentifierVersionActionPayload) => {
  const URL = 'v7/training-data-dataset/version/create'
  const payload = {
    useCaseId,
    fileIdentifier,
  }

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const removeVersion = ({
  useCaseId,
  fileIdentifier,
  version,
} : RemoveFileIdentifierVersionActionPayload) => {
  const URL = 'v7/training-data-dataset/version/delete'
  const payload = {
    useCaseId,
    fileIdentifier,
    version,
  }

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)
  })
}
