import { useCallback, useMemo } from 'react'

import {
  DataGridPremiumProps,
  GRID_ACTIONS_COLUMN_TYPE,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridLogicOperator,
  GridColDef,
  GRID_CHECKBOX_SELECTION_FIELD,
} from '@mui/x-data-grid-premium'

import { DataGridComponentProps } from '@base/datagrid/data-grid/DataGrid.component'
import { getActionsMenuWidth } from '@utils/data-grid.utils'

export interface useDataGridSlotPropsProps {
  uploading: DataGridComponentProps['uploading']
  disabled: DataGridComponentProps['disabled']
  groupingModel: DataGridComponentProps['groupingModel']
  groupingMode: DataGridComponentProps['groupingMode']
  enableGroupExpand: DataGridComponentProps['enableGroupExpand']
  columns: DataGridComponentProps['columns']
  slotProps: DataGridComponentProps['slotProps']
  onRowGroupingModelChange: DataGridPremiumProps['onRowGroupingModelChange']
}

export const useDataGridSlotProps = ({
  slotProps,
  disabled,
  groupingModel,
  enableGroupExpand,
  columns,
  groupingMode,
  onRowGroupingModelChange,
  uploading,
}: useDataGridSlotPropsProps) => {
  const actionsMenuWidth = useMemo(() => getActionsMenuWidth(columns as GridColDef[]), [columns])

  const getTogglableColumns = useCallback((cols: GridColDef[]) => {
    const hiddenFields = [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field, GRID_CHECKBOX_SELECTION_FIELD, GRID_ACTIONS_COLUMN_TYPE]

    return cols
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field)
  }, [])

  const finalSlotProps = useMemo(() => {
    const paginationSlotProps = slotProps?.pagination || {}
    const { disabledPagination, ...paginationProps } = paginationSlotProps

    return {
      ...slotProps,
      columnsManagement: {
        sx: {},
        getTogglableColumns,
        ...slotProps?.columnsManagement,
      },
      columnMenu: {
        ...slotProps?.columnMenu,
        disableGrouping: !enableGroupExpand,
      },
      toolbar: {
        ...slotProps?.toolbar,
        groupingMode,
        groupingModel,
        onRowGroupingModelChange,
      },
      pagination: {
        disabled: disabled || disabledPagination,
        nextIconButtonProps: {
          disabled: disabled || disabledPagination,
        },
        backIconButtonProps: {
          disabled: disabled || disabledPagination,
        },
        ...paginationProps,
      },
      noRowsOverlay: {
        ...slotProps?.noRowsOverlay,
      },
      loadingOverlay: {
        ...slotProps?.loadingOverlay,
        actionsMenuWidth,
        uploading,
      },
      baseTooltip: {
        ...slotProps?.baseTooltip,
        arrow: true,
      },
      baseFormControl: {
        ...slotProps?.baseFormControl,
        variant: 'outlined',
        size: 'small',
      } as any,
      baseTextField: {
        ...slotProps?.baseTextField,
        variant: 'outlined',
        size: 'small',
      } as any,
      baseCheckbox: {
        groupingModel,
        ...slotProps?.baseCheckbox,
      },
      filterPanel: {
        ...slotProps?.filterPanel,
        logicOperators: slotProps?.filterPanel?.logicOperators || [GridLogicOperator.And, GridLogicOperator.Or],
        filterFormProps: {
          logicOperatorInputProps: {
            variant: 'outlined',
            size: 'small',
          },
          columnInputProps: {
            variant: 'outlined',
            size: 'small',
          },
          operatorInputProps: {
            variant: 'outlined',
            size: 'small',
          },
          valueInputProps: {
            InputComponentProps: {
              variant: 'outlined',
              size: 'small',
            },
          },
        },
      },
    } as DataGridPremiumProps['slotProps']
  }, [
    slotProps, disabled, groupingModel,
    enableGroupExpand, groupingMode,
    actionsMenuWidth, onRowGroupingModelChange,
    uploading, getTogglableColumns,
  ])

  return finalSlotProps
}
