import moment from 'moment'
import { getReactIntlLocaleByShortCode } from '@decorators/ReactIntl.decorator'
import { FULL_LOCALES, SHORT_LOCALES } from '@constants/locales.constants'
import { IntlShape } from 'react-intl'

/**
 * @function isValueInRange Checks whether value is in the provided range
 *
 * @param {Number} value Value in range 1:0
 * @param {Array} range Array of [min, max] values in which value can be presented
 *
 * @return {Boolean} true, if value in range
 */
export const isValueInRange = (value: number, range?: number[]): boolean => {
  if (!range) {
    return true
  }

  const min = range[0]
  const max = range[1]

  return (value <= max) && (value >= min)
}

/**
 * @function normalizeValue Normalizes value in %
 *
 * @param {Number} value Value to normalize
 * @param {Number} min Value minimum
 * @param {Number} max Value maximum
 *
 * @return {Number} Normalized value
 */
export const normalizeValue = (
  value: number,
  min: number = 0,
  max: number = 100,
): number => {
  if ((value === min) && (value === max)) {
    return 100
  }

  return ((value - min) * 100) / (max - min)
}

/**
 * @function defaultNumberFormatter Returns formatted number
 *
 * @param {Number | String} value value to format
 * @param {Object} options Intl.NumberFormatOptions & locale
 *
 * @return {Number | String} Returns formatted number
 */
export const defaultNumberFormatter = (value: number | string | null, options?: {
  float?: boolean,
  numberFormatOptions?: Intl.NumberFormatOptions,
  intl?: IntlShape,
}): string => {
  const num = Number(value)
  const {
    numberFormatOptions,
    intl,
    float,
  } = options || {}

  const momentLocale = moment.locale() as SHORT_LOCALES
  const momentFullLocale = getReactIntlLocaleByShortCode(momentLocale)
  const finalLocale = intl ? (intl.locale as FULL_LOCALES) : momentFullLocale

  return isNaN(num) ? String(value) : num.toLocaleString(finalLocale, {
    maximumFractionDigits: float ? 2 : 0,
    minimumFractionDigits: 0,
    ...numberFormatOptions,
  })
}

/**
 * @function formatNumberWithSuffix Returns formatted number
 *
 * @param {Number | String} value value to format
 *
 * @return {String} Returns formatted number
 */
export const formatNumberWithSuffix = (value?: number | string) => {
  if (value === undefined) {
    return 'n/a'
  }

  const num = Number(value)

  if (isNaN(num)) {
    return 'n/a'
  }

  const suffixes = ['', 'k', 'M', 'B', 'T']
  const order = Math.floor(Math.log10(Math.abs(num)) / 3)

  const formattedNumber = defaultNumberFormatter((num / Math.pow(10, order * 3)), {
    numberFormatOptions: {
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
    },
  })

  if (!suffixes[order]) {
    return formattedNumber
  }

  return formattedNumber + suffixes[order]
}
