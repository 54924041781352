import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'
import { useRouteMatch } from 'react-router-dom'

import {
  Box,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { isSettingUpDefaultRoutings } from '@redux/modules/notifications/notifications.selectors'
import { getIsAdmin, getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { NOTIFICATIONS_ROUTINGS_SETUP_MODAL_NAME } from '@constants/modals.constants'
import { setupNotificationsRoutingsAction } from '@redux/modules/notifications/notifications.actions'

import TextFieldComponent from '@base/forms/TextField'

const NotificationsRoutingsSetupModalContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isAdmin = useSelector(getIsAdmin)
  const isSettingUpRoutings = useSelector(isSettingUpDefaultRoutings)
  const modalPageName = useSelector(getOpenedModal)
  const selectedCompanyId = useSelector(getSelectedCompanyId)

  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  const open = modalPageName === NOTIFICATIONS_ROUTINGS_SETUP_MODAL_NAME

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName(''))
    }
  }

  const handleSubmitAction = (values: {
    companyId: string
    useCaseId: string
    slackChannel?: string
  }) => {
    dispatch(
      setupNotificationsRoutingsAction({
        companyId: selectedCompanyId,
        useCaseId: usecase,
        ...(values.slackChannel ? { slackChannel: values.slackChannel } : {}),
      }),
    )
  }

  const formik = useFormik({
    initialValues: {
      companyId: selectedCompanyId,
      useCaseId: usecase,
      slackChannel: '',
    },
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
  })

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    isValid,
    errors,
    touched,
    values,
    dirty,
  } = formik

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, dispatch, resetForm])

  return (
    <SidePanelComponent
      open={open && isAdmin}
      title={intl.formatMessage({ id: 'notifications.routings.setup_default' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSettingUpRoutings}
    >
      <SidePanelLoadingComponent loading={false}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer>
                <FormLayoutItem xs={12} container={true}>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='companyId'
                      label={intl.formatMessage({ id: 'notifications.routings.fields.companyId' })}
                      value={values.companyId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors.companyId}
                      touched={touched.companyId}
                      disabled={true}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='useCaseId'
                      label={intl.formatMessage({ id: 'notifications.routings.fields.useCaseId' })}
                      value={values.useCaseId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors.useCaseId}
                      touched={touched.useCaseId}
                      disabled={true}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
                <FormLayoutItem xs={12} container={true}>
                  <FormLayoutItem xs={12}>
                    <TextFieldComponent
                      name='slackChannel'
                      label={intl.formatMessage({ id: 'notifications.routings.setup_default.slack_channel' })}
                      value={values.slackChannel}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors.slackChannel}
                      touched={touched.slackChannel}
                      floatingHelp={intl.formatMessage({ id: 'notifications.routings.setup_default.slack_channel.help' })}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='notificationsRoutingsSetupModalBackButton'
              onClick={() => handleCloseAction()}
              type='cancel'
            />

            <ModalButtonComponent
              name='notificationsRoutingsSetupModalSubmitButton'
              onClick={(e) => handleSubmitAction(values)}
              loading={isSettingUpRoutings}
              disabled={isSettingUpRoutings || !isValid}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default NotificationsRoutingsSetupModalContainer
