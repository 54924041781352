import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from '@redux/hooks'
import { useFormik } from 'formik'
import { Box } from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelButtonContainerComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
  SidePanelActionButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { AddCompanyUsersPayload, EditCompanyUsersPayload } from '@redux/modules/customer/customer.types'
import { DEFAULT_LOCALE_SHORT, SUPPORTED_LOCALES } from '@constants/locales.constants'
import { createUserAction, updateUserAction } from '@redux/modules/customer/customer.actions'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { getKeycloakAccountManagementUrl } from '@utils/common.utils'
import { COMPANY_USERS_MODAL_NAME } from '@constants/modals.constants'

import {
  getSelectedCompanyId,
  isFetching as isFetchingCustomer,
  isSubmitting as isSubmittingCustomer,
} from '@redux/modules/customer/customer.selectors'

import TextFieldComponent from '@base/forms/TextField'
import ProfileIcon from '@icons/profile.icon'
import SelectFieldComponent from '@base/forms/SelectField'

import companyUsersValidations from './CompanyUsersModal.validations'

export interface CompanyUsersModalPayload {
  record: Customer.UserItem | null,
  returnTo: string,
}

export interface CompanyUsersModalContainerProps {
  keycloak?: KeycloakTypes.Keycloak
  handleClose?: {
    (): any,
  },
}

const CompanyUsersModalContainer: React.FC<CompanyUsersModalContainerProps> = ({
  handleClose, keycloak,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const isSubmitting = useSelector(isSubmittingCustomer)
  const isFetching = useSelector(isFetchingCustomer)
  const selectedCompanyId = useSelector(getSelectedCompanyId)
  const modalPageName = useSelector(getOpenedModal)
  const modalDetails = useSelector(getModalDetails<CompanyUsersModalPayload>)
  const open = modalPageName === COMPANY_USERS_MODAL_NAME
  const customerId = keycloak?.subject!
  const isCurrentUser = Boolean(modalDetails.record && (customerId === modalDetails.record.customerId))

  const handleAdvancedProfileSettingsClick = () => {
    if (!isCurrentUser) {
      return
    }

    const url = getKeycloakAccountManagementUrl()

    window.open(url, '_blank')?.focus()
  }

  const edit = useMemo(() => {
    return modalDetails.record !== null
  }, [modalDetails])

  const initialValues = useMemo(() => {
    if (edit && modalDetails.record) {
      return modalDetails.record
    }

    return {
      firstName: '',
      lastName: '',
      email: '',
      language: DEFAULT_LOCALE_SHORT,
    } as Customer.UserItem
  }, [modalDetails, edit])

  const handleModalClose = () => {
    if (handleClose) {
      handleClose()
    }

    dispatch(
      setPrimaryModalPageName(''),
    )
  }

  const handleModalSubmit = (values: Customer.UserItem) => {
    const commonPayload = {
      companyId: selectedCompanyId,
      firstName: values.firstName,
      lastName: values.lastName,
      language: values.language,
      email: values.email,
    }

    const payload = edit ? {
      ...commonPayload,
      customerId: initialValues.customerId,
    } : {
      ...commonPayload,
    }

    if (edit) {
      dispatch(
        updateUserAction(payload as EditCompanyUsersPayload),
      )
    } else {
      dispatch(
        createUserAction(payload as AddCompanyUsersPayload),
      )
    }

    handleModalClose()
  }

  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    isValid,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleModalSubmit,
    enableReinitialize: true,
    validationSchema: companyUsersValidations(intl),
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  const modalTitle = edit ? (
    intl.formatMessage({ id: 'customers.users.modal.edit' }, { name: modalDetails?.record?.email })
  ) : (
    intl.formatMessage({ id: 'customers.users.modal.create' })
  )

  return (
    <SidePanelComponent
      open={open}
      title={modalTitle}
      handleClose={handleModalClose}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={isFetching}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer>
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    name='firstName'
                    touched={touched.firstName}
                    errors={errors.firstName}
                    value={values.firstName}
                    label={intl.formatMessage({ id: 'customers.users.form.firstName.title' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    name='lastName'
                    touched={touched.lastName}
                    errors={errors.lastName}
                    value={values.lastName}
                    label={intl.formatMessage({ id: 'customers.users.form.lastName.title' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    name='email'
                    touched={touched.email}
                    errors={errors.email}
                    value={values.email}
                    label={intl.formatMessage({ id: 'customers.users.form.email.title' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={edit}
                    type='email'
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={6}>
                  <SelectFieldComponent
                    name='language'
                    label={intl.formatMessage({ id: 'customers.users.form.language.title' })}
                    options={SUPPORTED_LOCALES}
                    value={values.language || DEFAULT_LOCALE_SHORT}
                    onChange={(e) => setFieldValue('language', e.target.value)}
                    disabled={false}
                    useIntlForLabel={true}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
            <SidePanelButtonContainerComponent hidden={true}>
              <SidePanelActionButtonComponent
                name='advancedProfileSettingsButton'
                StartIconComponent={ProfileIcon}
                label={intl.formatMessage({ id: 'common.layout.button.advancedProfileSettings' })}
                onClick={handleAdvancedProfileSettingsClick}
              />
            </SidePanelButtonContainerComponent>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='companyUsersModalCloseButton'
              onClick={handleModalClose}
              type='cancel'
              disabled={isSubmitting}
            />

            <ModalButtonComponent
              name='companyUsersModalSubmitButton'
              onClick={(e) => handleModalSubmit(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !dirty || !isValid}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default CompanyUsersModalContainer
