import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import {
  Box,
  Typography,
  useTheme,
} from '@mui/material'

import { DEFAULT_PADDING } from '@constants/ui.constants'
import { generateNotificationAction, generateNotificationContent } from '@utils/notifications.utils'

import ButtonComponent from '@base/buttons/Button'

import NotificationTileHeaderBlockComponent from './NotificationTileHeaderBlock.component'

export type NotificationTileVariants = 'default' | 'highlighted' | 'warning' | 'error'

export interface NotificationTileComponentProps {
  notification: Notifications.EnrichedNotificationItem
  borderBottom?: boolean
  handleModalClose: () => void
  onDismissButtonClick: (notification: Notifications.NotificationItem) => void
  onMarkAsReadButtonClick: (notification: Notifications.NotificationItem) => void
  level: 'use_case' | 'company'
  isAdmin?: boolean
}

export const NotificationTileComponent: React.FC<NotificationTileComponentProps> = ({
  notification,
  borderBottom,
  handleModalClose,
  onDismissButtonClick,
  onMarkAsReadButtonClick,
  isAdmin,
  level,
}) => {
  const history = useHistory()
  const intl = useIntl()
  const theme = useTheme()

  const {
    actionButtonColor,
    actionButtonLabel,
    actionButtonAction,
    actionButtonDisabled,
  } = generateNotificationAction({
    intl,
    isAdmin,
    history,
    notification,
    handleModalClose,
    onMarkAsReadButtonClick,
  })

  const content = generateNotificationContent(intl, notification)
  const shouldFadeOut = notification.read || notification.dismissed

  return (
    <Box
      data-testid={NotificationTileComponent.name}
      sx={{
        padding: DEFAULT_PADDING.LARGE,
        borderBottom: borderBottom ? `1px solid ${theme.palette.new.business_black_20}` : 'none',
      }}
    >
      <Box
        display='flex'
        flexDirection='column'
        gap='10px'
        sx={{
          width: '100%',
          opacity: shouldFadeOut ? 0.2 : 1,
        }}
      >
        <NotificationTileHeaderBlockComponent
          notification={notification}
          level={level}
          isAdmin={isAdmin}
          onDismissButtonClick={onDismissButtonClick}
          onMarkAsReadButtonClick={onMarkAsReadButtonClick}
        />

        <Box
          display='flex'
          flexDirection='row'
          alignItems='flex-end'
          justifyContent='space-between'
          width='100%'
          gap='40px'
        >
          <Box
            display='flex'
          >
            <Typography
              variant='body1'
              fontWeight={400}
            >
              {content}
            </Typography>
          </Box>

          <Box
            display='flex'
          >
            <ButtonComponent
              name='notificationActionButton'
              label={actionButtonLabel}
              onClick={actionButtonAction}
              disabled={actionButtonDisabled}
              rounded={true}
              color={actionButtonColor}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default NotificationTileComponent
