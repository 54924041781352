export const PARAMETER_TYPES = {
  INPUT: 'input',
  OUTPUT: 'output',
}

export const ARTIFACT_TYPES = {
  PREDICTION: 'PREDICTION',
}

export enum USE_CASE_FAMILY_TYPES {
  DEMAND = 'DEMAND',
  ARTICLE_ALLOCATION = 'ARTICLE_ALLOCATION',
  REPLENISHMENT = 'REPLENISHMENT',
  WORKFORCE_PLANNING = 'WORKFORCE_PLANNING',
  SALES_PLANNING = 'SALES_PLANNING',
  CUSTOM = 'CUSTOM',
}

export enum VISIBILITY_STATES {
  CUSTOMER = 'CUSTOMER',
  ADMIN = 'ADMIN',
}

export enum EVALUATION_PROFILE_FILTER_OPERATORS {
  EQUALS = 'equals',
  CONTAINS = 'contains',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
}

export const EVALUATION_PROFILE_FILTER_OPERATORS_OPTIONS_LIST = [
  {
    value: EVALUATION_PROFILE_FILTER_OPERATORS.EQUALS,
    labelKey: 'connect.modal.evaluationProfile.metadataFilter.operator.equals',
  },
  {
    value: EVALUATION_PROFILE_FILTER_OPERATORS.CONTAINS,
    labelKey: 'connect.modal.evaluationProfile.metadataFilter.operator.contains',
  },
  {
    value: EVALUATION_PROFILE_FILTER_OPERATORS.STARTS_WITH,
    labelKey: 'connect.modal.evaluationProfile.metadataFilter.operator.startsWith',
  },
  {
    value: EVALUATION_PROFILE_FILTER_OPERATORS.ENDS_WITH,
    labelKey: 'connect.modal.evaluationProfile.metadataFilter.operator.endsWith',
  },
]

export const USE_CASE_FAMILY_OPTIONS_LIST = [{
  value: USE_CASE_FAMILY_TYPES.ARTICLE_ALLOCATION,
  labelKey: 'use_cases.family.types.article_allocation',
}, {
  value: USE_CASE_FAMILY_TYPES.DEMAND,
  labelKey: 'use_cases.family.types.demand',
}, {
  value: USE_CASE_FAMILY_TYPES.REPLENISHMENT,
  labelKey: 'use_cases.family.types.replenishment',
}, {
  value: USE_CASE_FAMILY_TYPES.SALES_PLANNING,
  labelKey: 'use_cases.family.types.sales_planning',
}, {
  value: USE_CASE_FAMILY_TYPES.WORKFORCE_PLANNING,
  labelKey: 'use_cases.family.types.workforce_planning',
}, {
  value: USE_CASE_FAMILY_TYPES.CUSTOM,
  labelKey: 'use_cases.family.types.custom',
}]

export const DEFAULT_USE_CASE_FAMILY_TYPE = USE_CASE_FAMILY_TYPES.CUSTOM
