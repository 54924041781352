import React, { useMemo } from 'react'
import { Box, useTheme, Typography } from '@mui/material'
import { useIntl } from 'react-intl'

import {
  NO_UNIT_LABEL_PLACEHOLDER,
  FLOW_ELEMENTS_TRANSITIONS,
  EVALUATION_PROFILE_BLOCK_HEIGHT,
  EVALUATION_PROFILE_BLOCK_WIDTHS,
} from '@constants/flow.constants'

import { trackEvent, TRACKING_ACTIONS } from '@utils/tracking.utils'
import { TIME_RESOLUTION_OPTIONS } from '@constants/date.constants'

export interface ReactFlowEvaluationProfileBlockComponentProps {
  /**
   * Element's onClick handler
   */
  onClick?: {
    (e?: React.SyntheticEvent): any,
  },
  /**
   * Title of the element
   */
  name?: string,
  /**
   * Time resolution
   */
  timeResolution?: Common.TIME_RESOLUTION
  /**
   * Decision maker
   */
  decisionMaker?: string,
  /**
   * Data test id
   */
  dataTestId?: string,
}

const ReactFlowEvaluationProfileBlockComponent: React.FC<ReactFlowEvaluationProfileBlockComponentProps> = ({
  onClick,
  name,
  timeResolution,
  decisionMaker,
  dataTestId,
}) => {
  const theme = useTheme()
  const intl = useIntl()
  const timeResolutionLabelKey = TIME_RESOLUTION_OPTIONS.find((option) => option.value === timeResolution)?.labelKey

  const handleOnClick = (e: React.MouseEvent) => {
    trackEvent({
      componentName: 'evaluationProfileBlock',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      name,
    })

    if (onClick) {
      onClick(e)
    }
  }

  const rootStyles = useMemo(() => {
    const root = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: EVALUATION_PROFILE_BLOCK_WIDTHS,
      height: EVALUATION_PROFILE_BLOCK_HEIGHT,
      backgroundColor: theme.palette.new.white,
      border: `1px solid ${theme.palette.new.grey_a}`,
      padding: theme.spacing(1.5, 2, 1.5, 1.5),
      marginTop: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      outline: 'none',
      ...FLOW_ELEMENTS_TRANSITIONS,

      '&:hover': {
        border: `1px solid ${theme.palette.new.grey_c}`,
        backgroundColor: theme.palette.new.grey,
      },
      '&:focus-visible': {
        border: `1px solid ${theme.palette.new.grey_c}`,
        backgroundColor: theme.palette.new.grey,
      },
    }

    if (onClick) {
      Object.assign(root, {
        cursor: 'pointer',
      })
    }

    return root
  }, [
    theme,
    onClick,
  ])

  const nameStyles = useMemo(() => {
    const root = {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      color: theme.palette.new.black,
      width: EVALUATION_PROFILE_BLOCK_WIDTHS - 40,
    }

    return root
  }, [
    theme,
  ])

  return (
    <Box
      data-testid={dataTestId || ReactFlowEvaluationProfileBlockComponent.name}
      sx={rootStyles}
      onClick={handleOnClick}
      role='button'
      tabIndex={0}
    >
      <Typography
        variant='body1'
        className='name'
        sx={nameStyles}
        title={name}
      >
        {name}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: theme.spacing(2),
          gap: '5px',
        }}
      >
        <Typography
          variant='overline'
          noWrap={true}
          sx={{
            display: 'block',
            alignItems: 'center',
            letterSpacing: '-0.2px',
            color: theme.palette.new.grey_c,
            maxWidth: '180px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {decisionMaker || NO_UNIT_LABEL_PLACEHOLDER}
        </Typography>

        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
        >
          <Typography
            variant='overline'
            sx={{
              display: 'block',
              alignItems: 'flex-end',
              fontWeight: 500,
              letterSpacing: '-0.2px',
              color: theme.palette.new.versatile_violet,
            }}
            noWrap={true}
          >
            {timeResolutionLabelKey ? intl.formatMessage({ id: timeResolutionLabelKey }) : NO_UNIT_LABEL_PLACEHOLDER}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ReactFlowEvaluationProfileBlockComponent
