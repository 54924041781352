import { createAction } from 'redux-actions'

import {
  START_FETCHING_NOTIFICATIONS,
  STOP_FETCHING_NOTIFICATIONS,

  REQUEST_DISPATCHED_NOTIFICATIONS,
  RECEIVE_DISPATCHED_NOTIFICATIONS,

  REQUEST_UNREAD_NOTIFICATIONS_COUNT,
  RECEIVE_UNREAD_NOTIFICATIONS_COUNT,

  MARK_NOTIFICATIONS_AS_READ,
  DISMISS_NOTIFICATIONS,

  REQUEST_NOTIFICATIONS_ROUTINGS,
  RECEIVE_NOTIFICATIONS_ROUTINGS,
  REQUEST_NOTIFICATIONS_ROUTING_GRAPH,
  RECEIVE_NOTIFICATIONS_ROUTING_GRAPH,
  SETUP_NOTIFICATIONS_ROUTINGS,
  CREATE_NOTIFICATIONS_ROUTINGS,
  UPDATE_NOTIFICATIONS_ROUTINGS,
  REMOVE_NOTIFICATIONS_ROUTINGS,

  REQUEST_NOTIFICATIONS_SINKS,
  RECEIVE_NOTIFICATIONS_SINKS,
  CREATE_NOTIFICATIONS_SINK,
  UPDATE_NOTIFICATIONS_SINK,
  REMOVE_NOTIFICATIONS_SINK,
  TEST_NOTIFICATIONS_SINK,

  REQUEST_NOTIFICATIONS_TEMPLATES,
  RECEIVE_NOTIFICATIONS_TEMPLATES,
  CREATE_NOTIFICATIONS_TEMPLATE,
  UPDATE_NOTIFICATIONS_TEMPLATE,
  REMOVE_NOTIFICATIONS_TEMPLATE,

  MARK_NOTIFICATIONS_ALL_AS_READ,

  SEND_NOTIFICATION,
  REQUEST_NOTIFICATIONS,
  RECEIVE_NOTIFICATIONS,

  RECEIVE_NOTIFICATIONS_PAGINATION_META,
  CHANGE_NOTIFICATIONS_PAGINATION_META,
} from './notifications.action-types'

export const requestNotificationsAction = createAction<Notifications.RequestNotificationsAction>(REQUEST_NOTIFICATIONS)
export const receiveNotificationsAction = createAction(RECEIVE_NOTIFICATIONS)

export const changeNotificationsPaginationMetaAction = createAction<Notifications.NotificationsPaginationMetaAction>(CHANGE_NOTIFICATIONS_PAGINATION_META)
export const receiveNotificationsPaginationMetaAction = createAction(RECEIVE_NOTIFICATIONS_PAGINATION_META)

export const requestUnreadNotificationsCountAction = createAction<Notifications.APIGetUnreadNotificationsCountRequest>(REQUEST_UNREAD_NOTIFICATIONS_COUNT)
export const receiveUnreadNotificationsCountAction = createAction(RECEIVE_UNREAD_NOTIFICATIONS_COUNT)

export const sendNotificationAction = createAction<Notifications.SendNotificationRequest>(SEND_NOTIFICATION)

export const markNotificationsAllAsReadAction = createAction<Notifications.NotificationsMarkAllAsReadRequest>(MARK_NOTIFICATIONS_ALL_AS_READ)
export const markNotificationsAsReadAction = createAction<Notifications.MarkNotificationsAsReadAction>(MARK_NOTIFICATIONS_AS_READ)
export const dismissNotificationsAction = createAction<Notifications.DismissNotificationsAction>(DISMISS_NOTIFICATIONS)

export const requestDispatchedNotificationsAction = createAction<Notifications.DispatchedNotificationsRequest>(REQUEST_DISPATCHED_NOTIFICATIONS)
export const receiveDispatchedNotificationsAction = createAction(RECEIVE_DISPATCHED_NOTIFICATIONS)

export const requestNotificationsRoutingsAction = createAction<Notifications.NotificationsRoutingsRequest>(REQUEST_NOTIFICATIONS_ROUTINGS)
export const receiveNotificationsRoutingsAction = createAction(RECEIVE_NOTIFICATIONS_ROUTINGS)

export const requestNotificationsRoutingGraphAction = createAction<Notifications.GetRoutingGraphRequest>(REQUEST_NOTIFICATIONS_ROUTING_GRAPH)
export const receiveNotificationsRoutingGraphAction = createAction(RECEIVE_NOTIFICATIONS_ROUTING_GRAPH)

export const setupNotificationsRoutingsAction = createAction<Notifications.SetupNotificationsRoutingsAction>(SETUP_NOTIFICATIONS_ROUTINGS)
export const createNotificationsRoutingsAction = createAction<Notifications.CreateRoutingAction>(CREATE_NOTIFICATIONS_ROUTINGS)
export const updateNotificationsRoutingsAction = createAction<Notifications.UpdateRoutingAction>(UPDATE_NOTIFICATIONS_ROUTINGS)
export const removeNotificationsRoutingsAction = createAction<Notifications.RemoveRoutingAction>(REMOVE_NOTIFICATIONS_ROUTINGS)

export const requestNotificationsSinksAction = createAction<Notifications.NotificationsSinksRequestAction>(REQUEST_NOTIFICATIONS_SINKS)
export const receiveNotificationsSinksAction = createAction(RECEIVE_NOTIFICATIONS_SINKS)
export const createNotificationsSinkAction = createAction<Notifications.CreateNotificationsSinkRequest>(CREATE_NOTIFICATIONS_SINK)
export const updateNotificationsSinkAction = createAction<Notifications.UpdateNotificationsSinkRequest>(UPDATE_NOTIFICATIONS_SINK)
export const removeNotificationsSinkAction = createAction<Notifications.RemoveNotificationsSinkRequest>(REMOVE_NOTIFICATIONS_SINK)
export const testNotificationsSinkAction = createAction<Notifications.TestNotificationsSinkRequest>(TEST_NOTIFICATIONS_SINK)

export const requestNotificationsTemplatesAction = createAction<Notifications.NotificationsTemplatesRequest>(REQUEST_NOTIFICATIONS_TEMPLATES)
export const receiveNotificationsTemplatesAction = createAction(RECEIVE_NOTIFICATIONS_TEMPLATES)
export const createNotificationsTemplateAction = createAction<Notifications.CreateNotificationTemplate>(CREATE_NOTIFICATIONS_TEMPLATE)
export const updateNotificationsTemplateAction = createAction<Notifications.UpdateNotificationTemplate>(UPDATE_NOTIFICATIONS_TEMPLATE)
export const removeNotificationsTemplateAction = createAction<Notifications.RemoveNotificationTemplate>(REMOVE_NOTIFICATIONS_TEMPLATE)

export const startFetchingNotificationsAction = createAction<string>(START_FETCHING_NOTIFICATIONS)
export const stopFetchingNotificationsAction = createAction<string>(STOP_FETCHING_NOTIFICATIONS)
