import { IntlShape } from 'react-intl'
import { Edge, Node, Position } from '@xyflow/react'

import {
  NODE_TYPES,
  NODES_HEIGHT,
  NODES_WIDTHS,
  MAX_SOURCES_TO_SHOW,
  INPUT_SOURCE_BLOCK_HEIGHT,
  INPUT_SOURCE_BLOCK_HEIGHT_MARGIN,

  OUTPUT_PARAMETER_BLOCK_WIDTHS,
  OUTPUT_PARAMETER_BLOCK_HEIGHT,
  OUTPUT_PARAMETER_BLOCK_HEIGHT_MARGIN,
  OUTPUT_PARAMETER_BLOCK_WIDTHS_MARGIN,

  GROUPING_ATTRIBUTES_BLOCK_HEIGHT,
  GROUPING_ATTRIBUTES_BLOCK_WIDTHS,
  GROUPING_ATTRIBUTES_BLOCK_WIDTHS_MARGIN,
  GROUPING_ATTRIBUTES_BLOCK_HEIGHT_MARGIN,
  GROUPING_ATTRIBUTES_PER_ROW,

  EVALUATION_PROFILE_BLOCK_HEIGHT,
  EVALUATION_PROFILE_BLOCK_WIDTHS,
  EVALUATION_PROFILE_BLOCK_WIDTHS_MARGIN,
  EVALUATION_PROFILE_BLOCK_HEIGHT_MARGIN,
  EVALUATION_PROFILE_PER_ROW,

  INPUT_PARAMETER_BLOCK_HEIGHT,
  INPUT_PARAMETER_BLOCK_WIDTHS,
  INPUT_PARAMETER_BLOCK_WIDTHS_MARGIN,
  INPUT_PARAMETER_BLOCK_HEIGHT_MARGIN,
  INPUT_PARAMETER_PER_ROW,
  TARGET_PARAMETER_PER_ROW,

  SOURCE_TYPES_TO_ICONS_MAP,
  SOURCE_TYPES_OPTIONS,
  CATEGORIES_TO_LABEL_MAP,
  SOURCE_TYPES,

  SourceTypeOption,
  NodeTypeValues,
} from '@constants/flow.constants'

import { AUTH_DATA_SOURCE_MODAL_NAME } from '@constants/modals.constants'

export enum DIRECTIONS {
  LR = 'LR',
  TB = 'TB',
}

/**
 * Returns minimum item count
 * @param numOfItems Number of items
 * @returns Minimum item count
 */
export const getMinimumItemCount = (numOfItems?: number): number => {
  return (!numOfItems || (numOfItems <= 1)) ? 1 : numOfItems
}

/**
 * Returns height of the targets node group
 * @param numOfNodes Number of nodes
 * @returns Height of the targets node group
 */
export const getTargetsGroupHeigth = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numOfNodesWrapped = (minNodesPresent > TARGET_PARAMETER_PER_ROW) ? TARGET_PARAMETER_PER_ROW : minNodesPresent

  return (NODES_HEIGHT[NODE_TYPES.TARGETS_GROUP] + (numOfNodesWrapped * OUTPUT_PARAMETER_BLOCK_HEIGHT) + ((numOfNodesWrapped - 1) * OUTPUT_PARAMETER_BLOCK_HEIGHT_MARGIN))
}

/**
 * Returns width of the targets node group
 * @param numOfNodes Number of nodes
 * @returns Width of the targets node group
 */
export const getTargetsGroupWidth = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numberOfCols = Math.ceil(minNodesPresent / TARGET_PARAMETER_PER_ROW)

  return (NODES_WIDTHS[NODE_TYPES.TARGETS_GROUP] + ((numberOfCols - 1) * OUTPUT_PARAMETER_BLOCK_WIDTHS) + ((numberOfCols - 1) * OUTPUT_PARAMETER_BLOCK_WIDTHS_MARGIN))
}

/**
 * Returns height of the grouping attributes node group
 * @param numOfNodes Number of nodes
 * @returns Height of the grouping attributes node group
 */
export const getGroupingAttributesHeigth = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numOfNodesWrapped = (minNodesPresent > GROUPING_ATTRIBUTES_PER_ROW) ? GROUPING_ATTRIBUTES_PER_ROW : minNodesPresent

  return (
    NODES_HEIGHT[NODE_TYPES.GROUPING_ATTRIBUTES] + (numOfNodesWrapped * GROUPING_ATTRIBUTES_BLOCK_HEIGHT) + ((numOfNodesWrapped - 1) * GROUPING_ATTRIBUTES_BLOCK_HEIGHT_MARGIN)
  )
}

/**
 * Returns width of the grouping attributes node group
 * @param numOfNodes Number of nodes
 * @returns Width of the grouping attributes node group
 */
export const getGroupingAttributesWidth = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numberOfCols = Math.ceil(minNodesPresent / GROUPING_ATTRIBUTES_PER_ROW)

  return (
    NODES_WIDTHS[NODE_TYPES.GROUPING_ATTRIBUTES] + ((numberOfCols - 1) * GROUPING_ATTRIBUTES_BLOCK_WIDTHS) + ((numberOfCols - 1) * GROUPING_ATTRIBUTES_BLOCK_WIDTHS_MARGIN)
  )
}

/**
 * Returns height of the evaluation profile node group
 * @param numOfNodes Number of nodes
 * @returns Height of the evaluation profile group
 */
export const getEvaluationProfileGroupHeight = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numberOfRows = Math.ceil(minNodesPresent / EVALUATION_PROFILE_PER_ROW)

  return (NODES_HEIGHT[NODE_TYPES.EVALUATION_PROFILE_GROUP] + (numberOfRows * EVALUATION_PROFILE_BLOCK_HEIGHT) + ((numberOfRows - 1) * EVALUATION_PROFILE_BLOCK_HEIGHT_MARGIN))
}

/**
 * Returns width of the evaluation profile node group
 * @param numOfNodes Number of nodes
 * @returns Width of the evaluation profile group
 */
export const getEvaluationProfileGroupWidth = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numOfNodesWrapped = (minNodesPresent > EVALUATION_PROFILE_PER_ROW) ? EVALUATION_PROFILE_PER_ROW : minNodesPresent

  return (
    NODES_WIDTHS[NODE_TYPES.EVALUATION_PROFILE_GROUP] + (numOfNodesWrapped * EVALUATION_PROFILE_BLOCK_WIDTHS) + ((numOfNodesWrapped - 1) * EVALUATION_PROFILE_BLOCK_WIDTHS_MARGIN)
  )
}

/**
 * Returns height of the source node group
 * @param numOfNodes Number of nodes
 * @returns Height of the source group
 */
export const getInputsGroupHeight = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numberOfRows = Math.ceil(minNodesPresent / INPUT_PARAMETER_PER_ROW)

  return (NODES_HEIGHT[NODE_TYPES.INPUTS_GROUP] + (numberOfRows * INPUT_PARAMETER_BLOCK_HEIGHT) + ((numberOfRows - 1) * INPUT_PARAMETER_BLOCK_HEIGHT_MARGIN))
}

/**
 * Returns width of the source node group
 * @param numOfNodes Number of nodes
 * @returns Width of the source group
 */
export const getInputsGroupWidth = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numOfNodesWrapped = (minNodesPresent > INPUT_PARAMETER_PER_ROW) ? INPUT_PARAMETER_PER_ROW : minNodesPresent

  return (NODES_WIDTHS[NODE_TYPES.INPUTS_GROUP] + (numOfNodesWrapped * INPUT_PARAMETER_BLOCK_WIDTHS) + ((numOfNodesWrapped - 1) * INPUT_PARAMETER_BLOCK_WIDTHS_MARGIN))
}

/**
 * Returns height of the source node group
 * @param numOfSources Number of sources
 * @returns Height of the source group
 */
export const getSourceGroupHeight = (numOfSources?: number): number => {
  /**
   * In order to follow the design, we show only MAX_SOURCES_TO_SHOW blocks.
   * When there more source blocks we show - More block which has unusual height.
   */
  const minSourcesPresent = getMinimumItemCount(numOfSources)
  const shouldShowShowMoreBlock = (minSourcesPresent > MAX_SOURCES_TO_SHOW)
  const numOfSourcesSplitted = shouldShowShowMoreBlock ? (MAX_SOURCES_TO_SHOW + 2) : minSourcesPresent
  const heightDifference = shouldShowShowMoreBlock ? -38 : 0

  return (NODES_HEIGHT[NODE_TYPES.INPUT] + ((numOfSourcesSplitted + 1) * INPUT_SOURCE_BLOCK_HEIGHT) + INPUT_SOURCE_BLOCK_HEIGHT_MARGIN) + heightDifference
}

/**
 * Returns layouted elements for React Flow
 * @param dagre dagre instance
 * @param dagreGraph dagre graph instance
 * @param nodes List of nodes
 * @param edges List of edges
 * @param direction Direction
 * @returns Layouted elements
 */
export function getLayoutedElements<T extends Common.ReactFlowElementData>(
  dagre: any,
  dagreGraph: any,
  nodes: Node<T>[],
  edges: Edge[],
  direction = DIRECTIONS.LR,
) {
  const isHorizontal = direction === DIRECTIONS.LR

  dagreGraph.setGraph({ rankdir: direction })

  const maxNumOfNodes = Math.max(...nodes.filter((element) => {
    return [NODE_TYPES.ACTIVE_INPUTS_GROUP, NODE_TYPES.GENERIC_INPUTS_GROUP, NODE_TYPES.INPUTS_GROUP].includes(element.type as any)
  }).map((element) => {
    const elData = element.data as UseCase.ConnectOverviewElementData

    return elData.numOfNodes!
  }))

  nodes.forEach((el) => {
    const connectViewData = el.data as UseCase.ConnectOverviewElementData

    switch (el.type) {
      case NODE_TYPES.INPUT:
        dagreGraph.setNode(el.id, { width: NODES_WIDTHS[el.type], height: getSourceGroupHeight(connectViewData.numOfSources) })
        break
      case NODE_TYPES.ACTIVE_INPUTS_GROUP:
      case NODE_TYPES.GENERIC_INPUTS_GROUP:
      case NODE_TYPES.INPUTS_GROUP:
        dagreGraph.setNode(el.id, { width: getInputsGroupWidth(connectViewData.numOfNodes), height: getInputsGroupHeight(connectViewData.numOfNodes) })
        break
      case NODE_TYPES.TARGETS_GROUP:
        dagreGraph.setNode(el.id, { width: getTargetsGroupWidth(connectViewData.numOfNodes), height: getTargetsGroupHeigth(connectViewData.numOfNodes) })
        break
      case NODE_TYPES.GROUPING_ATTRIBUTES:
        dagreGraph.setNode(el.id, { width: getGroupingAttributesWidth(connectViewData.numOfNodes), height: getGroupingAttributesHeigth(connectViewData.numOfNodes) })
        break
      case NODE_TYPES.EVALUATION_PROFILE_GROUP:
        dagreGraph.setNode(el.id, { width: getEvaluationProfileGroupWidth(connectViewData.numOfNodes), height: getEvaluationProfileGroupHeight(connectViewData.numOfNodes) })
        break
      default:
        dagreGraph.setNode(el.id, { width: NODES_WIDTHS[el.type as NodeTypeValues], height: NODES_HEIGHT[el.type as NodeTypeValues] })
        break
    }
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  const layoutedNodes = nodes.map((node) => {
    const el = { ...node }
    const connectViewData = el.data as UseCase.ConnectOverviewElementData

    const nodeWithPosition = dagreGraph.node(el.id)
    const defaultHeight = 200

    let nodeY = nodeWithPosition.y
    let nodeX = nodeWithPosition.x + Math.random() / 1000

    el.targetPosition = (isHorizontal ? 'left' : 'top') as Position
    el.sourcePosition = (isHorizontal ? 'right' : 'bottom') as Position

    switch (el.type) {
      case NODE_TYPES.INPUT:
        nodeX = nodeWithPosition.x + 20
        nodeY = nodeWithPosition.y - (getSourceGroupHeight(connectViewData.numOfSources) / 2) + 100
        break
      case NODE_TYPES.EMPTY_GROUPING:
        nodeX = nodeWithPosition.x + 180
        nodeY = nodeWithPosition.y + ((defaultHeight - NODES_HEIGHT[el.type]) / 2)
        break
      case NODE_TYPES.ACTIVE_INPUTS_GROUP:
      case NODE_TYPES.GENERIC_INPUTS_GROUP:
      case NODE_TYPES.INPUTS_GROUP:
        nodeX = nodeWithPosition.x - (getInputsGroupWidth(maxNumOfNodes) / 2) + 240
        nodeY = nodeWithPosition.y - (getInputsGroupHeight(connectViewData.numOfNodes) / 2) + 100
        break
      case NODE_TYPES.TARGETS_GROUP:
        nodeX = nodeWithPosition.x - (getTargetsGroupWidth(connectViewData.numOfNodes) / 2) + 265
        nodeY = nodeWithPosition.y - (getTargetsGroupHeigth(connectViewData.numOfNodes) / 2) + 100
        break
      case NODE_TYPES.GROUPING_ATTRIBUTES:
        nodeX = nodeWithPosition.x - (getGroupingAttributesWidth(connectViewData.numOfNodes) / 2) + 230
        nodeY = nodeWithPosition.y - (getGroupingAttributesHeigth(connectViewData.numOfNodes) / 2) + 100
        break
      case NODE_TYPES.EVALUATION_PROFILE_GROUP:
        nodeX = nodeWithPosition.x - (getEvaluationProfileGroupWidth(connectViewData.numOfNodes) / 2) + 280
        nodeY = nodeWithPosition.y - (getEvaluationProfileGroupHeight(connectViewData.numOfNodes) / 2) + 100
        break
      default:
        nodeX = nodeWithPosition.x + 140
        nodeY = nodeWithPosition.y + ((defaultHeight - NODES_HEIGHT[el.type as NodeTypeValues]) / 2)
        break
    }

    // unfortunately we need this little hack to pass a slighltiy different position
    // in order to notify react flow about the change
    el.position = {
      x: nodeX,
      y: nodeY,
    }

    return el
  })

  return {
    nodes: layoutedNodes,
    edges,
  }
}

export interface MappedSources {
  /**
   * Source type option source id, defined in Hermes
   */
  sourceId?: string,
  /**
   * Source type option key
   */
  key: string,
  /**
   * Source type option title
   */
  title: string,
  /**
   * Source type option description
   */
  description: string,
  /**
   * Source type option help
   */
  help: string,
  /**
   * Source type option status text
   */
  statusText: string | null,
  /**
   * Source type option icon
   */
  icon?: any,
  /**
   * Source type option documentation link
   */
  documentationLink?: string,
  /**
   * Source type option video link
   */
  videoLink?: string,
  /**
   * Source type option video height
   */
  videoHeight?: string,
  /**
   * Source type option category
   */
  category: string,
  /**
   * Source type option category label
   */
  categoryLabel: string,
  /**
   * Source type option disabled flag
   */
  disabled: boolean,
  /**
   * Source type option onChange handler
   */
  onChange?: any,
  /**
   * If true, source type option configuration is available.
   * Otherwise, "On Request" will be displayed.
   */
  isConnectorAvailable: boolean,
}

interface SourceHandlers {
  handleCustomSourceClick: (modalName: string, sourceItems: any) => void
  handleStaticSourceClick: (value: string) => void
}

interface SourceMappingParams {
  connectionsList: Hermes.ConnectionDetails[]
  fileVersionsCount: number
  useCaseFileIdentifiersCount: number
  handleCustomSourceClick: SourceHandlers['handleCustomSourceClick']
  handleStaticSourceClick: SourceHandlers['handleStaticSourceClick']
}

/**
 * Checks if connector is available performing the following checks:
 *  - Source type option is not marked as "On Request"
 *    - Company ID is not in the list of exceptions
 *    - Connector is active
 *    - User is admin
 *  - Additional checks:
 *    - Connector is supported by Hermes or custom
 *
 *  @param params Parameters
 *  @param params.sourceId Source ID
 *  @param params.isCustomFiles Is custom files connector
 *  @param params.isParetos Is paretos connector
 *  @param params.onRequest Is on request connector
 *  @param params.onRequestExceptions List of company IDs that are exceptions for "On Request" connectors
 *  @param params.companyId Company ID
 *  @param params.isConnectorActive Is connector active
 *  @param params.isAdmin Is user admin
 *
 *  @returns True if connector is available, false otherwise
 */
export const isConnectorAvailable = ({
  sourceId,
  isCustomFiles,
  isParetos,
  onRequest,
  onRequestExceptions,
  companyId,
  isConnectorActive,
  isAdmin,
}: {
  sourceId: string | undefined,
  isCustomFiles: boolean,
  isParetos: boolean,
  onRequest: boolean,
  onRequestExceptions: string[],
  companyId: string,
  isConnectorActive: boolean,
  isAdmin: boolean,
}): boolean => {
  const hasPermission = !onRequest ||
    onRequestExceptions.includes(companyId) ||
    isConnectorActive ||
    isAdmin

  const isValidConnector = Boolean(sourceId || isCustomFiles || isParetos)

  return hasPermission && isValidConnector
}

/**
 * Gets connection details for a Hermes connector
 *
 * @param sourceId Source ID
 * @param connectionsList List of Hermes connections
 *
 * @returns Connection details
 */
export const getConnectionDetails = (
  sourceId: string | undefined,
  connectionsList: Hermes.ConnectionDetails[],
) => {
  if (!sourceId) {
    return {
      activeConnections: [],
      allConnections: [],
    }
  }

  const allConnections = connectionsList.filter(
    (connection) => connection.sourceId === sourceId,
  )

  const activeConnections = allConnections.filter(
    (connection) => connection.isConnectedToUseCase,
  )

  return {
    activeConnections,
    allConnections,
  }
}

/**
 * Creates the onChange handler for a source
 */
export const createChangeHandler = ({
  isDynamicConnector,
  isHermesConnector,
  allConnectionsCount,
  modalName,
  sourceItems,
  handlers,
}: {
  isDynamicConnector: boolean,
  isHermesConnector: boolean,
  allConnectionsCount: number,
  modalName: string,
  sourceItems: Partial<MappedSources>,
  handlers: SourceHandlers,
}) => {
  if (!isDynamicConnector) {
    return handlers.handleStaticSourceClick(sourceItems.key!)
  }

  return () => {
    if (isHermesConnector && allConnectionsCount === 0) {
      return handlers.handleCustomSourceClick(AUTH_DATA_SOURCE_MODAL_NAME, sourceItems)
    }

    return handlers.handleCustomSourceClick(modalName, sourceItems)
  }
}

/**
 * Matches sources to Hermes API connectors
 *
 * @param intl React Intl
 * @param isAdmin Is user admin
 * @param params Parameters
 * @returns Mapped sources
 */
export const matchSourcesToApi = (
  intl: IntlShape,
  isAdmin: boolean,
  companyId: string,
  params: SourceMappingParams,
): MappedSources[] => {
  const {
    connectionsList = [],
    fileVersionsCount = 0,
    useCaseFileIdentifiersCount = 0,
    handleCustomSourceClick,
    handleStaticSourceClick,
  } = params

  return SOURCE_TYPES_OPTIONS
    .filter((item) => !item.hidden)
    .map(({
      sourceId,
      onRequest,
      onRequestExceptions,
      value,
      labelKey,
      descriptionKey,
      helpKey,
      documentationLink,
      videoLink,
      videoHeight,
      category,
      modalName = '',
    }) => {
      const isCustomFiles = value === SOURCE_TYPES.CUSTOM
      const isParetos = value === SOURCE_TYPES.PARETOS

      const { activeConnections, allConnections } = getConnectionDetails(
        sourceId,
        connectionsList,
      )

      const connectorAvailable = isConnectorAvailable({
        sourceId,
        isCustomFiles,
        isParetos,
        onRequest,
        onRequestExceptions,
        companyId,
        isConnectorActive: activeConnections.length > 0,
        isAdmin,
      })

      const isHermesConnector = Boolean(sourceId)
      const isDynamicConnector = (isCustomFiles || isHermesConnector || isParetos) && connectorAvailable

      const sourceItems: MappedSources = {
        key: value,
        title: labelKey ? intl.formatMessage({ id: labelKey }) : '',
        description: descriptionKey ? intl.formatMessage({ id: descriptionKey }) : '',
        help: helpKey ? intl.formatMessage({ id: helpKey }) : '',
        statusText: '',
        icon: SOURCE_TYPES_TO_ICONS_MAP[value],
        documentationLink,
        videoLink,
        videoHeight,
        category,
        disabled: useCaseFileIdentifiersCount === 0 && isCustomFiles && !isAdmin,
        categoryLabel: (category && CATEGORIES_TO_LABEL_MAP[category]) ?
          intl.formatMessage({ id: CATEGORIES_TO_LABEL_MAP[category] }) :
          '',
        isConnectorAvailable: connectorAvailable,
        ...(isCustomFiles && {
          statusText: intl.formatMessage(
            { id: 'connect.modal.data_sources.custom_files' },
            { fileVersionsCount },
          ),
        }),
        ...(isHermesConnector && {
          statusText: activeConnections.length ?
            intl.formatMessage(
              { id: 'connect.modal.data_sources.connections_count' },
              { connectionsCount: activeConnections.length },
            ) : (
              null
            ),
          sourceId,
        }),
      }

      return {
        ...sourceItems,
        onChange: createChangeHandler({
          isDynamicConnector,
          isHermesConnector,
          allConnectionsCount: allConnections.length,
          modalName,
          sourceItems,
          handlers: { handleCustomSourceClick, handleStaticSourceClick },
        }),
      }
    })
}

export interface SourceTypeOptionExtended extends SourceTypeOption {
  isDisabled: boolean,
}

/**
 * Enriches source type options with additional properties
 * @param item Source type option
 * @param connectionsList List of Hermes connections
 * @param trainingFilesCount Number of training files
 * @param isAdmin Is user admin
 * @returns Enriched source type option
 */
export const enrichSourceTypeOptions = (
  item: SourceTypeOption,
  connectionsList: Hermes.ConnectionDetails[],
  trainingFilesCount: number,
  isAdmin: boolean,
): SourceTypeOptionExtended => {
  const isNoConnection = item.value === SOURCE_TYPES.NO_CONNECTION
  const isFileUploadConnected = (item.value === SOURCE_TYPES.CUSTOM) && trainingFilesCount > 0
  const isHermesConnected = (connectionsList.filter((hermesItem) => {
    return ((hermesItem.sourceId === item.sourceId) && hermesItem.isConnectedToUseCase)
  }) || []).length > 0

  return {
    isDisabled: !(isNoConnection || isFileUploadConnected || isHermesConnected) && !isAdmin,
    ...item,
  }
}
