import React, { ReactNode } from 'react'
import { IntlShape } from 'react-intl'
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid-premium'
import { getTableStateFromStorage } from '@utils/data-grid.utils'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { createInsightsDataGridState } from '@utils/insights.utils'
import { DEFAULT_DATE_PICKER_FORMAT, toUnixTimestamp } from '@utils/moment.utils'
import { TIME_RESOLUTION } from '@constants/date.constants'
import { CUSTOM_COL_HEADER_CLASS_NAME, FORCED_RIGHT_BORDER_CLASS_NAME } from '@constants/data-grid.constants'
import { transformColumnLabel, getAllowedOperators } from '@redux/modules/hera/hera.utils'
import {
  DEFAULT_BACKTEST_TARGET_COLUMN,
  DEFAULT_BACKTEST_PREDICTION_COLUMN,
  DEFAULT_LIVE_MONITORING_TARGET_COLUMN,
  DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN,
  DEFAULT_BASELINE_COMPARISON_TARGET_COLUMN,
  DEFAULT_BASELINE_COMPARISON_PREDICTION_COLUMN,
  DEFAULT_BASELINE_COMPARISON_BASELINE_COLUMN,
  DEFAULT_REL_DEVIATION_COLUMN,
  DEFAULT_ABS_DEVIATION_COLUMN,
  DEFAULT_PARETOS_REL_DEVIATION_COLUMN,
  DEFAULT_PARETOS_ABS_DEVIATION_COLUMN,
  INSIGHTS_PINNED_COLUMN_DEFAULT_HEADER_ALIGN,
  INSIGHTS_PINNED_PREDICTION_MIN_WIDTH,
  INSIGHTS_PINNED_COLUMN_DEFAULT_WIDTH,
  DEFAULT_BASELINE_ABS_DEVIATION_COLUMN,
  DEFAULT_BASELINE_REL_DEVIATION_COLUMN,
} from '@constants/insights.constants'

import DataGridCustomColHeaderComponent from '@base/datagrid/data-grid-custom-col-header'
import IntlFormatBoldComponent from '@base/utils/IntlFormatBold/IntlFormatBold.component'

/**
 * Creates the initial data grid state for live monitoring
 *
 * @param tableId Table id
 *
 * @returns merged table configs
 */
export const createBacktestingDataGridState = (tableId: string, initialState: Monitor.BacktestingGridState) => {
  const tableStateFromStorage = getTableStateFromStorage<Monitor.BacktestingGridState>(tableId)
  const parsedLsState = (tableStateFromStorage?.localStorageState || {}) as Monitor.BacktestingGridState

  const customState: Partial<Monitor.BacktestingGridState> = {
    backtestingFold: parsedLsState?.backtestingFold || initialState.backtestingFold,
  }

  return createInsightsDataGridState<Monitor.BacktestingGridState>(tableId, initialState, customState)
}

/**
 * Creates the initial data grid state for live monitoring
 *
 * @param tableId Table id
 *
 * @returns merged table configs
 */
export const createLiveMonitoringDataGridState = (tableId: string, initialState: Monitor.LiveMonitoringGridState) => {
  const tableStateFromStorage = getTableStateFromStorage<Monitor.LiveMonitoringGridState>(tableId)
  const parsedLsState = (tableStateFromStorage?.localStorageState || {}) as Monitor.LiveMonitoringGridState

  const customState: Partial<Monitor.LiveMonitoringGridState> = {
    liveForecastHorizonOffset: parsedLsState?.liveForecastHorizonOffset || initialState.liveForecastHorizonOffset,
  }

  return createInsightsDataGridState<Monitor.LiveMonitoringGridState>(tableId, initialState, customState)
}

/**
 * Creates the initial data grid state for baseline comparison
 * @param tableId ID of the table
 * @param initialState Initial state
 * @returns Merged table configs
 */
export const createBaselineComparisonDataGridState = (tableId: string, initialState: Monitor.BaselineComparisonGridState) => {
  const tableStateFromStorage = getTableStateFromStorage<Monitor.BaselineComparisonGridState>(tableId)
  const parsedLsState = (tableStateFromStorage?.localStorageState || {}) as Monitor.BaselineComparisonGridState

  const customState: Partial<Monitor.BaselineComparisonGridState> = {
    liveForecastHorizonOffset: parsedLsState?.liveForecastHorizonOffset || initialState.liveForecastHorizonOffset,
    timeStepRange: parsedLsState?.timeStepRange || initialState.timeStepRange,
    timeCompensation: parsedLsState?.timeCompensation || initialState.timeCompensation,
    itemCompensation: parsedLsState?.itemCompensation || initialState.itemCompensation,
  }

  return createInsightsDataGridState<Monitor.BaselineComparisonGridState>(tableId, initialState, customState)
}

/**
 * Returns the transformed columns with custom header names and filters
 * @param intl React Intl
 * @param columns Columns
 * @returns Columns with custom header names and filters
 */
export const transformMonitorTableColumns = (intl: IntlShape, columns?: Insights.SimplifiedGridColumnDefinition[]) => {
  if (!columns) {
    return []
  }

  return columns.map((column) => {
    const colCopy = { ...column } as GridColDef

    const targetFields = [
      DEFAULT_BACKTEST_TARGET_COLUMN,
      DEFAULT_LIVE_MONITORING_TARGET_COLUMN,
      DEFAULT_BASELINE_COMPARISON_TARGET_COLUMN,
    ]

    const predictionFields = [
      DEFAULT_BACKTEST_PREDICTION_COLUMN,
      DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN,
      DEFAULT_BASELINE_COMPARISON_PREDICTION_COLUMN,
    ]

    const absDeviationFields = [
      DEFAULT_ABS_DEVIATION_COLUMN,
      DEFAULT_PARETOS_ABS_DEVIATION_COLUMN,
      DEFAULT_BASELINE_ABS_DEVIATION_COLUMN,
    ]

    const relDeviationFields = [
      DEFAULT_REL_DEVIATION_COLUMN,
      DEFAULT_PARETOS_REL_DEVIATION_COLUMN,
      DEFAULT_BASELINE_REL_DEVIATION_COLUMN,
    ]

    if (DEFAULT_BASELINE_COMPARISON_BASELINE_COLUMN === colCopy.field) {
      Object.assign(colCopy, {
        headerAlign: INSIGHTS_PINNED_COLUMN_DEFAULT_HEADER_ALIGN,
        minWidth: INSIGHTS_PINNED_PREDICTION_MIN_WIDTH,
        headerName: intl.formatMessage({
          id: 'insights.table.baselineGroup',
        }, {
          name: column.headerName,
        }),
        headerClassName: `${CUSTOM_COL_HEADER_CLASS_NAME}`,
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <DataGridCustomColHeaderComponent
              params={params}
              subtitle={intl.formatMessage({ id: 'insights.table.sum' })}
            />
          )
        },
      })
    } else if (predictionFields.includes(colCopy.field)) {
      Object.assign(colCopy, {
        headerAlign: INSIGHTS_PINNED_COLUMN_DEFAULT_HEADER_ALIGN,
        minWidth: INSIGHTS_PINNED_PREDICTION_MIN_WIDTH,
        headerName: intl.formatMessage({
          id: 'insights.table.predictionGroup',
        }, {
          name: column.headerName,
        }),
        headerClassName: `${CUSTOM_COL_HEADER_CLASS_NAME}`,
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <DataGridCustomColHeaderComponent
              params={params}
              subtitle={intl.formatMessage({ id: 'insights.table.sum' })}
            />
          )
        },
      })
    } else if (targetFields.includes(colCopy.field)) {
      Object.assign(colCopy, {
        ...column,
        headerAlign: INSIGHTS_PINNED_COLUMN_DEFAULT_HEADER_ALIGN,
        minWidth: INSIGHTS_PINNED_COLUMN_DEFAULT_WIDTH,
        headerName: intl.formatMessage({
          id: 'insights.table.targetGroup',
        }, {
          name: colCopy.headerName,
        }),
        headerClassName: CUSTOM_COL_HEADER_CLASS_NAME,
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <DataGridCustomColHeaderComponent
              params={params}
              subtitle={intl.formatMessage({ id: 'insights.table.sum' })}
            />
          )
        },
      })
    } else if (relDeviationFields.includes(colCopy.field)) {
      Object.assign(colCopy, {
        headerAlign: INSIGHTS_PINNED_COLUMN_DEFAULT_HEADER_ALIGN,
        minWidth: INSIGHTS_PINNED_COLUMN_DEFAULT_WIDTH,
        headerName: intl.formatMessage({
          id: 'insights.table.relDeviationGroup',
        }),
        headerClassName: `${FORCED_RIGHT_BORDER_CLASS_NAME} ${CUSTOM_COL_HEADER_CLASS_NAME}`,
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <DataGridCustomColHeaderComponent
              params={params}
              subtitle={intl.formatMessage({ id: 'insights.table.wape' })}
              tooltip={intl.formatMessage({ id: 'insights.table.wape.help' }, {
                name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.wape.label' })}</IntlFormatBoldComponent>,
              })}
            />
          )
        },
        valueGetter: (value: number) => {
          if (value === null) {
            return null
          }

          return value * 100
        },
        valueFormatter: (value: number | null) => {
          if (value === null) {
            return intl.formatMessage({ id: 'common.na' })
          }

          return `${defaultNumberFormatter(value, {
            intl,
          })}%`
        },
      })
    } else if (absDeviationFields.includes(colCopy.field)) {
      Object.assign(colCopy, {
        headerAlign: INSIGHTS_PINNED_COLUMN_DEFAULT_HEADER_ALIGN,
        minWidth: INSIGHTS_PINNED_COLUMN_DEFAULT_WIDTH,
        headerName: intl.formatMessage({
          id: 'insights.table.absDeviationGroup',
        }),
        headerClassName: CUSTOM_COL_HEADER_CLASS_NAME,
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <DataGridCustomColHeaderComponent
              params={params}
              subtitle={intl.formatMessage({ id: 'insights.table.mae' })}
              tooltip={intl.formatMessage({ id: 'insights.table.mae.help' }, {
                name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.mae.label' })}</IntlFormatBoldComponent>,
              })}
            />
          )
        },
      })
    } else {
      Object.assign(colCopy, {
        headerName: transformColumnLabel(colCopy.headerName),
      })
    }

    if ((colCopy.type === 'number') && (!relDeviationFields.includes(colCopy.field))) {
      colCopy.valueFormatter = (value: number | null) => {
        if (value === null) {
          return intl.formatMessage({ id: 'common.na' })
        }

        if (!value) {
          return value
        }

        return defaultNumberFormatter(value, {
          intl,
        })
      }
    }

    return {
      ...colCopy,
      filterOperators: getAllowedOperators(intl, colCopy.type!),
    }
  })
}

/**
 * Returns the transformed columns with custom header names and filters
 * @param intl React Intl
 * @param columns Columns
 * @returns Columns with custom header names and filters
 */
export const transformBaselineComparisonTableColumns = (intl: IntlShape, targetName: string, columns?: Insights.SimplifiedGridColumnDefinition[]) => {
  if (!columns) {
    return []
  }

  const preProcessedColumns = transformMonitorTableColumns(intl, columns)

  /**
   * We need different headers for the baseline comparison table
   * Also since there are column groups, the name for the filters should be different
   * https://github.com/mui/mui-x/issues/13512
   */
  return preProcessedColumns.map((column) => {
    const colCopy = { ...column } as GridColDef

    const map: {
      [key: string]: {
        headerClassName: string
        filterHeaderName: string
        headerName: string
        headerSubtitle: string
        headerSubtitleTooltip?: string | ReactNode
      }
    } = {
      [DEFAULT_BASELINE_COMPARISON_BASELINE_COLUMN]: {
        headerClassName: CUSTOM_COL_HEADER_CLASS_NAME,
        filterHeaderName: intl.formatMessage({
          id: 'insights.table.deviationGroupBaselineField.filter',
        }, {
          name: targetName,
        }),
        headerName: intl.formatMessage({
          id: 'insights.table.deviationGroupBaselineField',
        }),
        headerSubtitle: intl.formatMessage({
          id: 'insights.table.sum',
        }),
      },
      [DEFAULT_BASELINE_COMPARISON_PREDICTION_COLUMN]: {
        headerClassName: `${FORCED_RIGHT_BORDER_CLASS_NAME} ${CUSTOM_COL_HEADER_CLASS_NAME}`,
        filterHeaderName: intl.formatMessage({
          id: 'insights.table.deviationGroupParetosField.filter',
        }, {
          name: targetName,
        }),
        headerName: intl.formatMessage({
          id: 'insights.table.deviationGroupParetosField',
        }),
        headerSubtitle: intl.formatMessage({
          id: 'insights.table.sum',
        }),
      },
      [DEFAULT_BASELINE_COMPARISON_TARGET_COLUMN]: {
        headerClassName: CUSTOM_COL_HEADER_CLASS_NAME,
        filterHeaderName: intl.formatMessage({
          id: 'insights.table.deviationGroupActualField.filter',
        }, {
          name: targetName,
        }),
        headerName: intl.formatMessage({
          id: 'insights.table.deviationGroupActualField',
        }),
        headerSubtitle: intl.formatMessage({
          id: 'insights.table.sum',
        }),
      },
      [DEFAULT_PARETOS_REL_DEVIATION_COLUMN]: {
        headerClassName: `${FORCED_RIGHT_BORDER_CLASS_NAME} ${CUSTOM_COL_HEADER_CLASS_NAME}`,
        headerName: intl.formatMessage({
          id: 'insights.table.deviationGroupParetosField',
        }),
        filterHeaderName: intl.formatMessage({
          id: 'insights.table.deviationGroupParetosField.filter.rel',
        }),
        headerSubtitle: intl.formatMessage({ id: 'insights.table.wape' }),
        headerSubtitleTooltip: intl.formatMessage({ id: 'insights.table.wape.help' }, {
          name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.wape.label' })}</IntlFormatBoldComponent>,
        }),
      },
      [DEFAULT_BASELINE_REL_DEVIATION_COLUMN]: {
        headerClassName: `${FORCED_RIGHT_BORDER_CLASS_NAME} ${CUSTOM_COL_HEADER_CLASS_NAME}`,
        headerName: intl.formatMessage({
          id: 'insights.table.deviationGroupBaselineField',
        }),
        filterHeaderName: intl.formatMessage({
          id: 'insights.table.deviationGroupBaselineField.filter.rel',
        }),
        headerSubtitle: intl.formatMessage({ id: 'insights.table.wape' }),
        headerSubtitleTooltip: intl.formatMessage({ id: 'insights.table.wape.help' }, {
          name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.wape.label' })}</IntlFormatBoldComponent>,
        }),
      },
      [DEFAULT_PARETOS_ABS_DEVIATION_COLUMN]: {
        headerClassName: `${FORCED_RIGHT_BORDER_CLASS_NAME} ${CUSTOM_COL_HEADER_CLASS_NAME}`,
        headerName: intl.formatMessage({
          id: 'insights.table.deviationGroupParetosField',
        }),
        filterHeaderName: intl.formatMessage({
          id: 'insights.table.deviationGroupParetosField.filter.abs',
        }),
        headerSubtitle: intl.formatMessage({ id: 'insights.table.mae' }),
        headerSubtitleTooltip: intl.formatMessage({ id: 'insights.table.mae.help' }, {
          name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.mae.label' })}</IntlFormatBoldComponent>,
        }),
      },
      [DEFAULT_BASELINE_ABS_DEVIATION_COLUMN]: {
        headerClassName: CUSTOM_COL_HEADER_CLASS_NAME,
        headerName: intl.formatMessage({
          id: 'insights.table.deviationGroupBaselineField',
        }),
        filterHeaderName: intl.formatMessage({
          id: 'insights.table.deviationGroupBaselineField.filter.abs',
        }),
        headerSubtitle: intl.formatMessage({ id: 'insights.table.mae' }),
        headerSubtitleTooltip: intl.formatMessage({ id: 'insights.table.mae.help' }, {
          name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.mae.label' })}</IntlFormatBoldComponent>,
        }),
      },
    }

    const filed = colCopy.field as keyof typeof map

    if (map[filed]) {
      Object.assign(colCopy, {
        headerClassName: map[filed].headerClassName,
        headerName: map[filed].filterHeaderName,
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <DataGridCustomColHeaderComponent
              params={params}
              customHeaderName={map[filed].headerName}
              subtitle={map[filed].headerSubtitle}
              tooltip={map[filed].headerSubtitleTooltip}
            />
          )
        },
      })
    }

    return colCopy
  })
}

/**
 * Formats the fold date to label
 *
 * @param intl Intl
 * @param fold Fold date
 * @param details Folds details
 *
 * @returns Formatted label
 */
export const convertFoldToLabel = (intl: IntlShape, selectedFoldDetails?: Monitor.MonitorAvailableFolds) => {
  if (!selectedFoldDetails) {
    return ''
  }

  const {
    timeResolution,
    date: foldDate,
    offsetFromToday: fold,
  } = selectedFoldDetails

  const formattedDate = foldDate.format(DEFAULT_DATE_PICKER_FORMAT)
  const intlParams = {
    fold,
    date: formattedDate,
  }

  switch (timeResolution) {
    case TIME_RESOLUTION.WEEKLY:
      return intl.formatMessage({
        id: 'insights.foldFormat.weekly',
      }, intlParams)

    case TIME_RESOLUTION.MONTHLY:
      return intl.formatMessage({
        id: 'insights.foldFormat.monthly',
      }, intlParams)

    case TIME_RESOLUTION.DAILY:
    default:
      return intl.formatMessage({
        id: 'insights.foldFormat.daily',
      }, intlParams)
  }
}

/**
 * Converts backtesting folds to options
 *
 * @param intl React Intl
 * @param backtestingAvailableFolds Available folds details
 * @returns Fold options
 */
export const foldsToOptions = (intl: IntlShape, availableFolds?: Monitor.MonitorAvailableFolds[]) => {
  if (!availableFolds) {
    return []
  }

  return availableFolds.map((foldDetails) => {
    return {
      value: foldDetails.offset,
      label: convertFoldToLabel(intl, foldDetails),
    }
  })
}

/**
 * Converts backtesting folds to chart annotations
 * @param intl React Intl
 * @param selectedFold Selected fold
 * @param backtestingAvailableFolds Available folds details
 * @returns Chart annotations
 */
export const foldsToChartAnnotations = (
  intl: IntlShape,
  selectedFold?: number,
  availableFolds?: Monitor.MonitorAvailableFolds[],
) => {
  if ((selectedFold === undefined) || !availableFolds) {
    return []
  }

  const selectedFoldDetails = availableFolds.find((fold) => fold.offset === selectedFold)

  if (!selectedFoldDetails) {
    return []
  }

  const selectedFoldTimestamp = Number(toUnixTimestamp(selectedFoldDetails.date))

  return [{
    x: selectedFoldTimestamp,
    overline: intl.formatMessage({ id: 'insights.dateOfPrediction' }),
    label: convertFoldToLabel(intl, selectedFoldDetails),
  }]
}
