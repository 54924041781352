import React, { useMemo } from 'react'

import {
  Box, styled,
  Typography,
  useTheme,
} from '@mui/material'

import { defaultNumberFormatter } from '@utils/analysis.utils'
import { useIntl } from 'react-intl'
import { getTextBackgroundFillStyles } from '@utils/ui.utils'

export const BaselineValue = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontVariantNumeric: 'tabular-nums',
  color: theme.palette.new.versatile_violet,
}))

export const ParetosValue = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontVariantNumeric: 'tabular-nums',
  ...getTextBackgroundFillStyles(),
}))

export const DifferenceValue = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  borderRadius: '3px',
  height: '19px',
  padding: '0 3px',
  display: 'inline-flex',
  alignItems: 'baseline',
  gap: '2px',
  fontVariantNumeric: 'tabular-nums',
}))

export interface BaselineKpiDeviationValuesComponentProps {
  /**
   * Baseline value
   */
  baselineValue: number
  /**
   * Paretos value
   */
  paretosValue: number
  /**
   * If true, show the sign of the difference
   */
  showSign?: boolean
  /**
   * If true, the values are relative
   */
  isRelative?: boolean
}

const BaselineKpiDeviationValuesComponent: React.FC<BaselineKpiDeviationValuesComponentProps> = ({
  baselineValue,
  paretosValue,
  showSign,
  isRelative,
}) => {
  const theme = useTheme()
  const intl = useIntl()

  /**
   * All values needs to be rounded before formatting
   * Otherwise rounding discrepancy might occur
   */
  const scale = isRelative ? 100 : 1
  const roundedBaselineValue = Math.round(baselineValue * scale)
  const roundedParetosValue = Math.round(paretosValue * scale)
  const roundedDifference = roundedParetosValue - roundedBaselineValue

  const formatterToUse = (value: number) => {
    const formattedValue = defaultNumberFormatter(value, {
      intl,
    })

    if (isRelative) {
      return `${formattedValue}%`
    }

    return formattedValue
  }

  const formatedBaselineValue = formatterToUse(roundedBaselineValue)
  const formatedParetosValue = formatterToUse(roundedParetosValue)
  const formatedDifference = formatterToUse(Math.abs(roundedDifference))

  const differenceSign = useMemo(() => {
    if (!showSign) {
      return ''
    }

    if (roundedDifference === 0) {
      return ''
    }

    if (roundedDifference > 0) {
      return '+'
    }

    return '-'
  }, [showSign, roundedDifference])

  const differenceStyles = useMemo(() => {
    if (roundedDifference === 0) {
      return {
        backgroundColor: theme.palette.new.business_black_5,
        color: theme.palette.new.business_black_40,
      }
    }

    if (roundedDifference < 0) {
      return {
        backgroundColor: theme.palette.new.generous_green_10,
        color: theme.palette.new.generous_green,
      }
    }

    return {
      backgroundColor: theme.palette.new.rebellious_red_10,
      color: theme.palette.new.rebellious_red,
    }
  }, [theme, roundedDifference])

  return (
    <Box
      data-testid={BaselineKpiDeviationValuesComponent.name}
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      gap='10px'
      width='100%'
    >
      <Box
        component='table'
        sx={{
          borderSpacing: '0px',
          marginBottom: '-5px',
        }}
      >
        <Box component='tbody'>
          <Box component='tr' lineHeight={1}>
            <Box
              component='td'
              textAlign='right'
            >
              <BaselineValue
                variant='subtitle2'
              >
                {formatedBaselineValue}
              </BaselineValue>
            </Box>
            <Box
              component='td'
              paddingLeft='10px'
            >
              <BaselineValue
                variant='subtitle2'
              >
                {intl.formatMessage({ id: 'insights.kpis.baseline' })}
              </BaselineValue>
            </Box>
            <Box component='td' />
          </Box>

          <Box component='tr' lineHeight={1}>
            <Box
              component='td'
              textAlign='right'
            >
              <ParetosValue
                variant='subtitle2'
              >
                {formatedParetosValue}
              </ParetosValue>
            </Box>
            <Box
              component='td'
              paddingLeft='10px'
            >
              <ParetosValue
                variant='subtitle2'
              >
                {intl.formatMessage({ id: 'insights.kpis.paretos' })}
              </ParetosValue>
            </Box>
            <Box component='td'>
              <DifferenceValue
                sx={differenceStyles}
              >
                <Box
                  component='span'
                  fontSize='12px'
                >
                  {differenceSign}
                </Box>

                <Box
                  component='span'
                >
                  {formatedDifference}
                </Box>
              </DifferenceValue>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BaselineKpiDeviationValuesComponent
