import React, { useLayoutEffect } from 'react'

import { useRouteMatch } from 'react-router-dom'
import { Box } from '@mui/material'

import { useDispatch, useSelector } from '@redux/hooks'
import { requestBaselineComparisonAction } from '@redux/modules/monitor/monitor.actions'
import { getBaselineComparisonChart, getBaselineComparisonTable, isBaselineComparisonInsightsAvailable } from '@redux/modules/monitor/monitor.selectors'

import EvaluationProfileModalContainer from '@containers/modals/evaluation-profile-modal'
import BaselineComparisonTableContainer from './baseline-comparison-table/BaselineComparisonTable.container'
import BaselineComparisonFiltersContainer from './baseline-comparison-filters/BaselineComparisonFilters.container'
import BaselineComparisonChartContainer from './baseline-comparison-chart/BaselineComparisonChart.container'
import BaselineComparisonKpisContainer from './baseline-comparison-kpis/BaselineComparisonKpis.container'
import BaselineComparisonEmptyContainer from './baseline-comparison-empty/BaselineComparisonEmpty.container'

export interface BaselineComparisonContainerProps {
  exportId?: string,
}

const BaselineComparisonContainer: React.FC<BaselineComparisonContainerProps> = ({ exportId }) => {
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const insightsAvailable = useSelector(isBaselineComparisonInsightsAvailable)
  const useCaseId = match.params.usecase

  useLayoutEffect(() => {
    dispatch(
      requestBaselineComparisonAction({
        useCaseId,
      }),
    )
  }, [dispatch, useCaseId])

  if (!insightsAvailable) {
    return (
      <Box
        id={exportId}
        data-testid={BaselineComparisonContainer.name}
        sx={{ backgroundColor: 'white' }}
      >
        <BaselineComparisonEmptyContainer />
      </Box>
    )
  }

  return (
    <Box
      id={exportId}
      data-testid={BaselineComparisonContainer.name}
      sx={{ backgroundColor: 'white' }}
    >
      <BaselineComparisonKpisContainer />

      <BaselineComparisonChartContainer />

      <BaselineComparisonFiltersContainer />

      <BaselineComparisonTableContainer />

      <EvaluationProfileModalContainer
        tableStateSelector={getBaselineComparisonTable}
        chartStateSelector={getBaselineComparisonChart}
      />
    </Box>
  )
}

export default BaselineComparisonContainer
