import React, { useState, useLayoutEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { COMPANY_USERS_MODAL_NAME } from '@constants/modals.constants'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { KEYCLOAK_REALM, KEYCLOAK_URI } from '@constants/env-replacements.constants'
import { Box } from '@mui/material'

import PageLayoutContainer from '@containers/application/PageLayout'
import IntlFormatBoldComponent from '@base/utils/IntlFormatBold'
import FormDialogComponent from '@base/dialogs/FormDialog'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'
import CompanyUsersOverviewComponent from '@containers/pages/(company-global)/company-users/company-users-overview'
import CallToActionButtonComponent from '@base/pagebar/CallToActionButton'
import AutocompleteSelectFieldComponent from '@base/autocomplete/AutocompleteSelectField'
import ActionButtonComponent from '@base/pagebar/ActionButton'
import DatabaseIcon from '@icons/navigation/database.icon'
import PlusIcon from '@icons/plus.icon'

import { CompanyUsersModalPayload } from '@containers/modals/company-users-modal/CompanyUsersModal.container'

import {
  deleteUserAction,
  reassignUserAction,
  requestCompanyUsersListAction,
} from '@redux/modules/customer/customer.actions'

import {
  getSelectedCompanyId,
  getCompanyUsersList,
  getCompaniesList,
  getIsAdmin,
  isFetchingCompanyUsersList,
  isSubmitting,
  getSelectedCompanyName,
} from '@redux/modules/customer/customer.selectors'
import { EXPORT_AS_CSV } from '@constants/export.constants'
import { handleExport } from '@utils/export.utils'

const initialRemoveUserDialogState = {
  open: false,
  customerId: '',
  email: '',
}

const CompanyUsersContainer = ({
  keycloak,
} : {
  keycloak: KeycloakTypes.Keycloak,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const companyUsersList = useSelector(getCompanyUsersList)
  const companiesList = useSelector(getCompaniesList)
  const isFetching = useSelector(isFetchingCompanyUsersList)
  const isSubmittingData = useSelector(isSubmitting)
  const isAdmin = useSelector(getIsAdmin)
  const selectedCompanyId = useSelector(getSelectedCompanyId)
  const companyName = useSelector(getSelectedCompanyName)
  const currentCompany = companiesList.find((item) => item.companyId === selectedCompanyId)

  const initialReasignDialogState = {
    open: false,
    email: '',
    company: currentCompany,
    customerId: '',
  }

  const [removeUserDialogState, setRemoveUserDialogState] = useState(initialRemoveUserDialogState)
  const [reasignDialogState, setReasignDialogState] = useState(initialReasignDialogState)

  useLayoutEffect(() => {
    if (selectedCompanyId) {
      dispatch(
        requestCompanyUsersListAction({ companyId: selectedCompanyId }),
      )
    }
  }, [dispatch, selectedCompanyId])

  const onCreateButtonClick = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: COMPANY_USERS_MODAL_NAME,
        modalDetails: {
          record: null,
          returnTo: '',
        } as CompanyUsersModalPayload,
      }),
    )
  }

  const handleExportAllUsers = async () => {
    const adminToken = keycloak.token
    const response = await fetch(`${KEYCLOAK_URI}admin/realms/${KEYCLOAK_REALM}/users?max=100000`, {
      method: 'GET',
      headers: {
        // eslint-disable-next-line
        'Authorization': `Bearer ${adminToken}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      const users = await response.json()

      handleExport({
        type: EXPORT_AS_CSV,
        fileName: 'paretos_users',
        exportId: '',
        data: users.filter((user: Common.KeycloakUser) => user.email).map((user: Common.KeycloakUser) => {
          return {
            id: user.id,
            email: user.email,
          }
        }),
      })

      return users
    }

    return null
  }

  const pageBarRightSideBlocks = isAdmin ? (
    <>
      {
        keycloak.hasResourceRole('view-users', 'realm-management') ? (
          <ActionButtonComponent
            name='exportAllUsersButton'
            onClick={handleExportAllUsers}
            IconComponent={DatabaseIcon}
            label={intl.formatMessage({ id: 'customers.users.export_all_users' })}
          />
        ) : (
          null
        )
      }

      <CallToActionButtonComponent
        name='createUserButton'
        StartIconComponent={PlusIcon}
        onClick={onCreateButtonClick}
        label={intl.formatMessage({ id: 'customers.users.elements.add_user' })}
      />
    </>
  ) : (
    null
  )

  const handleEdit = useCallback((record: Customer.UserItem) => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: COMPANY_USERS_MODAL_NAME,
        modalDetails: {
          record,
          returnTo: '',
        } as CompanyUsersModalPayload,
      }),
    )
  }, [dispatch])

  const handleUserDeleteConfirm = (customerId: string) => {
    dispatch(
      deleteUserAction({ companyId: selectedCompanyId, customerId }),
    )

    setRemoveUserDialogState(initialRemoveUserDialogState)
  }

  const handleUserDelete = useCallback((record: Customer.UserItem) => {
    setRemoveUserDialogState({
      open: true,
      customerId: record.customerId,
      email: record.email,
    })
  }, [])

  const handleReassign = () => {
    if (reasignDialogState.company) {
      dispatch(
        reassignUserAction({ companyId: reasignDialogState.company.companyId, customerId: reasignDialogState.customerId }),
      )
    }
  }

  const handleChangeCompany = useCallback((record: Customer.UserItem) => {
    setReasignDialogState({
      open: true,
      customerId: record.customerId,
      email: record.email,
      company: currentCompany,
    })
  }, [currentCompany])

  return (
    <PageLayoutContainer
      pageBarRightSideBlocks={pageBarRightSideBlocks}
      title={intl.formatMessage({ id: 'customers.users.browser_tab.title' }, { companyName })}
    >
      <CompanyUsersOverviewComponent
        list={companyUsersList}
        isFetching={isFetching || isSubmittingData}
        handleEdit={handleEdit}
        handleDelete={handleUserDelete}
        handleChangeCompany={handleChangeCompany}
      />

      <TextConfirmationDialogComponent
        open={removeUserDialogState.open}
        onClose={() => setRemoveUserDialogState(initialRemoveUserDialogState)}
        onSubmit={() => handleUserDeleteConfirm(removeUserDialogState.customerId)}
        confirmationText={removeUserDialogState.email}
        confirmationInputLabel={intl.formatMessage({ id: 'customers.users.table.dialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'customers.users.table.dialog.content' }, {
            email: <Box component='strong'>{removeUserDialogState.email}</Box>,
          })
        }
      />

      <FormDialogComponent
        open={reasignDialogState.open}
        onClose={() => setReasignDialogState(initialReasignDialogState)}
        onSubmit={() => handleReassign()}
        disableSubmitButton={!(reasignDialogState.company?.companyId)}
        title={intl.formatMessage({ id: 'customers.users.reassign.title' })}
        description={intl.formatMessage({ id: 'customers.users.reassign.description' }, { email: <IntlFormatBoldComponent>{reasignDialogState.email}</IntlFormatBoldComponent> })}
      >
        <AutocompleteSelectFieldComponent
          name='newCompany'
          options={companiesList}
          label={intl.formatMessage({ id: 'customers.users.reassign.select_company' })}
          placeholder={intl.formatMessage({ id: 'customers.users.reassign.search_company' })}
          noOptionsText={intl.formatMessage({ id: 'customers.users.reassign.search_empty' })}
          handleChangeCallback={(e, selectedValue) => {
            if (selectedValue) {
              setReasignDialogState({
                ...reasignDialogState,
                company: selectedValue,
              })
            }
          }}
          value={reasignDialogState.company}
          getOptionLabel={(option: Customer.CompanyItem) => {
            return option.name
          }}
        />
      </FormDialogComponent>
    </PageLayoutContainer>
  )
}

export default CompanyUsersContainer
