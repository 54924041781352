/* eslint-disable  */

/**
 * Helper function to get environment variables from window._env_ or fallback to process.env
 * @param key - The environment variable key to get
 * @returns The environment variable value
 */
const getEnv = (key: string): string => {
  /**
   * If we are in development mode, we want to use the environment variables from the .env.local file
   */
  if (process.env.NODE_ENV === 'development') {
    return process.env[key] as string
  }

  if (typeof window !== 'undefined' && window._env_ && window._env_[key]) {
    return window._env_[key]
  }

  // Fallback to process.env for SSR or if window._env_ is not available
  // Check if process and process.env exist before accessing them
  if (typeof process !== 'undefined' && process.env && process.env[key]) {
    return process.env[key] as string
  }

  return ''
}

// Declare the _env_ property on the window object for TypeScript
declare global {
  interface Window {
    _env_: Record<string, string> | undefined
  }
}

export const NOTIFICATIONS_URI: string = getEnv('NOTIFICATIONS_URI')
export const FILES_URI: string = getEnv('FILES_URI')
export const HERA_URI: string = getEnv('HERA_URI')
export const HERMES_URI: string = getEnv('HERMES_URI')
export const CUSTOMER_URI: string = getEnv('CUSTOMER_URI')
export const CHAT_URI: string = getEnv('CHAT_URI')
export const USE_CASE_URI: string = getEnv('USE_CASE_URI')
export const PIPELINES_URI: string = getEnv('PIPELINES_URI')
export const SNAPSHOTS_URI: string = getEnv('SNAPSHOTS_URI')
export const KEYCLOAK_URI: string = getEnv('KEYCLOAK_URI')
export const KEYCLOAK_REALM: string = getEnv('KEYCLOAK_REALM')
export const KEYCLOAK_CLIENT_ID: string = getEnv('KEYCLOAK_CLIENT_ID')
export const KEYCLOAK_ADMIN_ROLE = getEnv('KEYCLOAK_ADMIN_ROLE') || 'paretos-admin'
export const COCKPIT_GA_ID: string = getEnv('COCKPIT_GA_ID')
export const COCKPIT_GTM_ID: string = getEnv('COCKPIT_GTM_ID')
export const COCKPIT_BUILD_ENV: string = getEnv('COCKPIT_BUILD_ENV')
export const PARETOS_COMPANY_ID: string = getEnv('PARETOS_COMPANY_ID')
export const MUI_X_LICENSE_KEY: string = getEnv('MUI_X_LICENSE_KEY')
export const COCKPIT_POSTHOG_KEY: string = getEnv('COCKPIT_POSTHOG_KEY')
export const COCKPIT_POSTHOG_HOST: string = getEnv('COCKPIT_POSTHOG_HOST')
export const CHAT_USER_ROLE: string = getEnv('CHAT_USER_ROLE') || 'chat-user'
/* eslint-enable */
