import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
} from 'react'

import { NodeTypes } from '@xyflow/react'

import {
  Box, Typography,
  useTheme,
} from '@mui/material'

import {
  useRouteMatch,
  generatePath,
} from 'react-router-dom'

import {
  GridColDef,
} from '@mui/x-data-grid-premium'

import { DATA_GRIDS } from '@constants/data-grid.constants'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { requestPipelineOverviewAction } from '@redux/modules/pipelines/pipelines.actions'
import { getPipelineOverview, isFetchingPipelines } from '@redux/modules/pipelines/pipelines.selectors'
import useGridInitialState from '@hooks/useGridInitialState.hook'

import useQuery from '@hooks/useQuery.hook'
import GearIcon from '@icons/gear.icon'
import PageLayoutContainer from '@containers/application/PageLayout'
import DataGridComponent from '@base/datagrid/data-grid'
import CallToActionButtonComponent from '@base/pagebar/CallToActionButton'
import ActionButtonComponent from '@base/pagebar/ActionButton'
import DataGridTooltipCellComponent from '@base/datagrid/data-grid-cells/data-grid-tooltip-cell'

import PipelineScheduleConfigurationModalContainer from '@containers/modals/pipeline-schedule-configuration-modal'
import PipelineReportDeliveryModalContainer from '@containers/modals/pipeline-report-delivery-modal'

import PipelineConfigurationModalContainer, {
  PipelineConfigurationModalDetails,
} from '@containers/modals/pipeline-configuration-modal/PipelineConfigurationModal.container'

import { PIPELINES_PATH, USE_CASE_PIPELINES_PATH } from '@constants/routes.constants'
import { getDataGridId } from '@utils/data-grid.utils'

import {
  cellValueGetter,
  generateChipColumnDefinition,
  generateCopyButtonColumnDefinition,
  generateDateTimeColumnDefinition,
  generateSingleSelectColumnDefinition,
} from '@utils/data-grid-cells.utils'

import {
  PIPELINE_DELIVERY_STATUS_OPTIONS, PIPELINE_VIEW_TYPES,
  getChipBgColorByStatus,
} from '@constants/pipelines.constants'

import { NODE_TYPES } from '@constants/flow.constants'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { PIPELINE_CONFIGURATION_MODAL_NAME } from '@constants/modals.constants'

import ReactFlowComponent from '@components/connect-view/flow/ReactFlow'
import ReactFlowPipelineNodeComponent from '@components/connect-view/flow/ReactFlowPipelineNode'
import PipelineConfigComponent from './pipeline-config/PipelineConfig.component'

const nodeTypes = {
  [NODE_TYPES.PIPELINE_ITEM]: ReactFlowPipelineNodeComponent,
} as NodeTypes

const PipelinesContainer = () => {
  const intl = useIntl()
  const theme = useTheme()
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.PipelinesRouterMatch>()
  const query = useQuery()

  const useCaseId = match.params.usecase
  const pipelineId = match.params.pipeline
  const previousView = query.get('previousView') as PIPELINE_VIEW_TYPES

  const isAdmin = useSelector(getIsAdmin)
  const isFetching = useSelector(isFetchingPipelines)
  const pipelineOverview = useSelector(getPipelineOverview)
  const pipelineDetails = pipelineOverview?.pipelineDetails
  const pipelineName = pipelineDetails?.name
  const pipelineConfig = pipelineOverview?.pipelineConfig
  const pipelineGraph = pipelineOverview?.pipelineGraph
  const tableId = getDataGridId(DATA_GRIDS.PIPELINE_OVERVIEW, 1)

  const handleEdit = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: PIPELINE_CONFIGURATION_MODAL_NAME,
        modalDetails: {
          returnTo: '',
          pipeline: pipelineDetails,
        } as PipelineConfigurationModalDetails,
      }),
    )
  }

  useLayoutEffect(() => {
    dispatch(
      requestPipelineOverviewAction({
        useCaseId,
        pipelineId,
      }),
    )
  }, [dispatch, useCaseId, pipelineId])

  const onRefreshButtonClick = () => {
    dispatch(
      requestPipelineOverviewAction({
        useCaseId,
        pipelineId,
      }),
    )
  }

  const pageBarRightSideBlocks = (
    <>
      <ActionButtonComponent
        name='editPipelineButton'
        onClick={handleEdit}
        IconComponent={GearIcon}
        label={intl.formatMessage({ id: 'common.tables.actions.configure' })}
      />

      <CallToActionButtonComponent
        name='refreshPipelinesButton'
        onClick={onRefreshButtonClick}
        loading={isFetching}
        label={intl.formatMessage({ id: 'common.refresh' })}
      />
    </>
  )

  const columns = useMemo<GridColDef[]>(() => [
    generateCopyButtonColumnDefinition({
      field: 'expectedDeliveryId',
      headerName: intl.formatMessage({ id: 'pipeline_overview.list.expectedDeliveryId' }),
    }),
    generateChipColumnDefinition({
      intl,
      field: 'delivery',
      headerName: intl.formatMessage({ id: 'pipeline_overview.list.delivery' }),
    }),
    generateSingleSelectColumnDefinition({
      field: 'status',
      headerName: intl.formatMessage({ id: 'pipeline_overview.list.pipelineStatus' }),
      valueOptions: PIPELINE_DELIVERY_STATUS_OPTIONS,
      naLabel: intl.formatMessage({ id: 'pipelines.na' }),
      getBgColorByValue: getChipBgColorByStatus,
    }),
    generateChipColumnDefinition({
      intl,
      field: 'deliveryScheduleSnapshot',
      headerName: intl.formatMessage({ id: 'pipeline_overview.list.deliveryScheduleSnapshot' }),
      getTooltip: (params) => intl.formatMessage({ id: 'pipelines.table.cronText' }, { cron: params.row.deliveryScheduleSnapshotDate }),
    }),
    generateDateTimeColumnDefinition({
      intl,
      field: 'deliveryScheduleSnapshotFullDate',
      headerName: intl.formatMessage({ id: 'pipeline_overview.list.deliveryScheduleSnapshotDate' }),
      gridColDefOverrides: {
        renderCell: (params) => {
          return cellValueGetter({
            params,
            customCell: (
              <DataGridTooltipCellComponent
                label={params.formattedValue || intl.formatMessage({ id: 'pipelines.na' })}
                tooltip={intl.formatMessage({ id: 'pipelines.table.cronTimezoneTemporal' })}
              />
            ),
          })
        },
      },
    }),
    generateDateTimeColumnDefinition({
      intl,
      field: 'createdAt',
      headerName: intl.formatMessage({ id: 'pipeline_overview.list.createdAt' }),
    }),
    generateDateTimeColumnDefinition({
      intl,
      field: 'updatedAt',
      headerName: intl.formatMessage({ id: 'pipeline_overview.list.updatedAt' }),
    }),
  ], [
    intl,
  ])

  const initialState = useGridInitialState(tableId, {
    sorting: {
      sortModel: [{ field: 'delivery', sort: 'desc' }],
    },
  })

  const getRowId = useCallback((row: Pipelines.ReduxExpectedDeliveryHistoryItem) => row.expectedDeliveryId, [])

  const backToPath = previousView === PIPELINE_VIEW_TYPES.USECASE ?
    generatePath(USE_CASE_PIPELINES_PATH, { usecase: useCaseId }) :
    PIPELINES_PATH

  if (!isAdmin) {
    return null
  }

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'pipeline_overview.browser_tab.title' }, { name: pipelineName })}
      pageBarRightSideBlocks={pageBarRightSideBlocks}
      backToPath={backToPath}
      customTitle={
        intl.formatMessage({ id: 'common.layout.drill_down.title' }, {
          name: (
            <Box component='span' fontWeight={300}>
              {
                isFetching ? (
                  intl.formatMessage({ id: 'common.loading' })
                ) : pipelineName
              }
            </Box>
          ),
        })
      }
    >

      {
        pipelineGraph && !isFetching ? (
          <Box>
            <Typography
              variant='h5'
              mb={2}
              mt={2}
            >
              {intl.formatMessage({ id: 'pipeline_overview.pipelineGraph' })}
            </Typography>

            <ReactFlowComponent<Pipelines.PipelineGraphNodeData>
              edges={pipelineGraph.edges}
              nodes={pipelineGraph.nodes}
              nodeTypes={nodeTypes}
              fitViewOptions={{
                maxZoom: 1,
              }}
              sx={{
                borderRadius: '10px',
                border: `1px solid ${theme.palette.new.grey_a}`,
                height: '500px',
                position: 'relative',
              }}
            />
          </Box>
        ) : (
          null
        )
      }

      <PipelineConfigComponent
        pipelineConfig={pipelineConfig}
        isFetching={isFetching}
        sx={{ my: 2 }}
      />

      <Typography
        variant='h5'
        my={2}
      >
        {intl.formatMessage({ id: 'pipeline_overview.deliveryHistory' })}
      </Typography>

      <DataGridComponent
        id={tableId}
        stateResetKey={useCaseId}
        name={DATA_GRIDS.PIPELINE_OVERVIEW}
        columns={columns}
        autoHeight={true}
        autosizeOnMount={true}
        disableVirtualization={true}
        rows={pipelineOverview.expectedDeliveryHistory}
        getRowId={getRowId}
        initialState={initialState}
        loading={isFetching}
      />

      <PipelineConfigurationModalContainer />

      <PipelineScheduleConfigurationModalContainer />

      <PipelineReportDeliveryModalContainer />
    </PageLayoutContainer>
  )
}

export default PipelinesContainer
