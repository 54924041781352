export const INCREMENT_UNRESOLVED_REQUESTS = 'INCREMENT_UNRESOLVED_REQUESTS'
export const INCREMENT_UNRESOLVED_REQUESTS_DONE = 'INCREMENT_UNRESOLVED_REQUESTS_DONE'

export const DECREMENT_UNRESOLVED_REQUESTS = 'DECREMENT_UNRESOLVED_REQUESTS'
export const DECREMENT_UNRESOLVED_REQUESTS_DONE = 'DECREMENT_UNRESOLVED_REQUESTS_DONE'

export const CHANGE_SIDEBAR_STATE = 'CHANGE_SIDEBAR_STATE'
export const CHANGE_SIDEBAR_STATE_DONE = 'CHANGE_SIDEBAR_STATE_DONE'

export const CHANGE_CHATBOT_STATE = 'CHANGE_CHATBOT_STATE'
export const CHANGE_CHATBOT_STATE_DONE = 'CHANGE_CHATBOT_STATE_DONE'

export const CHANGE_TOAST = 'CHANGE_TOAST'
export const CHANGE_TOAST_DONE = 'CHANGE_TOAST_DONE'

export const START_FETCHING_COMMON = 'START_FETCHING_COMMON'
export const STOP_FETCHING_COMMON = 'STOP_FETCHING_COMMON'
