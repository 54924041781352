import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'

import TabBarComponent from '@base/pagebar/TabBar'
import ButtonComponent from '@base/buttons/Button'
import AutocompleteSelectFieldComponent from '@base/autocomplete/AutocompleteSelectField/AutocompleteSelectField.component'
import { TRACKING_MODULES } from '@utils/tracking.utils'

interface NotificationsHeaderComponentProps {
  tabs: Common.TabOption[]
  useCaseOptions: Array<{ value: string; label: string }>
  useCaseFilter: { value: string; label: string }
  unreadCountOfList: number
  onTabChange: (selection: Common.TabOption) => void
  onUseCaseChange: (e: React.SyntheticEvent, selectedValue: { value: string; label: string }) => void
  handleMarkAllAsRead: () => void
}

const NotificationsHeaderComponent: React.FC<NotificationsHeaderComponentProps> = ({
  tabs,
  useCaseOptions,
  useCaseFilter,
  unreadCountOfList,
  onTabChange,
  onUseCaseChange,
  handleMarkAllAsRead,
}) => {
  const intl = useIntl()

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
      data-testid={NotificationsHeaderComponent.name}
    >
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        gap={2}
        width='100%'
      >
        <TabBarComponent
          options={tabs}
          onTabChange={onTabChange}
          trackingProps={{
            componentName: 'notificationsTabBar',
            moduleName: TRACKING_MODULES.TOP_BAR,
          }}
        />

        <AutocompleteSelectFieldComponent
          options={useCaseOptions}
          handleChangeCallback={onUseCaseChange}
          multiple={false}
          clearIcon={false}
          value={useCaseFilter}
          name='notificationsOverviewUseCaseDropdown'
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          label=''
          hideLabel={true}
          freeSolo={false}
          placeholder={intl.formatMessage({ id: 'notifications.modal.overview.allUseCases' })}
          containerSx={{
            width: '100%',
            maxWidth: '200px',
          }}
        />
      </Box>

      <ButtonComponent
        name='notificationsOverviewMarkAllAsReadButton'
        label={intl.formatMessage({ id: 'notifications.modal.mark_all_as_read' })}
        onClick={handleMarkAllAsRead}
        disabled={unreadCountOfList === 0}
        color='secondary'
      />
    </Box>
  )
}

export default NotificationsHeaderComponent
