import {
  styled, Box,
} from '@mui/material'

import { DEFAULT_BORDER_RADIUS, DEFAULT_SELECT_BOX_SHADOW } from '@constants/ui.constants'

const ChartTooltipContainerComponent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column' as any,
  border: 'none',
  backgroundColor: theme.palette.new.white,
  color: theme.palette.new.grey_c,
  opacity: 1,
  boxShadow: DEFAULT_SELECT_BOX_SHADOW,
  borderRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
  fontVariantNumeric: 'tabular-nums',
  position: 'relative' as const,
  overflow: 'hidden',
  fontFamily: 'AeonikPro, Roboto, Helvetica, Arial, sans-serif',
  outline: `1px solid ${theme.palette.new.business_black_20}`,
}))

export default ChartTooltipContainerComponent
