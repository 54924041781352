import produce from 'immer'
import moment from 'moment'

import { ReducerPayload, MonitorState } from '@redux/modules/types'
import { MIN_PREDICTION_HORIZON, TIME_RESOLUTION } from '@constants/date.constants'
import { getDataGridColumnsFromHeraColumns, getTargetNameFromColumnDefinitions, processChartData } from '@utils/insights.utils'

import {
  DEFAULT_BACKTEST_TARGET_COLUMN,
  DEFAULT_BACKTEST_PREDICTION_COLUMN,
  DEFAULT_LIVE_MONITORING_TARGET_COLUMN,
  DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN,
  DEFAULT_REL_DEVIATION_COLUMN,
  DEFAULT_ABS_DEVIATION_COLUMN,
  DEFAULT_PARETOS_REL_DEVIATION_COLUMN,
  DEFAULT_PARETOS_ABS_DEVIATION_COLUMN,
  DEFAULT_BACKTEST_TARGET_FIELD,
  DEFAULT_BACKTEST_PREDICTION_FIELD,
  DEFAULT_LIVE_MONITORING_TARGET_FIELD,
  DEFAULT_LIVE_MONITORING_PREDICTION_FIELD,
  DEFAULT_BACKTEST_PREDICTION_FIELD_PREFIX,
  DEFAULT_LIVE_MONITORING_PREDICTION_FIELD_PREFIX,

  DEFAULT_BASELINE_COMPARISON_TARGET_COLUMN,
  DEFAULT_BASELINE_COMPARISON_PREDICTION_COLUMN,
  DEFAULT_BASELINE_COMPARISON_PREDICTION_FIELD_PREFIX,
  DEFAULT_BASELINE_COMPARISON_TARGET_FIELD,
  DEFAULT_BASELINE_COMPARISON_PREDICTION_FIELD,
  DEFAULT_BASELINE_COMPARISON_BASELINE_FIELD,
  DEFAULT_BASELINE_COMPARISON_BASELINE_COLUMN,
  DEFAULT_BASELINE_REL_DEVIATION_COLUMN,
  DEFAULT_BASELINE_ABS_DEVIATION_COLUMN,
} from '@constants/insights.constants'

const defaultAbcTotals = {
  a: 0,
  b: 0,
  c: 0,
  total: 0,
}

const defaultBacktestingOverallNumbers = {
  absDeviation: 0,
  relDeviation: 0,
  target: 0,
  backtestPrediction: 0,
} as Monitor.BacktestingOverallNumbers

const defaultLiveMonitoringOverallNumbers = {
  absDeviation: 0,
  relDeviation: 0,
  target: 0,
  prediction: 0,
} as Monitor.LiveMonitoringOverallNumbers

const defaultBaselineComparisonOverallNumbers = {
  target: 0,
  baselineAbsDeviation: 0,
  baselinePrediction: 0,
  baselineRelDeviation: 0,
  paretosAbsDeviation: 0,
  paretosPrediction: 0,
  paretosRelDeviation: 0,
} as Monitor.BaselineComparisonOverallNumbers

export const defaultTableState = {
  tableId: '',
  useCaseId: '',
  targetName: '',
  columns: [],
  rows: [],
  rowCount: 0,
  gridState: undefined,
} as Monitor.BacktestingTable

export const defaultBacktestingChartState = {
  targetName: '',
  targetUnit: '',
  dataset: [],
  lines: [],
  events: [],
  overallNumbers: defaultBacktestingOverallNumbers,
  abcClassification: defaultAbcTotals,
  timeResolution: TIME_RESOLUTION.DAILY,
} as Monitor.BacktestingChart

export const defaultLiveMonitoringChartState = {
  targetName: '',
  targetUnit: '',
  dataset: [],
  lines: [],
  events: [],
  overallNumbers: defaultLiveMonitoringOverallNumbers,
  abcClassification: defaultAbcTotals,
  timeResolution: TIME_RESOLUTION.DAILY,
} as Monitor.LiveMonitoringChart

export const defaultBaselineComparisonChartState = {
  targetName: '',
  targetUnit: '',
  dataset: [],
  lines: [],
  events: [],
  overallNumbers: defaultBaselineComparisonOverallNumbers,
  abcClassification: defaultAbcTotals,
  timeResolution: TIME_RESOLUTION.DAILY,
  timeSteps: MIN_PREDICTION_HORIZON,
} as Monitor.BaselineComparisonChart

export const initialState: MonitorState = {
  fetchingKeys: [],

  backtestingTable: defaultTableState,
  backtestingChart: defaultBacktestingChartState,
  backtestingAvailableFolds: [],

  liveMonitoringTable: defaultTableState,
  liveMonitoringChart: defaultLiveMonitoringChartState,
  liveMonitoringAvailableForecastHorizons: [],

  baselineComparisonTable: defaultTableState,
  baselineComparisonChart: defaultBaselineComparisonChartState,
  baselineComparisonAvailableForecastHorizons: [],
  baselineComparisonNoInsightsAvailable: false,
}

export const receiveBacktestingFoldsOptions = (state: MonitorState, action: ReducerPayload<Hera.ListBacktestFoldsApiResponse>) => {
  const nextState = produce(state, (draftState) => {
    const offsets = action.payload.offsets || []
    const options = offsets.map((offset, index) => {
      return {
        offset,
        offsetFromToday: action.payload.offsetsFromToday[index],
        date: moment.utc(action.payload.cutoffDates[index], 'YYYY-MM-DD'),
        timeResolution: action.payload.timeResolution,
      }
    }).sort((a, b) => {
      return a.offset > b.offset ? 1 : -1
    })

    draftState.backtestingAvailableFolds = options
  })

  return nextState
}

export const receiveBacktestingTable = (state: MonitorState, action: ReducerPayload<{
  useCaseId: string
  tableId?: string
  initialization?: boolean
  response?: Monitor.BacktestingTablePaginatedAPIResponse
  gridInitialState?: Monitor.BacktestingGridState
}>) => {
  const nextState = produce(state, (draftState) => {
    if (!action.payload.response || !action.payload.tableId) {
      draftState.backtestingTable = defaultTableState

      return
    }

    const {
      tableId,
      gridInitialState,
      initialization,
      response,
      useCaseId,
    } = action.payload

    const {
      data,
      metaData,
    } = response

    const {
      columns,
      totalCount,
    } = metaData

    if (initialization) {
      const targetName = getTargetNameFromColumnDefinitions(columns, DEFAULT_BACKTEST_TARGET_COLUMN)
      const tableColumns = getDataGridColumnsFromHeraColumns({
        columns,
        specialColumnNames: [
          DEFAULT_BACKTEST_TARGET_COLUMN, DEFAULT_BACKTEST_PREDICTION_COLUMN,
          DEFAULT_REL_DEVIATION_COLUMN, DEFAULT_ABS_DEVIATION_COLUMN,
        ],
        disableAggregation: true,
      })

      draftState.backtestingTable = {
        tableId,
        useCaseId,
        targetName,
        columns: tableColumns,
        rows: data,
        rowCount: totalCount,
        gridState: gridInitialState,
      }
    } else {
      draftState.backtestingTable = {
        ...draftState.backtestingTable,
        rows: data,
        rowCount: totalCount,
      }
    }
  })

  return nextState
}

export const receiveBacktestingChart = (state: MonitorState, action: ReducerPayload<{
  response: Monitor.BacktestingChartAPIResponse,
  timeResolution: TIME_RESOLUTION
}>) => {
  const nextState = produce(state, (draftState) => {
    if (!action.payload.response || !action.payload.response.data || !action.payload.response.metaData || !draftState.backtestingTable.gridState) {
      draftState.backtestingChart = defaultBacktestingChartState

      return
    }

    const {
      data,
      overallNumbers,
      metaData: {
        targetName,
        targetUnit,
        legend,
        abcClassification,
      },
    } = action.payload.response

    const timeResolution = action.payload.timeResolution
    const { lines, dataset, events } = processChartData<Monitor.BacktestingGridState>({
      gridState: draftState.backtestingTable.gridState,
      dataPoints: data,
      previousYearsDataPoints: [],
      baseLegend: [DEFAULT_BACKTEST_TARGET_FIELD, DEFAULT_BACKTEST_PREDICTION_FIELD],
      targetPayloadKey: DEFAULT_BACKTEST_TARGET_FIELD,
      predictionPayloadKey: DEFAULT_BACKTEST_PREDICTION_FIELD,
      predictionGroupingPayloadPrefix: DEFAULT_BACKTEST_PREDICTION_FIELD_PREFIX,
      groupingLegend: legend,
      timeResolution,
      shouldEnrichWithVirtualTarget: false,
    })

    draftState.backtestingChart = {
      targetName,
      targetUnit,
      overallNumbers,
      lines,
      dataset,
      events,
      abcClassification,
      timeResolution,
    }
  })

  return nextState
}

export const receiveBacktestingGridStateChange = (state: MonitorState, action: ReducerPayload<Monitor.BacktestingGridState>) => {
  const nextState = produce(state, (draftState) => {
    draftState.backtestingTable.gridState = {
      ...draftState.backtestingTable.gridState,
      ...action.payload,
    }
  })

  return nextState
}

export const receiveLiveMonitoringTable = (state: MonitorState, action: ReducerPayload<{
  useCaseId: string
  tableId?: string
  initialization?: boolean
  response?: Monitor.BacktestingTablePaginatedAPIResponse
  gridInitialState?: Monitor.LiveMonitoringGridState
}>) => {
  const nextState = produce(state, (draftState) => {
    if (!action.payload.response || !action.payload.tableId) {
      draftState.liveMonitoringTable = defaultTableState

      return
    }

    const {
      tableId,
      gridInitialState,
      initialization,
      response,
      useCaseId,
    } = action.payload

    const {
      data,
      metaData,
    } = response

    const {
      columns,
      totalCount,
    } = metaData

    if (initialization) {
      const targetName = getTargetNameFromColumnDefinitions(columns, DEFAULT_LIVE_MONITORING_TARGET_COLUMN)
      const tableColumns = getDataGridColumnsFromHeraColumns({
        columns,
        specialColumnNames: [
          DEFAULT_LIVE_MONITORING_TARGET_COLUMN, DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN,
          DEFAULT_REL_DEVIATION_COLUMN, DEFAULT_ABS_DEVIATION_COLUMN,
        ],
        disableAggregation: true,
      })

      draftState.liveMonitoringTable = {
        tableId,
        useCaseId,
        targetName,
        columns: tableColumns,
        rows: data,
        rowCount: totalCount,
        gridState: gridInitialState,
      }
    } else {
      draftState.liveMonitoringTable = {
        ...draftState.liveMonitoringTable,
        rows: data,
        rowCount: totalCount,
      }
    }
  })

  return nextState
}

export const receiveLiveMonitoringChart = (state: MonitorState, action: ReducerPayload<{
  response: Monitor.LiveMonitoringChartAPIResponse
  lastYearResponse?: Monitor.LiveMonitoringChartAPIResponse
  timeResolution: TIME_RESOLUTION
}>) => {
  const nextState = produce(state, (draftState) => {
    if (!action.payload.response || !action.payload.response.data || !action.payload.response.metaData || !draftState.liveMonitoringTable.gridState) {
      draftState.liveMonitoringChart = defaultLiveMonitoringChartState

      return
    }

    const {
      data,
      overallNumbers,
      metaData: {
        targetName,
        targetUnit,
        legend,
        abcClassification,
      },
    } = action.payload.response

    const timeResolution = action.payload.timeResolution
    const { lines, dataset, events } = processChartData<Monitor.LiveMonitoringGridState>({
      gridState: draftState.liveMonitoringTable.gridState,
      dataPoints: data,
      previousYearsDataPoints: [],
      baseLegend: [DEFAULT_LIVE_MONITORING_TARGET_FIELD, DEFAULT_LIVE_MONITORING_PREDICTION_FIELD],
      targetPayloadKey: DEFAULT_LIVE_MONITORING_TARGET_FIELD,
      predictionPayloadKey: DEFAULT_LIVE_MONITORING_PREDICTION_FIELD,
      predictionGroupingPayloadPrefix: DEFAULT_LIVE_MONITORING_PREDICTION_FIELD_PREFIX,
      groupingLegend: legend,
      timeResolution,
      shouldEnrichWithVirtualTarget: false,
    })

    draftState.liveMonitoringChart = {
      targetName,
      targetUnit,
      overallNumbers,
      lines,
      dataset,
      events,
      abcClassification,
      timeResolution,
    }
  })

  return nextState
}

export const receiveLiveMonitoringGridStateChange = (state: MonitorState, action: ReducerPayload<Monitor.LiveMonitoringGridState>) => {
  const nextState = produce(state, (draftState) => {
    draftState.liveMonitoringTable.gridState = {
      ...draftState.liveMonitoringTable.gridState,
      ...action.payload,
    }
  })

  return nextState
}

export const receiveLiveMonitoringForecastHorizonsOptions = (state: MonitorState, action: ReducerPayload<Hera.ListForecastHorizonsApiResponse>) => {
  const nextState = produce(state, (draftState) => {
    const offsets = action.payload.offsets || []
    const options = offsets.map((offset, index) => {
      return {
        offset,
        offsetFromToday: action.payload.offsetsFromToday[index],
        date: moment.utc(action.payload.dateOfPredictionDates[index], 'YYYY-MM-DD'),
        timeResolution: action.payload.timeResolution,
      }
    }).sort((a, b) => {
      return a.offset > b.offset ? 1 : -1
    })

    draftState.liveMonitoringAvailableForecastHorizons = options
  })

  return nextState
}

export const receiveBaselineComparisonTable = (state: MonitorState, action: ReducerPayload<{
  useCaseId: string
  tableId?: string
  initialization?: boolean
  response?: Monitor.BacktestingTablePaginatedAPIResponse
  gridInitialState?: Monitor.BaselineComparisonGridState
  noInsightsAvailable?: boolean
}>) => {
  const nextState = produce(state, (draftState) => {
    if (!action.payload.response || !action.payload.tableId) {
      draftState.baselineComparisonTable = defaultTableState
      draftState.baselineComparisonNoInsightsAvailable = action.payload.noInsightsAvailable

      return
    }

    const {
      tableId,
      gridInitialState,
      initialization,
      response,
      useCaseId,
    } = action.payload

    const {
      data,
      metaData,
    } = response

    const {
      columns,
      totalCount,
    } = metaData

    if (initialization) {
      const targetName = getTargetNameFromColumnDefinitions(columns, DEFAULT_BASELINE_COMPARISON_TARGET_COLUMN)
      const tableColumns = getDataGridColumnsFromHeraColumns({
        columns,
        specialColumnNames: [
          DEFAULT_BASELINE_COMPARISON_TARGET_COLUMN,
          DEFAULT_BASELINE_COMPARISON_BASELINE_COLUMN,
          DEFAULT_BASELINE_COMPARISON_PREDICTION_COLUMN,

          DEFAULT_BASELINE_REL_DEVIATION_COLUMN,
          DEFAULT_BASELINE_ABS_DEVIATION_COLUMN,
          DEFAULT_PARETOS_REL_DEVIATION_COLUMN,
          DEFAULT_PARETOS_ABS_DEVIATION_COLUMN,
        ],
        disableAggregation: true,
      })

      draftState.baselineComparisonTable = {
        tableId,
        useCaseId,
        targetName,
        columns: tableColumns,
        rows: data,
        rowCount: totalCount,
        gridState: gridInitialState,
      }
    } else {
      draftState.baselineComparisonTable = {
        ...draftState.baselineComparisonTable,
        rows: data,
        rowCount: totalCount,
      }
    }
  })

  return nextState
}

export const receiveBaselineComparisonChart = (state: MonitorState, action: ReducerPayload<{
  noInsightsAvailable?: boolean
  response: Monitor.BaselineComparisonChartAPIResponse
  timeResolution: TIME_RESOLUTION
}>) => {
  const nextState = produce(state, (draftState) => {
    if (!action.payload.response || !action.payload.response.data || !action.payload.response.metaData || !draftState.baselineComparisonTable.gridState) {
      draftState.baselineComparisonChart = defaultBaselineComparisonChartState
      draftState.baselineComparisonNoInsightsAvailable = action.payload.noInsightsAvailable

      return
    }

    const {
      data,
      overallNumbers,
      metaData: {
        targetName,
        targetUnit,
        legend,
        abcClassification,
        timeSteps,
      },
    } = action.payload.response

    const timeResolution = action.payload.timeResolution
    const { lines, dataset, events } = processChartData<Monitor.BaselineComparisonGridState>({
      gridState: draftState.baselineComparisonTable.gridState,
      dataPoints: data,
      previousYearsDataPoints: [],
      baseLegend: [DEFAULT_BASELINE_COMPARISON_TARGET_FIELD, DEFAULT_BASELINE_COMPARISON_PREDICTION_FIELD, DEFAULT_BASELINE_COMPARISON_BASELINE_FIELD],
      targetPayloadKey: DEFAULT_BASELINE_COMPARISON_TARGET_FIELD,
      predictionPayloadKey: DEFAULT_BASELINE_COMPARISON_PREDICTION_FIELD,
      predictionGroupingPayloadPrefix: DEFAULT_BASELINE_COMPARISON_PREDICTION_FIELD_PREFIX,
      groupingLegend: legend,
      timeResolution,
      shouldEnrichWithVirtualTarget: false,
    })

    draftState.baselineComparisonChart = {
      timeSteps,
      targetName,
      targetUnit,
      overallNumbers,
      lines,
      dataset,
      events,
      abcClassification,
      timeResolution,
    }
  })

  return nextState
}

export const receiveBaselineComparisonGridStateChange = (state: MonitorState, action: ReducerPayload<Monitor.BaselineComparisonGridState>) => {
  const nextState = produce(state, (draftState) => {
    draftState.baselineComparisonTable.gridState = {
      ...draftState.baselineComparisonTable.gridState,
      ...action.payload,
    }
  })

  return nextState
}

export const receiveBaselineComparisonForecastHorizonsOptions = (state: MonitorState, action: ReducerPayload<Hera.ListForecastHorizonsApiResponse>) => {
  const nextState = produce(state, (draftState) => {
    const offsets = action.payload.offsets || []
    const options = offsets.map((offset, index) => {
      return {
        offset,
        offsetFromToday: action.payload.offsetsFromToday[index],
        date: moment.utc(action.payload.dateOfPredictionDates[index], 'YYYY-MM-DD'),
        timeResolution: action.payload.timeResolution,
      }
    }).sort((a, b) => {
      return a.offset > b.offset ? 1 : -1
    })

    draftState.baselineComparisonAvailableForecastHorizons = options
  })

  return nextState
}
