import Paper from '@mui/material/Paper'
import {
  GridColDef,
  GridLogicOperator,
  gridClasses,
  useGridApiRef,
} from '@mui/x-data-grid-premium'

import { DATA_GRIDS } from '@constants/data-grid.constants'
import { Box, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { getFilePreviewDelimiterOptions, getFilePreviewPreviewModeOptions } from '@utils/files.utils'

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import DataGridComponent from '@base/datagrid/data-grid'
import useGridInitialState from '@hooks/useGridInitialState.hook'
import DropdownMenuComponent from '@base/dropdowns/DropdownMenu/DropdownMenu.component'

import {
  CSV_PREVIEW_COLUMNS_AUTOSIZE_TIMEOUT_IN_MS,
  FILE_BROWSER_CSV_EMPTY_COLUMN_NAME_PREFIX,
  FILE_BROWSER_PREVIEW_OPTIONS,
} from '@constants/files.constants'

export interface FileBrowserPreviewTableComponentProps {
  /**
   * Columns definitions
   */
  columns: GridColDef[]
  /**
   * Data to display
   */
  data: any[]
  /**
   * The preview mode to use
   */
  previewMode: string
  /**
   * The delimiter to use
   */
  delimiter?: string
  /**
   * The function to set the delimiter
   */
  setDelimiter: (delimiter: string) => void
  /**
   * The function to set the preview mode
   */
  setPreviewMode: (previewMode: FILE_BROWSER_PREVIEW_OPTIONS) => void
  /**
   * The auto detected delimiter
   */
  autoDetectedDelimiter?: string
  /**
   * The parsing csv columns
   */
  parsingCsvColumns?: boolean
}

const FileBrowserPreviewTableComponent: React.FC<FileBrowserPreviewTableComponentProps> = ({
  columns,
  data,
  previewMode = FILE_BROWSER_PREVIEW_OPTIONS.RAW_DATA,
  delimiter = 'auto',
  autoDetectedDelimiter,
  setDelimiter,
  setPreviewMode,
  parsingCsvColumns,
}) => {
  const theme = useTheme()
  const intl = useIntl()
  const delimiterOptions = getFilePreviewDelimiterOptions(intl, autoDetectedDelimiter)
  const previewModeOptions = getFilePreviewPreviewModeOptions(intl)

  const [containerMaxHeight, setContainerMaxHeight] = useState(0)
  const gridApiRef = useGridApiRef()
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const initialState = useGridInitialState(DATA_GRIDS.FILE_BROWSER_PREVIEW_TABLE, {
    pagination: {
      paginationModel: {
        pageSize: 50,
      },
    },
  })

  const sx = {
    [`& .${gridClasses.toolbarContainer}`]: {
      borderTop: 'none',
    },
    [`& .${gridClasses.main}`]: {
      borderRadius: '0px',
    },
    [`& .${gridClasses.columnHeader}[data-field^="${FILE_BROWSER_CSV_EMPTY_COLUMN_NAME_PREFIX}"]`]: {
      backgroundColor: theme.palette.new.rebellious_red_10,
      color: theme.palette.new.rebellious_red,
    },
  }

  const handleResize = useCallback(() => {
    if (!tableContainerRef || !tableContainerRef.current) {
      return
    }

    setContainerMaxHeight((tableContainerRef.current.parentElement?.clientHeight || 0))
  }, [tableContainerRef])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    if (!tableContainerRef || !tableContainerRef.current) {
      return
    }

    handleResize()
  }, [handleResize])

  useEffect(() => {
    if (gridApiRef && gridApiRef.current) {
      gridApiRef.current.setFilterModel({
        items: [],
        logicOperator: GridLogicOperator.And,
        quickFilterValues: [],
        quickFilterLogicOperator: GridLogicOperator.And,
        quickFilterExcludeHiddenColumns: true,
      })

      gridApiRef.current.setRowGroupingModel([])

      gridApiRef.current.setAggregationModel({})

      gridApiRef.current.setSortModel([])
    }
  }, [previewMode, gridApiRef])

  const customToolbarChildren = useMemo(() => {
    return (
      <Box
        display='flex'
        alignItems='center'
        gap={1}
      >
        <DropdownMenuComponent
          name='delimiter'
          onChange={(value) => setDelimiter(value as string)}
          value={delimiter}
          multiple={false}
          options={delimiterOptions}
          label={intl.formatMessage({ id: 'fileManager.preview.dialog.delimiter' })}
          fitMenuToButton={false}
          fitButtonToMenu={true}
        />

        <DropdownMenuComponent
          name='dynamicTyping'
          onChange={(value) => {
            setPreviewMode(value as FILE_BROWSER_PREVIEW_OPTIONS)
          }}
          value={previewMode}
          multiple={false}
          options={previewModeOptions}
          label={intl.formatMessage({ id: 'fileManager.preview.dialog.previewMode' })}
          fitMenuToButton={false}
          fitButtonToMenu={true}
        />
      </Box>
    )
  }, [
    delimiter,
    previewMode,
    setDelimiter,
    setPreviewMode,
    delimiterOptions,
    previewModeOptions,
    intl,
  ])

  const gridSlotConfig = useMemo(() => ({
    toolbar: {
      withExport: false,
      customToolbarChildren,
    },
    pagination: {
      nextIconButtonProps: {
        enableGoToLastPage: true,
      },
      backIconButtonProps: {
        enableGoToFirstPage: true,
      },
    } as any,
  }), [customToolbarChildren])

  useEffect(() => {
    setTimeout(() => {
      if (gridApiRef && gridApiRef.current) {
        gridApiRef.current.autosizeColumns({
          includeHeaders: true,
          includeOutliers: true,
        })
      }
    }, CSV_PREVIEW_COLUMNS_AUTOSIZE_TIMEOUT_IN_MS)
  }, [previewMode, gridApiRef])

  return (
    <Paper
      data-testid={FileBrowserPreviewTableComponent.name}
      sx={{ width: '100%', overflow: 'hidden' }}
      ref={tableContainerRef}
      elevation={0}
    >
      <DataGridComponent
        id={DATA_GRIDS.FILE_BROWSER_PREVIEW_TABLE}
        name={DATA_GRIDS.FILE_BROWSER_PREVIEW_TABLE}
        columns={columns}
        rows={data}
        height={`${containerMaxHeight}px`}
        rounded={false}
        loading={parsingCsvColumns}
        enablePersistence={false}
        disableAggregation={previewMode === FILE_BROWSER_PREVIEW_OPTIONS.RAW_DATA}
        disableRowGrouping={false}
        slotProps={gridSlotConfig}
        initialState={initialState}
        apiRef={gridApiRef}
        headerFilters={(previewMode !== FILE_BROWSER_PREVIEW_OPTIONS.RAW_DATA) && !parsingCsvColumns}
        sx={sx}
      />
    </Paper>
  )
}

export default FileBrowserPreviewTableComponent
