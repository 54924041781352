import React from 'react'
import { Position } from '@xyflow/react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { createId } from '@utils/common.utils'
import { Box, useTheme } from '@mui/material'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { CONNECT_EVALUATION_PROFILE_MODAL_NAME } from '@constants/modals.constants'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { EVALUATION_PROFILE_PER_ROW, INPUT_TYPES } from '@constants/flow.constants'
import { getEvaluationProfileGroupWidth, getEvaluationProfileGroupHeight } from '@utils/flow.utils'

import ReactFlowAddNewButtonComponent from '@components/connect-view/flow/ReactFlowAddNewButton'
import ReactFlowBlockHeaderComponent from '@components/connect-view/flow/ReactFlowBlockHeader'
import ReactFlowEvaluationProfileBlockComponent from '@components/connect-view/flow/ReactFlowEvaluationProfileBlock'
import ReactFlowHandleComponent from '@components/connect-view/flow/ReactFlowHandle'

export interface ReactFlowEvaluationProfileGroupNodeComponentProps {}

const ReactFlowEvaluationProfileGroupNodeComponent: React.FC<ReactFlowEvaluationProfileGroupNodeComponentProps> = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const theme = useTheme()

  const useCase = useSelector(getUseCaseItem)
  const evaluationProfiles = (useCase.evaluationProfiles || [])
  const numOfNodes = evaluationProfiles.length

  const evaluationProfilesChunks = []
  const isAddButtonAvailable = Boolean(numOfNodes)

  for (let i = 0; i < evaluationProfiles.length; i += EVALUATION_PROFILE_PER_ROW) {
    const chunk = evaluationProfiles.slice(i, i + EVALUATION_PROFILE_PER_ROW)
    evaluationProfilesChunks.push(chunk)
  }

  const onAddButtonClick = () => {
    dispatch(
      setPrimaryModalPageName(CONNECT_EVALUATION_PROFILE_MODAL_NAME),
    )
  }

  const onParameterClick = (parameterItem: UseCase.EvaluationProfile) => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: CONNECT_EVALUATION_PROFILE_MODAL_NAME,
        modalDetails: {
          returnTo: '',
          parameterItem,
        },
      }),
    )
  }

  return (
    <Box
      data-testid={ReactFlowEvaluationProfileGroupNodeComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: getEvaluationProfileGroupWidth(numOfNodes),
        height: getEvaluationProfileGroupHeight(numOfNodes),
        background: theme.palette.new.white,
        border: '1px solid',
        borderColor: theme.palette.new.grey_a,
        borderRadius: theme.spacing(0.5),
        position: 'relative',
        cursor: 'default',
      }}
    >
      <ReactFlowHandleComponent
        position={Position.Left}
        type='target'
      />

      <Box
        sx={{
          padding: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          height: '100%',
        }}
      >
        <ReactFlowBlockHeaderComponent
          name={intl.formatMessage({ id: 'connect.block.decision' })}
          help={intl.formatMessage({ id: 'connect.block.evaluationProfile.help' })}
          onAddButtonClick={onAddButtonClick}
          isAddButtonAvailable={isAddButtonAvailable}
          parentComponentName={ReactFlowEvaluationProfileGroupNodeComponent.name}
        />

        {
          numOfNodes ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                '& div > div': {
                  marginRight: theme.spacing(2),
                },
                '& div:last-child': {
                  marginRight: 0,
                },
              }}
            >
              {
                evaluationProfilesChunks.map((chunk, index) => {
                  return (
                    <Box
                      key={createId(index, 'chunk')}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        width: '100%',
                      }}
                    >
                      {
                        chunk.map((item: UseCase.EvaluationProfile, chunkIndex: number) => {
                          const {
                            name,
                            timeResolution,
                            decisionMaker,
                          } = item

                          return (
                            <ReactFlowEvaluationProfileBlockComponent
                              key={createId(index, name)}
                              name={name}
                              timeResolution={timeResolution}
                              decisionMaker={decisionMaker}
                              onClick={() => onParameterClick(item)}
                              dataTestId={`${ReactFlowEvaluationProfileBlockComponent.name}-${ReactFlowEvaluationProfileGroupNodeComponent.name}-${index}`}
                            />
                          )
                        })
                      }
                    </Box>
                  )
                })
              }
            </Box>
          ) : (
            <ReactFlowAddNewButtonComponent
              buttonText={intl.formatMessage({ id: 'connect.block.evaluationProfile.add' })}
              type={INPUT_TYPES.NONE}
              onAddButtonClick={onAddButtonClick}
              parentComponentName={ReactFlowEvaluationProfileGroupNodeComponent.name}
            />
          )
        }
      </Box>
    </Box>
  )
}

export default ReactFlowEvaluationProfileGroupNodeComponent
