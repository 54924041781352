import React from 'react'
import PropTypes from 'prop-types'

import { withRouter, match as Match } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { injectIntl, IntlShape } from 'react-intl'
import TopBarAutocompleteComponent from '@base/topbar/TopBarAutocomplete'

import { State } from '@redux/modules/types'
import { fetchAllUseCasesAction, redirectToUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { FetchAllUseCasesActionActionPayload, RedirectToUseCaseActionPayload } from '@redux/modules/use-case/use-case.types'
import { getSortedUseCasesList, isFetchingUseCases } from '@redux/modules/use-case/use-case.selectors'
import { requestCompanyItemAction } from '@redux/modules/customer/customer.actions'
import { RequestCompanyItemPayload } from '@redux/modules/customer/customer.types'
import { DASHBOARD_PATH } from '@constants/routes.constants'
import { TRACKING_ACTIONS, TRACKING_MODULES, trackEvent } from '@utils/tracking.utils'
import { truncateUseCaseSlug } from '@utils/ui.utils'

import {
  getSelectedCustomerId,
  getSelectedCompanyId,
  getIsAdmin,
  isFetchingCompany,
} from '@redux/modules/customer/customer.selectors'
import { MenuItem, Typography } from '@mui/material'
import { MAX_OPTION_ITEM_WIDTH } from '@base/topbar/TopBarAutocomplete/TopBarAutocomplete.component'

export interface UseCaseSelectorContainerProps {
  isAdmin: boolean
  selectedCompanyId: string
  match: Match<Common.RouterMatch>
  useCases: UseCase.DetailsExtended[]
  intl: IntlShape
  isFetching: boolean

  requestCompanyItem(payload: RequestCompanyItemPayload): any
  fetchAllUseCases(payload: FetchAllUseCasesActionActionPayload): any
  redirectToUseCase(payload: RedirectToUseCaseActionPayload): any
}

export interface UseCaseSelectorContainerState {
  value: null | UseCase.DetailsExtended,
  anchorEl: null | Element,
}

class UseCaseSelectorContainer extends React.Component<UseCaseSelectorContainerProps, UseCaseSelectorContainerState> {
  static defaultProps: any

  static propTypes: any

  constructor(props: UseCaseSelectorContainerProps) {
    super(props)

    const selectedUseCase = props.useCases.find((item) => String(item.useCaseId) === String(props.match.params.usecase))

    this.state = selectedUseCase ? {
      value: selectedUseCase,
      anchorEl: null,
    } : {
      value: null,
      anchorEl: null,
    }
  }

  componentDidMount() {
    const {
      fetchAllUseCases,
      selectedCompanyId,
      requestCompanyItem,
      isAdmin,
      match: { path },
    } = this.props

    /*
    * Fetch all use cases if the user is not an admin
    * For the admin user, the use cases are fetched in the CompanySelector
    *
    * Skip fetching use cases if the user is on the dashboard page, because the use cases are already fetched in the DashboardContainer
    * */
    if (!isAdmin) {
      if (path !== DASHBOARD_PATH) {
        fetchAllUseCases({ companyId: selectedCompanyId })
      }

      requestCompanyItem({ companyId: selectedCompanyId })
    }
  }

  componentDidUpdate(prevProps: UseCaseSelectorContainerProps) {
    const {
      useCases,
      match: { params: { usecase } },
    } = this.props

    const {
      useCases: prevUseCases,
      match: { params: { usecase: prevUsecase } },
    } = prevProps

    if (
      useCases.length &&
      ((useCases.length !== prevUseCases.length) ||
      ((usecase !== prevUsecase)))
    ) {
      const selectedUseCase = useCases.find((item) => String(item.useCaseId) === String(usecase))

      if (selectedUseCase) {
        this.setState({
          value: selectedUseCase,
        })
      } else {
        this.setState({
          value: null,
        })
      }
    }
  }

  getSelectedUseCaseName = () => {
    const {
      intl,
      isFetching,
      match: { params: { usecase } },
    } = this.props

    const {
      value,
    } = this.state

    if (value && value.name) {
      return value.name
    }

    if (isFetching) {
      return intl.formatMessage({ id: 'common.layout.header.loading' })
    }

    if (!usecase) {
      return intl.formatMessage({ id: 'common.layout.header.list.title.no_usecase' })
    }

    return intl.formatMessage({ id: 'common.layout.header.list.title' })
  }

  handleChange = (e: React.SyntheticEvent, value: UseCase.DetailsExtended) => {
    const {
      redirectToUseCase,
      match,
    } = this.props

    if (value) {
      trackEvent({
        moduleName: TRACKING_MODULES.TOP_BAR,
        componentName: 'useCaseSelectorListItem',
        actionName: TRACKING_ACTIONS.CLICK,
      }, {
        useCaseId: value.useCaseId,
      })

      redirectToUseCase({
        useCaseId: value.useCaseId,
        currentLevel: match.params.usecase ? 'use-case' : 'company',
        currentPath: match.path,
      })

      this.setState({
        value,
      })
    }
  }

  renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: UseCase.DetailsExtended, { selected } : { selected: boolean }) => {
    const { isAdmin } = this.props

    if (isAdmin) {
      return (
        <MenuItem
          {...props}
          selected={selected}
          sx={{
            '&&': {
              px: '24px',
              py: '8px',
              display: 'flex',
              justifyContent: 'space-between',
            },
          }}
        >
          <Typography
            variant='body1'
            noWrap={true}
            maxWidth='160px'
          >
            {option?.name}
          </Typography>
          <Typography
            variant='body1'
            noWrap={true}
            maxWidth='160px'
            sx={{
              opacity: 0.4,
            }}
          >
            {truncateUseCaseSlug(option?.fileStorageBucket)}
          </Typography>
        </MenuItem>
      )
    }

    return (
      <MenuItem
        {...props}
        selected={selected}
        sx={{ '&&': { px: '24px', py: '8px' } }}
      >
        <Typography
          variant='body1'
          noWrap={true}
          maxWidth={MAX_OPTION_ITEM_WIDTH}
        >
          {option?.name}
        </Typography>
      </MenuItem>
    )
  }

  filterOptions = (options: UseCase.DetailsExtended[], params: { inputValue: string }) => {
    const { isAdmin } = this.props

    const inputValue = (params?.inputValue || '').toLowerCase()

    if (!inputValue) {
      return options
    }

    if (isAdmin) {
      return options.filter((option) => {
        const lowerCaseOptionName = (option.name || '').toLowerCase()
        const lowerCaseOptionFileStorageBucket = (option.fileStorageBucket || '').toLowerCase()

        return lowerCaseOptionName.includes(inputValue) || lowerCaseOptionFileStorageBucket.includes(inputValue)
      })
    }

    return options.filter((option) => {
      const lowerCaseOptionName = (option.name || '').toLowerCase()

      return lowerCaseOptionName.includes(inputValue)
    })
  }

  render() {
    const {
      useCases,
      intl,
      isFetching,
    } = this.props

    const { value } = this.state

    const selectedUseCaseName = this.getSelectedUseCaseName()

    return (
      <TopBarAutocompleteComponent
        name='useCaseSelectorButton'
        dataTestId={UseCaseSelectorContainer.name}
        buttonAriaLabel={intl.formatMessage({ id: 'common.layout.header.useCaseSelectorAriaLabel' }, { name: selectedUseCaseName })}
        buttonLabel={selectedUseCaseName}
        inputPlaceholder={intl.formatMessage({ id: 'common.layout.header.search.placeholder_use_cases' })}
        noOptionsText={intl.formatMessage({ id: 'common.layout.header.search.not_found_use_cases' })}
        renderOption={this.renderOption}
        isOptionEqualToValue={(fOption, fValue) => {
          return Boolean((fOption && fValue && (fOption.useCaseId === fValue.useCaseId)))
        }}
        getOptionLabel={(option) => {
          return option?.name || ''
        }}
        onChange={this.handleChange}
        filterOptions={this.filterOptions}
        options={useCases}
        value={value}
        disabled={isFetching}
      />
    )
  }
}

UseCaseSelectorContainer.propTypes = {
  useCases: PropTypes.array,
  fetchAllUseCases: PropTypes.func,
  intl: PropTypes.object,
  isFetching: PropTypes.bool,
  match: PropTypes.object,
  isAdmin: PropTypes.bool,
  selectedCompanyId: PropTypes.string,
  requestCompanyItem: PropTypes.func,
}

UseCaseSelectorContainer.defaultProps = {
  useCases: [],
}

export const mapStateToProps = (state: State) => {
  const isFetching = isFetchingUseCases(state) || isFetchingCompany(state)

  return {
    isFetching,
    useCases: getSortedUseCasesList(state),
    customerId: getSelectedCustomerId(state),
    isAdmin: getIsAdmin(state),
    selectedCompanyId: getSelectedCompanyId(state),
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const fetchAllUseCases = bindActionCreators(fetchAllUseCasesAction, dispatch)
  const requestCompanyItem = bindActionCreators(requestCompanyItemAction, dispatch)
  const redirectToUseCase = bindActionCreators(redirectToUseCaseAction, dispatch)

  return {
    redirectToUseCase,
    fetchAllUseCases,
    requestCompanyItem,
  }
}

// @ts-ignore-next-line
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(UseCaseSelectorContainer)))
