import { useEffect, useMemo, useState } from 'react'
// @ts-ignore
import Keycloak from 'keycloak-js'
import axios from 'axios'
import { usePostHog } from 'posthog-js/react'
import type { Store } from 'redux'

import {
  KEYCLOAK_URI,
  KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID,
  CHAT_USER_ROLE,
  KEYCLOAK_ADMIN_ROLE,
} from '@constants/env-replacements.constants'

import {
  changeUserInfoAction,
  changeCompanyIdAction,
} from '@redux/modules/customer/customer.actions'

import { DEFAULT_LOCALE_SHORT } from '@constants/locales.constants'
import { setUserToken } from '@utils/local-storage.utils'
import { initUserTracking } from '@utils/tracking.utils'
import { AXIOS_TIMEOUT_IN_MS } from '@constants/api-provider.constants'

export const initAxios = () => {
  /* eslint-disable dot-notation */
  axios.defaults.headers.common['Accept'] = 'application/json'
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  axios.defaults.timeout = AXIOS_TIMEOUT_IN_MS
}

const useAppInit = ({
  store,
} : {
  store: Store,
}) => {
  const [loading, setLoading] = useState(true)
  const [authError, setAuthError] = useState(false)
  const posthog = usePostHog()

  const keycloak = useMemo(() => new Keycloak({
    url: KEYCLOAK_URI,
    realm: KEYCLOAK_REALM,
    clientId: KEYCLOAK_CLIENT_ID,
  }), [])

  keycloak.onTokenExpired = () => {
    keycloak.updateToken(5).then(() => {
      setUserToken(keycloak.token)
    }).catch(() => {
      keycloak.logout()
    })
  }

  useEffect(() => {
    keycloak.init({
      onLoad: 'login-required',
      /**
       * This is a workaround for the issue where the iframe is not loaded in modern browsers.
       * @see https://www.keycloak.org/securing-apps/javascript-adapter#_session_status_iframe
       */
      checkLoginIframe: false,
    }).then((accessGranted: boolean) => {
      if (accessGranted) {
        const roles = keycloak?.realmAccess?.roles || []
        const language = keycloak?.tokenParsed?.locale || DEFAULT_LOCALE_SHORT
        const companyId = keycloak?.tokenParsed?.companyId
        const isAdmin = roles.includes(KEYCLOAK_ADMIN_ROLE)
        const isChatUser = roles.includes(CHAT_USER_ROLE)

        setUserToken(keycloak.token)

        store.dispatch(changeUserInfoAction({
          ...keycloak.tokenParsed,
          isChatUser,
          language,
          isAdmin,
        } as Common.UserInfo))

        store.dispatch(changeCompanyIdAction({
          companyId,
          saveToLs: false,
        }))

        initAxios()

        initUserTracking(keycloak, posthog)

        setTimeout(() => {
          setLoading(false)
        }, 0)
      } else {
        setAuthError(true)
      }
    }).catch(() => {
      setAuthError(true)
    })
  }, [
    posthog,
    keycloak,
    store,
  ])

  return {
    keycloak,
    loading,
    authError,
  }
}

export default useAppInit
