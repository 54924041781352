import React from 'react'
import { RouterProps } from 'react-router-dom'

import MainLayoutContainer from '@containers/application/MainLayout'
import PreLoaderContainer from '@containers/application/PreLoader'
import ErrorBoundaryContainer, { ERROR_BOUNDARY_ERROR_TYPES } from '@containers/application/ErrorBoundary/ErrorBoundary.container'

interface MainContainerProps {
  keycloak: KeycloakTypes.Keycloak
  children?: any
  Component?: any
  routerProps: RouterProps
}

const MainContainer = (props: MainContainerProps) => {
  const {
    children, keycloak,
    Component, routerProps,
  } = props

  return (
    <MainLayoutContainer
      keycloak={keycloak}
    >
      <ErrorBoundaryContainer type={ERROR_BOUNDARY_ERROR_TYPES.INNER}>
        {Component ? (
          <Component route={routerProps} keycloak={keycloak} />
        ) : (
          children
        )}

        <PreLoaderContainer />
      </ErrorBoundaryContainer>
    </MainLayoutContainer>
  )
}

export default MainContainer
