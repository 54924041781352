import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

import {
  Box, Menu,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'

import DropdownButtonComponent, { DropdownButtonProps } from '@base/dropdowns/DropdownButton/DropdownButton.component'

export const BUTTON_WITH_ARROW_PADDING = '38px'

export interface DropdownMenuOption {
  label: string | React.ReactNode
  value: string | number
}

export interface DropdownMenuComponentProps {
  /**
   * The label to display (displayed on the left of the dropdown button)
   */
  label?: string
  /**
   * Overline text (displayed on top of the dropdown button)
   */
  overline?: string
  /**
   * If 'true', the menu will expand to the full width of the container
   */
  fitMenuToButton?: boolean
  /**
   * If 'true', the button will expand to the full width of the menu
   */
  fitButtonToMenu?: boolean
  /**
   * Data test id
   */
  dataTestId?: string
  /**
   * Name of the dropdown
   */
  name: string
  /**
   * If 'true', the dropdown will be in loading state
   */
  loading?: boolean
  /**
   * If 'true', the dropdown will be disabled
   */
  disabled?: boolean
  /**
   * If 'true', the dropdown will not allow to unselect the selected option
   */
  forbidUnselected?: boolean
  /**
   * Max height of the dropdown in pixels
   */
  maxHeight?: string
  /**
   * If 'true', the dropdown will allow multiple selection
   */
  multiple?: boolean
  /**
   * Dropdown options
   */
  options?: DropdownMenuOption[]
  /**
   * Selected value
   */
  value?: DropdownMenuOption['value'] | DropdownMenuOption['value'][]
  /**
   * Change handler
   */
  onChange: (value: DropdownMenuOption['value'] | DropdownMenuOption['value'][]) => void
  /**
   * Custom button props
   */
  buttonProps?: Partial<DropdownButtonProps>
  /**
   * Minimum width of the dropdown menu
   */
  minWidth?: string
  /**
   * If true, react-intl will be used to format the option label
   * @default false
   */
  useIntlForOptionLabel?: boolean
}

export const DropdownMenuComponent: React.FC<DropdownMenuComponentProps> = ({
  dataTestId,
  name,
  label,
  value,
  disabled,
  forbidUnselected,
  multiple,
  overline,
  maxHeight,
  onChange,
  options = [],
  buttonProps,
  fitMenuToButton = true,
  fitButtonToMenu = false,
  minWidth,
  useIntlForOptionLabel = false,
}) => {
  const intl = useIntl()
  const { params } = useRouteMatch<Common.RouterMatch>()

  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const menuRef = React.useRef<HTMLUListElement>(null)

  const [openMenu, setOpenMenu] = useState(false)
  const [buttonWidth, setButtonWidth] = useState(0)

  const handleOpen = () => {
    setOpenMenu(true)
  }

  const handleClose = () => {
    setOpenMenu(false)
  }

  const handleOptionClick = (option: DropdownMenuOption) => {
    if (multiple && Array.isArray(value)) {
      const isChecked = value.includes(option.value)
      const newValue = isChecked ? value.filter((item) => item !== option.value) : [...value, option.value]

      onChange(newValue)
    } else {
      onChange(option.value)
    }

    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CHANGE,
    }, {
      option,
      router: params,
    })

    handleClose()
  }

  const buttonLabel = useMemo(() => {
    if (value === undefined || value === null) {
      return intl.formatMessage({ id: 'common.dropdown.loading' })
    }

    if (!options || options.length === 0) {
      return intl.formatMessage({ id: 'common.dropdown.noOptions' })
    }

    if (multiple) {
      if (!Array.isArray(value) || !value || value.length === 0) {
        return intl.formatMessage({ id: 'common.dropdown.select' })
      }

      return intl.formatMessage({ id: 'common.dropdown.nSelected' }, {
        n: value.length,
      })
    }

    const selectedOption = options.find((option) => option.value === value)
    const selectedOptionLabel = selectedOption?.label
    const formattedOptionLabel = (selectedOptionLabel && useIntlForOptionLabel && typeof selectedOptionLabel === 'string') ? (
      intl.formatMessage({ id: selectedOption?.label as string })
    ) : selectedOptionLabel

    return formattedOptionLabel || intl.formatMessage({ id: 'common.dropdown.select' })
  }, [intl, multiple, options, value, useIntlForOptionLabel])

  useEffect(() => {
    if (menuRef.current) {
      setButtonWidth(menuRef.current.offsetWidth)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuRef.current])

  return (
    <Box
      data-testid={dataTestId || DropdownMenuComponent.name}
      display='flex'
      alignItems='center'
      gap={0.5}
    >
      {
        label ? (
          <Typography>
            {label}
          </Typography>
        ) : (
          null
        )
      }

      <DropdownButtonComponent
        label={buttonLabel}
        dataTestId={dataTestId}
        onClick={handleOpen}
        name={name}
        open={openMenu}
        bold={true}
        outlined={true}
        disabled={disabled}
        overline={overline}
        handleRef={buttonRef}
        {...buttonProps}
        sx={{
          ...buttonProps?.sx,
          ...(fitButtonToMenu && {
            justifyContent: 'space-between',
            width: buttonWidth || 'auto',
          }),
        }}
      />

      <Menu
        open={openMenu}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        keepMounted={fitButtonToMenu}
        sx={{
          '& .MuiPaper-root': {
            transform: 'translate(0px, 2px) !important',
            maxHeight,
          },
        }}
        MenuListProps={{
          ref: menuRef,
          sx: {
            width: fitMenuToButton ? (buttonRef && buttonRef.current?.offsetWidth) : '100%',
            minWidth,
          },
        }}
      >
        {
          options?.map((option) => {
            const isChecked = Array.isArray(value) ? value.includes(option.value) : value === option.value
            const isDisabled = forbidUnselected && Array.isArray(value) && isChecked && value.length === 1
            const formattedOptionLabel = (useIntlForOptionLabel && typeof option.label === 'string') ? (
              intl.formatMessage({ id: option.label as string })
            ) : option.label

            return (
              <MenuItem
                key={option.value}
                onClick={() => handleOptionClick(option)}
                disabled={isDisabled}
                selected={isChecked && !multiple}
                sx={fitButtonToMenu ? {
                  pr: BUTTON_WITH_ARROW_PADDING,
                } : {}}
              >
                <ListItemText
                  primary={formattedOptionLabel}
                  primaryTypographyProps={{
                    style: {
                      ...(typeof formattedOptionLabel !== 'string' ? {
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      } : {}),
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                />
              </MenuItem>
            )
          })
        }
      </Menu>
    </Box>
  )
}

export default DropdownMenuComponent
