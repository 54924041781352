import React from 'react'
import { useIntl } from 'react-intl'

import {
  AppBar, Toolbar,
  Box, useTheme,
} from '@mui/material'

import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getChatbotState } from '@redux/modules/common/common.selectors'
import { getCurrentUserInfo, getIsAdmin, getIsChatUser } from '@redux/modules/customer/customer.selectors'
import { COMPANY_USERS_MODAL_NAME } from '@constants/modals.constants'
import { CompanyUsersModalPayload } from '@containers/modals/company-users-modal/CompanyUsersModal.container'
import { getCompanyUser } from '@redux/modules/customer/customer.api'

import { DEFAULT_PADDING, TOPBAR_HEIGHT } from '@constants/ui.constants'
import { CHAT_WIDTH } from '@constants/chat.constnats'

import { TRACKING_MODULES } from '@utils/tracking.utils'

import LogoComponent from '@base/topbar/Logo'
import LogOutIcon from '@icons/logOut.icon'
import ProfileIcon from '@icons/profile.icon'

import TopBarButtonComponent from '@base/topbar/TopBarButton'
import TopBarDividerComponent from '@base/topbar/TopBarDivider'

import UseCaseSelectorContainer from '@containers/application/UseCaseSelector'
import CompanySelectorContainer from '@containers/application/CompanySelector'
import StatusBarContainer from '@containers/application/StatusBar'
import ChatSliderContainer from '@containers/application/ChatSlider'
import NotificationsContainer from '@containers/application/Notifications'

import {
  ROUTES,
  DASHBOARD_PAGE_NAME,
} from '@constants/routes.constants'

import CompanyUsersModalContainer from '@containers/modals/company-users-modal'
import CopyButtonComponent from '@base/buttons/CopyButton'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'

export interface TopBarContainerProps {
  /**
   * Keycloak instance, used for logging out
   */
  keycloak: KeycloakTypes.Keycloak,
  /**
   * Function to set chat width, during resize main layout is affected
   * @param width new chat width
   */
  setChatWidth: (width: number) => void,
  /**
   * Current chat width
   */
  chatWidth: number,
}

const TopBarContainer: React.FC<TopBarContainerProps> = ({ keycloak, chatWidth, setChatWidth }) => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const theme = useTheme()
  const match = useRouteMatch<Common.RouterMatch>()
  const isAdmin = useSelector(getIsAdmin)
  const useCaseDetails = useSelector(getUseCaseItem)
  const chatbotState = useSelector(getChatbotState)
  const isChatUser = useSelector(getIsChatUser)
  const currentUserInfo = useSelector(getCurrentUserInfo)
  const userEmail = currentUserInfo?.email || ''

  const onLogoClick = () => {
    dispatch(
      setPrimaryModalPageName(''),
    )

    history.push(ROUTES[DASHBOARD_PAGE_NAME])
  }

  const onLogoutClick = async () => {
    await keycloak.logout()
  }

  const onUserSettingsClick = async () => {
    const customerId = keycloak.subject!
    const user = await getCompanyUser({ customerId })

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: COMPANY_USERS_MODAL_NAME,
        modalDetails: {
          record: user,
          returnTo: '',
        } as CompanyUsersModalPayload,
      }),
    )
  }

  return (
    <>
      <AppBar
        elevation={0}
        data-testid={TopBarContainer.name}
        sx={{
          minHeight: TOPBAR_HEIGHT,
          height: TOPBAR_HEIGHT,
          borderBottom: `1px solid ${theme.palette.new.grey_a}`,
          backgroundColor: theme.palette.new.white,
          zIndex: theme.zIndex.drawer,
        }}
      >
        <Toolbar
          sx={{
            '&.MuiToolbar-root': {
              minHeight: TOPBAR_HEIGHT,
              height: TOPBAR_HEIGHT,
              padding: 0,
              justifyContent: 'space-between',
              marginRight: `${chatbotState ? ((chatWidth || CHAT_WIDTH) - 1) : 0}px`,
            },
          }}
        >
          <LogoComponent onClick={onLogoClick} />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              marginLeft: DEFAULT_PADDING.MEDIUM,
            }}
          >

            <CompanySelectorContainer />

            <TopBarDividerComponent />

            <UseCaseSelectorContainer />

            {
              (isAdmin && match.params.usecase) ? (
                <>
                  <TopBarDividerComponent />

                  <Box
                    display='flex'
                    alignItems='center'
                    px={1}
                    sx={{
                      opacity: 0.4,
                    }}
                  >
                    <CopyButtonComponent
                      value={useCaseDetails.fileStorageBucket}
                      reversed={true}
                      maxValueWidth='auto'
                    />
                  </Box>
                </>
              ) : (
                null
              )
            }
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <StatusBarContainer />

            <TopBarDividerComponent />

            <Box
              display='flex'
              gap={1}
              mr={isChatUser ? 0 : 1}
            >
              <NotificationsContainer />

              <TopBarButtonComponent
                name='userSettingsButton'
                IconComponent={ProfileIcon}
                onClick={onUserSettingsClick}
                trackingProps={{ moduleName: TRACKING_MODULES.TOP_BAR }}
                label={intl.formatMessage({ id: 'common.layout.button.profileSettings' })}
                disabled={!userEmail}
              />

              <TopBarButtonComponent
                name='logOutButton'
                IconComponent={LogOutIcon}
                onClick={onLogoutClick}
                trackingProps={{ moduleName: TRACKING_MODULES.TOP_BAR }}
                label={intl.formatMessage({ id: 'common.layout.button.logOut' })}
              />
            </Box>

            <ChatSliderContainer
              onChatWidthChange={setChatWidth}
              chatWidth={chatWidth}
            />
          </Box>
        </Toolbar>
      </AppBar>

      <CompanyUsersModalContainer
        keycloak={keycloak}
      />
    </>
  )
}

export default TopBarContainer
