export const ANALYZE_TABLE_CONFIG_VERSION = 5
export const BACKTESTING_TABLE_CONFIG_VERSION = 4
export const LIVE_MONITORING_TABLE_CONFIG_VERSION = 4
export const BASELINE_COMPARISON_TABLE_CONFIG_VERSION = 7

export const INSIGHTS_TRUTH_LINE_STROKE_WIDTH = 1
export const INSIGHTS_LAST_YEAR_TRUTH_LINE_STROKE_WIDTH = 1
export const INSIGHTS_PREDICTION_LINE_STROKE_WIDTH = 3
export const INSIGHTS_BASELINE_LINE_STROKE_WIDTH = 3

export const INSIGHTS_GROUPING_LAST_YEAR_COLORWAY_OPACITY = '66'
export const INSIGHTS_X_AXIS_TICKS_FORMAT_EN = 'MMM DD, YYYY'
export const INSIGHTS_X_AXIS_TICKS_FORMAT_DE = 'DD. MMM YYYY'

export const INSIGHTS_COLOR_WAY = [
  {
    base: '#51D7E6',
    background: '#F7FDFD',
    hover: '#EFFBFD',
  },
  {
    base: '#F96385',
    background: '#FEF8F9',
    hover: '#FFF0F3',
  },
  {
    base: '#AAA3CC',
    background: '#FBFBFD',
    hover: '#F6F6FA',
  },
  {
    base: '#FBB03B',
    background: '#FFFCF7',
    hover: '#FEF7EE',
  },
  {
    base: '#00C0AD',
    background: '#F5FCFB',
    hover: '#EAF9F7',
  },
  {
    base: '#E026A7',
    background: '#FEF5FB',
    hover: '#FCECF7',
  },
  {
    base: '#D7E651',
    background: '#FDFEF8',
    hover: '#FBFCEF',
  },
  {
    base: '#518DE6',
    background: '#F7FAFE',
    hover: '#EFF4FC',
  },
  {
    base: '#3BFBB0',
    background: '#F6FFFB',
    hover: '#EEFEF8',
  },
  {
    base: '#F51400',
    background: '#FEF5F4',
    hover: '#FEEBEA',
  },
  {
    base: '#96BEF9',
    background: '#FAFCFE',
    hover: '#F5F8FF',
  },
  {
    base: '#26E05F',
    background: '#F6FDF8',
    hover: '#ECFCF0',
  },
]

export const INSIGHTS_MAX_SELECTED_ROWS_IN_GROUPING_MODE = 12
export const INSIGHTS_PINNED_COLUMN_DEFAULT_WIDTH = 80
export const INSIGHTS_PINNED_PREDICTION_MIN_WIDTH = INSIGHTS_PINNED_COLUMN_DEFAULT_WIDTH
export const INSIGHTS_PINNED_COLUMN_DEFAULT_HEADER_ALIGN = 'left'
export const INSIGHTS_BASELINE_STORKE_DASHARRAY = '6 1'

export const INSIHGTS_DEFAULT_LAST_YEAR_LINE_COLOR = '#00000033'
export const INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD = 'lastYearTarget'
export const INSIGHTS_DEFAULT_DATE_FIELD = 'date'
export const INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD_PREFIX = 'lastYearTarget'

export const DEFAULT_BACKTEST_TARGET_COLUMN = '_target'
export const DEFAULT_BACKTEST_PREDICTION_COLUMN = '_backtest_prediction'
export const DEFAULT_BACKTEST_PREDICTION_FIELD_PREFIX = 'backtest_prediction'
export const DEFAULT_BACKTEST_ABS_DEVIATION_FIELD_PREFIX = 'abs_deviation'

export const DEFAULT_REL_DEVIATION_COLUMN = '_rel_deviation'
export const DEFAULT_ABS_DEVIATION_COLUMN = '_abs_deviation'

export const DEFAULT_PARETOS_REL_DEVIATION_COLUMN = '_paretos_rel_deviation'
export const DEFAULT_PARETOS_ABS_DEVIATION_COLUMN = '_paretos_abs_deviation'
export const DEFAULT_BASELINE_REL_DEVIATION_COLUMN = '_baseline_rel_deviation'
export const DEFAULT_BASELINE_ABS_DEVIATION_COLUMN = '_baseline_abs_deviation'

export const DEFAULT_BACKTEST_PREDICTION_FIELD = 'backtest_prediction'
export const DEFAULT_BACKTEST_TARGET_FIELD = 'target'
export const DEFAULT_BACKTEST_ABS_DEVIATION_FIELD = 'abs_deviation'

export const DEFAULT_LIVE_MONITORING_TARGET_COLUMN = '_target'
export const DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN = '_prediction'
export const DEFAULT_LIVE_MONITORING_PREDICTION_FIELD_PREFIX = 'prediction'
export const DEFAULT_LIVE_MONITORING_ABS_DEVIATION_FIELD_PREFIX = 'abs_deviation'

export const DEFAULT_LIVE_MONITORING_TARGET_FIELD = 'target'
export const DEFAULT_LIVE_MONITORING_PREDICTION_FIELD = 'prediction'
export const DEFAULT_LIVE_MONITORING_ABS_DEVIATION_FIELD = 'abs_deviation'

export const DEFAULT_BASELINE_COMPARISON_TARGET_COLUMN = '_target'
export const DEFAULT_BASELINE_COMPARISON_PREDICTION_COLUMN = '_paretos_prediction'
export const DEFAULT_BASELINE_COMPARISON_BASELINE_COLUMN = '_baseline_prediction'

export const DEFAULT_BASELINE_COMPARISON_PREDICTION_FIELD_PREFIX = 'paretos_prediction'
export const DEFAULT_BASELINE_COMPARISON_BASELINE_FIELD_PREFIX = 'baseline_prediction'

export const DEFAULT_BASELINE_COMPARISON_TARGET_FIELD = 'target'
export const DEFAULT_BASELINE_COMPARISON_PREDICTION_FIELD = 'paretos_prediction'
export const DEFAULT_BASELINE_COMPARISON_BASELINE_FIELD = 'baseline_prediction'

export const DEFAULT_ANALYZE_TARGET_COLUMN = '_target'
export const DEFAULT_ANALYZE_PREDICTION_COLUMN = '_prediction'
export const DEFAULT_ANALYZE_PREDICTION_FIELD = 'prediction'
export const DEFAULT_ANALYZE_PREDICTION_FIELD_PREFIX = 'prediction'
export const DEFAULT_ANALYZE_TARGET_FIELD = 'target'
