import {
  MONITOR_LIVE_MONITORING_PATH,
  MONITOR_BACKTESTING_PATH,
  MONITOR_BASELINE_COMPARISON_PATH,
} from '@constants/routes.constants'

import { DEFAULT_EVENTS_TO_EXCLUDE } from '@utils/events.utils'

export const LS_SIDEBAR_STATE = 'LS_SIDEBAR_STATE_V1'
export const MEMORY_TOOLTIPS = 'LS_MEMORY_TOOLTIPS'

export const USER_TOKEN = 'LS_USER_TOKEN'
export const USER_TOKEN_META = 'LS_USER_TOKEN_META'

export const LS_COMPANY_ID = 'LS_COMPANY_ID'
export const LS_SIDEPANEL_MAX_WIDTH = 'LS_SIDEPANEL_MAX_WIDTH'
export const LS_DEFAULT_MONITOR_TAB = 'LS_DEFAULT_MONITOR_TAB'

export const LS_CHAT_ENDPOINT = 'LS_CHAT_ENDPOINT'
export const LS_CHAT_USE_TOKEN = 'LS_CHAT_USE_TOKEN'
export const LS_CHAT_TOKEN = 'LS_CHAT_TOKEN'
export const LS_CHAT_ADDITIONAL_PAYLOAD = 'LS_CHAT_ADDITIONAL_PAYLOAD'

export const LS_PROMOTIONS_VISIBILITY = 'LS_PROMOTIONS_VISIBILITY'
export const LS_PROMOTIONS_TO_EXCLUDE = 'LS_PROMOTIONS_TO_EXCLUDE_V1'
export const LS_LAST_YEAR_VISIBILITY = 'LS_LAST_YEAR_VISIBILITY'

/**
 * @function setCompanyIdToStorage Sets selected company ID to local storage
 *
 * @param {String} currentUserId Current User ID
 * @param {String} selectedCompanyId selected company id
 *
 * @return {Void}
 */
export const setCompanyIdToStorage = (currentUserId: string, selectedCompanyId: string) => {
  sessionStorage.setItem(LS_COMPANY_ID, JSON.stringify({ [currentUserId]: selectedCompanyId }))
}

/**
 * @function getCompanyIdFromStorage Gets value from local storage
 *
 * @param {String} currentUserId Current User ID
 *
 * @return {String} Selected Company Id
 */
export const getCompanyIdFromStorage = (currentUserId: string): string => {
  const result = JSON.parse(sessionStorage.getItem(LS_COMPANY_ID) || '{}')

  if (!currentUserId) {
    return ''
  }

  return result[currentUserId] || ''
}

/**
 * @function setMemoryTooltipState Sets state of memory tooltip
 *
 * @param {String} id Tooltip ID
 *
 * @return {Void}
 */
export const setMemoryTooltipState = (id: string) => {
  const acceptedTooltips = getAcceptedTooltipsList() || []

  if (!acceptedTooltips.includes(id)) {
    acceptedTooltips.push(id)

    localStorage.setItem(MEMORY_TOOLTIPS, JSON.stringify(acceptedTooltips))
  }
}

/**
 * @function getAcceptedTooltipsList Gets memory tooltips state
 *
 * @return {Array} List of accepted tooltips
 */
export const getAcceptedTooltipsList = (): string[] => {
  return JSON.parse(localStorage.getItem(MEMORY_TOOLTIPS) || '[]')
}

/**
 * @function getMemoryTooltipState Gets memory tooltip state by Id
 *
 * @param {String} id Tooltip ID
 *
 * @return {Boolean} Tooltip State
 */
export const getMemoryTooltipState = (id: string): boolean => {
  const acceptedTooltips = getAcceptedTooltipsList() || []

  return acceptedTooltips.includes(id)
}

/**
 * @function setUserToken Sets user token to local storage
 *
 * @param {String} token User token
 *
 * @return {Void}
 */
export const setUserToken = (token: string | undefined) => {
  localStorage.setItem(USER_TOKEN, token || '')
}

/**
 * @function getUserToken Gets user token from local storage
 *
 * @return {String} User token
 */
export const getUserToken = (): string | null | undefined => {
  return localStorage.getItem(USER_TOKEN)
}

/**
 * @function setSidebarState Sets sidebar state
 *
 * @param {String} state sidebar state
 *
 * @return {Void}
 */
export const setSidebarState = (sideBarState: boolean | string) => {
  localStorage.setItem(LS_SIDEBAR_STATE, String(sideBarState))
}

/**
 * @function getSidebarState Gets sidebar state
 *
 * @return {Bool} sidebar state
 */
export const getSidebarState = (): boolean => {
  const state = localStorage.getItem(LS_SIDEBAR_STATE)

  return state ? (String(state) === 'true') : false
}

/**
 * @function setSidePanelMaxWidth Sets side panel max width
 * @param {Number} width side panel max width
 * @return {Void}
 *
 */
export const setSidePanelMaxWidth = (width: number | null) => {
  if (!width) {
    return
  }

  localStorage.setItem(LS_SIDEPANEL_MAX_WIDTH, String(width))
}

/**
 * @function getSidePanelMaxWidth Gets side panel max width
 * @return {Number} side panel max width
 */
export const getSidePanelMaxWidth = (): number | null => {
  const width = localStorage.getItem(LS_SIDEPANEL_MAX_WIDTH)
  const widthAsNumber = Number(width)

  if (!width) {
    return null
  }

  if (isNaN(widthAsNumber)) {
    return null
  }

  return widthAsNumber
}

/**
 * @function setDefaultMonitorTab Sets default monitor tab
 * @param {String} tab default monitor tab
 * @return {Void}
 *
 */
export const setDefaultMonitorTab = (tab: string) => {
  if (!tab || ![MONITOR_LIVE_MONITORING_PATH, MONITOR_BACKTESTING_PATH, MONITOR_BASELINE_COMPARISON_PATH].includes(tab)) {
    return
  }

  localStorage.setItem(LS_DEFAULT_MONITOR_TAB, tab)
}

/**
 * @function getDefaultMonitorTab Gets default monitor tab
 * @return {String} default monitor tab
 */
export const getDefaultMonitorTab = (): string => {
  const tabFromLs = localStorage.getItem(LS_DEFAULT_MONITOR_TAB)

  if (!tabFromLs || ![MONITOR_LIVE_MONITORING_PATH, MONITOR_BACKTESTING_PATH, MONITOR_BASELINE_COMPARISON_PATH].includes(tabFromLs)) {
    return MONITOR_BACKTESTING_PATH
  }

  return tabFromLs
}

/**
 * Sets promotion visibility to local storage
 * @param visibility promotion visibility
 */
export const setEventsVisibility = (visibility: boolean) => {
  localStorage.setItem(LS_PROMOTIONS_VISIBILITY, String(visibility))
}

/**
 * Gets promotion visibility from local storage
 * @returns true if promotion is visible, false otherwise
 */
export const getEventsVisibility = (): boolean => {
  const visibility = localStorage.getItem(LS_PROMOTIONS_VISIBILITY)

  if (visibility === null || visibility === undefined) {
    return true
  }

  return visibility ? (String(visibility) === 'true') : false
}

/**
 * Sets last year visibility to local storage
 * @param visibility last year visibility
 */
export const setLastYearVisibility = (visibility: boolean) => {
  localStorage.setItem(LS_LAST_YEAR_VISIBILITY, String(visibility))
}

/**
 * Gets last year visibility from local storage
 * @returns true if last year is visible, false otherwise
 */
export const getLastYearVisibility = (): boolean => {
  const visibility = localStorage.getItem(LS_LAST_YEAR_VISIBILITY)

  if (visibility === null || visibility === undefined) {
    return true
  }

  return visibility ? (String(visibility) === 'true') : false
}

/**
 * Sets promotion days to exclude to local storage
 * @param promotionDaysToExclude promotion days to exclude
 */
export const setEventsToExclude = (promotionDaysToExclude: string[]) => {
  localStorage.setItem(LS_PROMOTIONS_TO_EXCLUDE, JSON.stringify(promotionDaysToExclude))
}

/**
 * Gets promotion days to exclude from local storage
 * @returns promotion days to exclude
 */
export const getEventsToExclude = (): string[] => {
  const promotionDaysToExclude = localStorage.getItem(LS_PROMOTIONS_TO_EXCLUDE)

  try {
    return promotionDaysToExclude ? JSON.parse(promotionDaysToExclude) : DEFAULT_EVENTS_TO_EXCLUDE
  } catch (e) {
    return DEFAULT_EVENTS_TO_EXCLUDE
  }
}
