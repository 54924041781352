import React from 'react'

export const EVENTS_AREA_HEIGHT = 6

interface CustomReferenceAreaProps {
  /**
   * Id of the promotion area
   */
  id?: string
  /*
  * x-coordinate (in pixels)
  */
  x?: number
  /**
   * Starting x-coordinate (in pixels or derived from scale)
   */
  x1?: number
  /**
   * Ending x-coordinate (in pixels or derived from scale)
   */
  x2?: number
  /**
   * y-coordinate (in pixels)
   */
  y?: number
  /**
   * Starting y-coordinate (top position)
   */
  y1?: number
  /**
   * Ending y-coordinate (bottom position)
   */
  y2?: number
  /**
   * Height of the rectangle
   */
  height?: number
  /**
   * Width of the rectangle
   */
  width?: number
  /**
   * Fill color of the rectangle
   */
  fill?: string
  /**
   * Fill opacity of the rectangle
   */
  fillOpacity?: number
  /**
   * Border color of the rectangle
   */
  stroke?: string
  /**
   * Clip path of the rectangle
   */
  clipPath?: string
}

export const InsightsEventsAreaComponent: React.FC<CustomReferenceAreaProps> = ({
  id, x, x1, x2, y, width, fill, fillOpacity, stroke, clipPath, height, ...rest
}) => {
  return (
    <g
      clipPath={clipPath}
      className='insights-events-area'
    >
      <rect
        id={id}
        x={x}
        x1={x1}
        x2={x2}
        y={(height && y) ? (height + y) - EVENTS_AREA_HEIGHT : 0}
        width={width}
        height={EVENTS_AREA_HEIGHT}
        fill={fill}
        fillOpacity={fillOpacity}
        stroke={stroke}
        r={1}
      />
    </g>
  )
}

export default InsightsEventsAreaComponent
