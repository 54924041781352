import React, { useLayoutEffect, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Box, Typography, useTheme } from '@mui/material'
import { NodeTypes } from '@xyflow/react'

import { useSelector, useDispatch } from '@redux/hooks'
import { requestNotificationsRoutingGraphAction } from '@redux/modules/notifications/notifications.actions'
import { getNotificationsRoutingGraph, isFetchingNotificationsRoutingGraph } from '@redux/modules/notifications/notifications.selectors'
import { getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { NODE_TYPES } from '@constants/flow.constants'
import { NOTIFICATIONS_TYPES, NOTIFICATIONS_TYPES_LIST } from '@constants/notifications.constants'
import { DIRECTIONS } from '@utils/flow.utils'

import SectionTileComponent from '@base/tiles/SectionTile/SectionTile.component'
import ReactFlowComponent from '@components/connect-view/flow/ReactFlow/ReactFlow.component'
import ReactFlowNotificationTypeNodeComponent from '@components/connect-view/flow/ReactFlowNotificationTypeNode'
import ReactFlowNotificationSinkInfoNodeComponent from '@components/connect-view/flow/ReactFlowNotificationSinkInfoNode'
import IconButtonComponent from '@base/buttons/IconButton'
import ProgressIcon from '@icons/progress.icon'
import AutocompleteSelectFieldComponent from '@base/autocomplete/AutocompleteSelectField/AutocompleteSelectField.component'

const nodeTypes = {
  [NODE_TYPES.NOTIFICATION_TYPE]: ReactFlowNotificationTypeNodeComponent,
  [NODE_TYPES.NOTIFICATION_SINK_INFO]: ReactFlowNotificationSinkInfoNodeComponent,
} as NodeTypes

export interface RoutingsGraphContainerProps {
}

const RoutingsGraphContainer: React.FC<RoutingsGraphContainerProps> = (props) => {
  const intl = useIntl()
  const theme = useTheme()
  const dispatch = useDispatch()
  const selectedCompanyId = useSelector(getSelectedCompanyId)
  const isFetching = useSelector(isFetchingNotificationsRoutingGraph)
  const routingGraph = useSelector(getNotificationsRoutingGraph)

  const [notificationType, setNotificationType] = useState<NOTIFICATIONS_TYPES>(NOTIFICATIONS_TYPES.DATA_STALE)

  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  useLayoutEffect(() => {
    dispatch(
      requestNotificationsRoutingGraphAction({
        companyId: selectedCompanyId,
        useCaseId: usecase,
        type: notificationType,
      }),
    )
  }, [
    selectedCompanyId,
    usecase,
    notificationType,
    dispatch,
  ])

  const rightSideActions = useMemo(() => {
    return (
      <Box
        display='flex'
        alignItems='center'
        gap={1}
      >
        <Box
          display='flex'
          alignItems='center'
          gap={1}
        >
          <Typography
            variant='body1'
            noWrap={true}
          >
            {intl.formatMessage({ id: 'notifications.routings.graph.notification_type' })}
          </Typography>

          <AutocompleteSelectFieldComponent
            options={NOTIFICATIONS_TYPES_LIST}
            handleChangeCallback={(e, selectedValue) => {
              if (selectedValue) {
                setNotificationType(selectedValue.value as NOTIFICATIONS_TYPES)
              }
            }}
            clearIcon={false}
            multiple={false}
            value={NOTIFICATIONS_TYPES_LIST.find((type) => type.value === notificationType)}
            name='notificationTypeInGraph'
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            label={intl.formatMessage({ id: 'notifications.routings.graph.notification_type' })}
            hideLabel={true}
            freeSolo={false}
            placeholder={intl.formatMessage({ id: 'notifications.routings.graph.notification_type.placeholder' })}
            containerSx={{
              minWidth: '200px',
              maxWidth: '200px',
            }}
          />
        </Box>

        <IconButtonComponent
          name='refresh'
          color='secondary'
          loading={isFetching}
          label={intl.formatMessage({ id: 'notifications.routings.graph.refresh' })}
          IconComponent={ProgressIcon}
          onClick={() => {
            dispatch(requestNotificationsRoutingGraphAction({
              companyId: selectedCompanyId,
              useCaseId: usecase,
              type: notificationType,
            }))
          }}
        />
      </Box>
    )
  }, [
    intl,
    selectedCompanyId,
    usecase,
    isFetching,
    notificationType,
    setNotificationType,
    dispatch,
  ])

  return (
    <SectionTileComponent
      name='routingsGraph'
      loading={isFetching}
      rightSideActions={rightSideActions}
      data-testid={RoutingsGraphContainer.name}
      title={intl.formatMessage({ id: 'notifications.routings.graph.title' })}
      sx={{ mt: 2 }}
      contentMinHeight='550px'
      noDataMessage={intl.formatMessage({ id: 'notifications.routings.graph.title.no_routings' })}
      noData={!routingGraph.nodes.length}
    >
      <ReactFlowComponent<Notifications.NotificationGraphNodeData>
        edges={routingGraph.edges}
        nodes={routingGraph.nodes}
        nodeTypes={nodeTypes}
        direction={DIRECTIONS.TB}
        fitViewOptions={{
          maxZoom: 1,
        }}
        sx={{
          borderRadius: '10px',
          border: `1px solid ${theme.palette.new.grey_a}`,
          height: '500px',
          position: 'relative',
        }}
      />
    </SectionTileComponent>
  )
}

export default RoutingsGraphContainer
