export const REQUEST_USE_CASES = 'REQUEST_USE_CASES'
export const RECEIVE_USE_CASES = 'RECEIVE_USE_CASES'

export const REQUEST_USE_CASE = 'REQUEST_USE_CASE'
export const RECEIVE_USE_CASE = 'RECEIVE_USE_CASE'

export const REDIRECT_TO_USE_CASE = 'REDIRECT_TO_USE_CASE'
export const REDIRECTED_TO_USE_CASE = 'REDIRECTED_TO_USE_CASE'

export const CREATE_USE_CASE = 'CREATE_USE_CASE'
export const UPDATE_USE_CASE = 'UPDATE_USE_CASE'
export const DELETE_USE_CASE = 'DELETE_USE_CASE'

export const REQUEST_DEMAND_PROBLEM_DEFINITION = 'REQUEST_DEMAND_PROBLEM_DEFINITION'
export const RECEIVE_DEMAND_PROBLEM_DEFINITION = 'RECEIVE_DEMAND_PROBLEM_DEFINITION'
export const UPDATE_DEMAND_PROBLEM_DEFINITION = 'UPDATE_DEMAND_PROBLEM_DEFINITION'

export const CREATE_FORECAST_PARAMETERS = 'CREATE_FORECAST_PARAMETERS'

export const MANAGE_USE_CASE = 'MANAGE_USE_CASE'
export const FREEZE_USE_CASE = 'FREEZE_USE_CASE'
export const SET_USE_CASE_CONTACTS = 'SET_USE_CASE_CONTACTS'

export const REQUEST_USE_CASE_AVAILABLE_META_DATA_COLUMNS = 'REQUEST_USE_CASE_AVAILABLE_META_DATA_COLUMNS'
export const RECEIVE_USE_CASE_AVAILABLE_META_DATA_COLUMNS = 'RECEIVE_USE_CASE_AVAILABLE_META_DATA_COLUMNS'
export const REQUEST_USE_CASE_SPECIFIED_META_DATA_COLUMNS = 'REQUEST_USE_CASE_SPECIFIED_META_DATA_COLUMNS'
export const RECEIVE_USE_CASE_SPECIFIED_META_DATA_COLUMNS = 'RECEIVE_USE_CASE_SPECIFIED_META_DATA_COLUMNS'
export const SET_USE_CASE_META_DATA_COLUMNS = 'SET_USE_CASE_META_DATA_COLUMNS'

export const START_FETCHING_USE_CASES = 'START_FETCHING_USE_CASES'
export const STOP_FETCHING_USE_CASES = 'STOP_FETCHING_USE_CASES'

export const PREPARE_CONNECT_OVERVIEW = 'PREPARE_CONNECT_OVERVIEW'
export const RESET_CONNECT_OVERVIEW = 'RESET_CONNECT_OVERVIEW'
export const PREPARE_CONNECT_OVERVIEW_DONE = 'PREPARE_CONNECT_OVERVIEW_DONE'

export const REQUEST_FORECAST_METADATA = 'REQUEST_FORECAST_METADATA'
export const RECEIVE_FORECAST_METADATA = 'RECEIVE_FORECAST_METADATA'
