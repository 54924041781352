import React from 'react'
import { useIntl } from 'react-intl'

import {
  Card, Grid,
  Typography,
  Box,
  useTheme,
} from '@mui/material'

import { DEFAULT_BORDER_RADIUS, DEFAULT_BUTTON_TRANSITION } from '@constants/ui.constants'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'
import { truncateUseCaseSlug } from '@utils/ui.utils'

import GearIcon from '@icons/gear.icon'
import ButtonComponent from '@base/buttons/Button'
import PotentialCoSavingsComponent from '@components/dashboard/PotentialCoSavings'
import UseCaseListItemBlockComponent from '@components/dashboard/UseCaseListItemBlock'
import ForecastAddedValueComponent from '@components/dashboard/ForecastAddedValue'
import TargetsBlockComponent from '@components/dashboard/TargetsBlock'
import CopyButtonComponent from '@base/buttons/CopyButton'

export interface UseCaseListItemComponentProps {
  /**
   * Use case details
   */
  useCase: UseCase.DetailsExtended
  /**
   * Is user admin
   */
  isAdmin: boolean
  /**
   * Handle item click
   * @param useCaseId Use case id
   */
  onItemClick?: (useCaseId: string) => void
  /**
   * Handle admin button click
   * @param useCaseId Use case id
   */
  onAdminButtonClick?: (useCaseId: string) => void
}

export const UseCaseListItemComponent: React.FC<UseCaseListItemComponentProps> = ({
  useCase,
  isAdmin,
  onItemClick,
  onAdminButtonClick,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  const useCaseId = useCase.useCaseId

  const handleAdminClick = (e: React.MouseEvent<any>) => {
    e.preventDefault()
    e.stopPropagation()

    if (onAdminButtonClick) {
      onAdminButtonClick(useCase.useCaseId)
    }
  }

  const handleItemClick = () => {
    trackEvent({
      componentName: 'useCasesListItem',
      actionName: TRACKING_ACTIONS.NAVIGATE,
    }, {
      useCaseId,
    })

    if (onItemClick) {
      onItemClick(useCase.useCaseId)
    }
  }

  return (
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      item={true}
      data-testid={UseCaseListItemComponent.name}
    >
      <Card
        elevation={0}
        role='listitem'
        tabIndex={0}
        sx={{
          position: 'relative',
          zIndex: 2,
          borderRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
          border: `1px solid ${theme.palette.new.business_black_20}`,
          transition: DEFAULT_BUTTON_TRANSITION,
          '& .useCaseListItemBlock': {
            transition: DEFAULT_BUTTON_TRANSITION,
          },
          ':hover': {
            border: `1px solid ${theme.palette.new.black}`,
          },
          '&:active:focus': {
            backgroundColor: theme.palette.new.smokey_silver,

            '& .useCaseListItemBlock': {
              backgroundColor: theme.palette.new.smokey_silver,
            },
          },
          '&:focus-visible': {
            outline: 'none',
            border: `1px solid ${theme.palette.new.black}`,
          },
          '&:focus': {
            outline: 'none',
          },
        }}
        onClick={handleItemClick}
        data-testid='useCaseCard'
      >
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'stretch',
              padding: theme.spacing(0),
              cursor: 'pointer',
              mr: isAdmin ? '160px' : '0px',
              overflow: 'hidden',
            }}
          >
            <UseCaseListItemBlockComponent
              title={intl.formatMessage({ id: 'use_cases.name.title' })}
              borderLeft={false}
              name='useCaseName'
              sx={{ width: '100%' }}
            >
              <Typography
                title={useCase.name}
                variant='subtitle1'
                noWrap={true}
              >
                {useCase.name}
              </Typography>
            </UseCaseListItemBlockComponent>

            <ForecastAddedValueComponent
              useCase={useCase}
            />

            <PotentialCoSavingsComponent
              useCase={useCase}
            />

            <TargetsBlockComponent
              useCase={useCase}
            />

            <UseCaseListItemBlockComponent
              title={intl.formatMessage({ id: 'use_cases.admin.slug' })}
              hidden={!isAdmin}
              name='fileStorageBucket'
              minWidth='180px'
            >
              <CopyButtonComponent
                name='copyFileStorageBucket'
                value={useCase.fileStorageBucket}
                reversed={true}
                label={truncateUseCaseSlug(useCase.fileStorageBucket)}
                maxValueWidth='160px'
                tooltip={useCase.fileStorageBucket}
              />
            </UseCaseListItemBlockComponent>

            <UseCaseListItemBlockComponent
              title={intl.formatMessage({ id: 'use_cases.admin.useCaseId' })}
              hidden={!isAdmin}
              name='useCaseId'
              minWidth='145px'
            >
              <CopyButtonComponent
                name='copyUseCaseId'
                value={useCase.useCaseId}
                label={intl.formatMessage({ id: 'use_cases.admin.copyId' })}
                reversed={true}
                maxValueWidth='145px'
                tooltip={useCase.useCaseId}
              />
            </UseCaseListItemBlockComponent>
          </Box>

          <UseCaseListItemBlockComponent
            title={intl.formatMessage({ id: 'use_cases.admin.title' })}
            hidden={!isAdmin}
            minWidth='160px'
            sx={{
              position: 'absolute',
              right: '0px',
              top: '0px',
              height: '100%',
              backgroundColor: theme.palette.new.white,
            }}
          >
            <ButtonComponent
              color='tertiary'
              name='useCaseAdminButton'
              label={intl.formatMessage({ id: 'use_cases.admin.button' })}
              onClick={handleAdminClick}
              StartIconComponent={GearIcon}
              sx={{
                position: 'absolute',
                bottom: '12px',
                left: '7px',
              }}
            />
          </UseCaseListItemBlockComponent>
        </Box>
      </Card>
    </Grid>
  )
}

export default UseCaseListItemComponent
