import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react'

import { useIntl } from 'react-intl'
import { useRouteMatch, matchPath } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'

import { requestDispatchedNotificationsAction } from '@redux/modules/notifications/notifications.actions'
import { getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'

import PageLayoutContainer from '@containers/application/PageLayout'
import CallToActionButtonComponent from '@base/pagebar/CallToActionButton'

import DispatchedNotificationsContainer from '@containers/pages/(company-global)/notifications/dispatched-notifications'
import NotificationsRoutingsContainer from '@containers/pages/(company-global)/notifications/notifications-routings'
import NotificationsSinksContainer from '@containers/pages/(company-global)/notifications/notifications-sinks'
import NotificationsTemplatesContainer from '@containers/pages/(company-global)/notifications/notifications-templates'
import NotificationsGlobalSinksContainer from '@containers/pages/(company-global)/notifications/notifications-global-sinks'

import {
  DISPATCHED_NOTIFICATIONS_PATH,
  NOTIFICATIONS_GLOBAL_SINKS_PATH,
  NOTIFICATIONS_SINKS_PATH,
  NOTIFICATIONS_TEMPLATES_PATH,
  USE_CASE_NOTIFICATIONS_DISPATCHED_PATH,
  USE_CASE_NOTIFICATIONS_ROUTINGS_PATH,
  USE_CASE_NOTIFICATIONS_SINKS_PATH,
} from '@constants/routes.constants'

import { getNotificationsRoutings, isFetchingDispatchedNotifications, isSettingUpDefaultRoutings } from '@redux/modules/notifications/notifications.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'

import PlusIcon from '@icons/plus.icon'
import ProgressIcon from '@icons/progress.icon'
import ShareIcon from '@icons/share.icon'

import {
  NOTIFICATIONS_ROUTINGS_MODAL_NAME,
  NOTIFICATIONS_ROUTINGS_SETUP_MODAL_NAME,
  NOTIFICATIONS_SEND_NOTIFICATION_MODAL_NAME,
  NOTIFICATIONS_SINKS_MODAL_NAME,
  NOTIFICATIONS_TEMPLATES_MODAL_NAME,
} from '@constants/modals.constants'

import ButtonComponent from '@base/buttons/Button/Button.component'
import { Box } from '@mui/material'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'

const NotificationsContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const useCaseItem = useSelector(getUseCaseItem)
  const selectedCompanyId = useSelector(getSelectedCompanyId)
  const isSettingUpRoutings = useSelector(isSettingUpDefaultRoutings)
  const isRefreshingDispatchedNotifications = useSelector(isFetchingDispatchedNotifications)
  const listOfRoutings = useSelector(getNotificationsRoutings)

  const {
    path,
    params: { usecase },
  } = useRouteMatch<Common.RouterMatch>()

  useEffect(() => {
    if (usecase) {
      dispatch(
        fetchUseCaseAction({
          useCaseId: usecase,
        }),
      )
    }
  }, [dispatch, usecase])

  const pathMap = useMemo(() => {
    return {
      [USE_CASE_NOTIFICATIONS_DISPATCHED_PATH]: matchPath(path, USE_CASE_NOTIFICATIONS_DISPATCHED_PATH),
      [DISPATCHED_NOTIFICATIONS_PATH]: matchPath(path, DISPATCHED_NOTIFICATIONS_PATH),

      [USE_CASE_NOTIFICATIONS_ROUTINGS_PATH]: matchPath(path, USE_CASE_NOTIFICATIONS_ROUTINGS_PATH),

      [NOTIFICATIONS_SINKS_PATH]: matchPath(path, NOTIFICATIONS_SINKS_PATH),
      [NOTIFICATIONS_GLOBAL_SINKS_PATH]: matchPath(path, NOTIFICATIONS_GLOBAL_SINKS_PATH),
      [USE_CASE_NOTIFICATIONS_SINKS_PATH]: matchPath(path, USE_CASE_NOTIFICATIONS_SINKS_PATH),

      [NOTIFICATIONS_TEMPLATES_PATH]: matchPath(path, NOTIFICATIONS_TEMPLATES_PATH),
    }
  }, [path])

  const onRefreshDispatchedNotifications = useCallback(() => {
    dispatch(
      requestDispatchedNotificationsAction({
        page: 1,
        companyId: selectedCompanyId,
        useCaseId: usecase,
      }),
    )
  }, [dispatch, selectedCompanyId, usecase])

  const onCreateTemplateButtonClick = useCallback(() => {
    dispatch(
      setPrimaryModalPageName(NOTIFICATIONS_TEMPLATES_MODAL_NAME),
    )
  }, [dispatch])

  const onCreateSinkButtonClick = useCallback(() => {
    dispatch(
      setPrimaryModalPageName(NOTIFICATIONS_SINKS_MODAL_NAME),
    )
  }, [dispatch])

  const onCreateRoutingButtonClick = useCallback(() => {
    dispatch(
      setPrimaryModalPageName(NOTIFICATIONS_ROUTINGS_MODAL_NAME),
    )
  }, [dispatch])

  const onSetupDefaultRoutingsButtonClick = useCallback(() => {
    dispatch(
      setPrimaryModalPageName(NOTIFICATIONS_ROUTINGS_SETUP_MODAL_NAME),
    )
  }, [dispatch])

  const onSendNotificationButtonClick = useCallback(() => {
    dispatch(
      setPrimaryModalPageName(NOTIFICATIONS_SEND_NOTIFICATION_MODAL_NAME),
    )
  }, [dispatch])

  const tabs = useMemo(() => {
    const tabsList = usecase ? [
      {
        label: intl.formatMessage({ id: 'notifications.dispatched.tab.use_case' }),
        path: USE_CASE_NOTIFICATIONS_DISPATCHED_PATH,
      },
      {
        label: intl.formatMessage({ id: 'notifications.routings.tab' }),
        path: USE_CASE_NOTIFICATIONS_ROUTINGS_PATH,
      },
      {
        label: intl.formatMessage({ id: 'notifications.sinks.tab.use_case' }),
        path: USE_CASE_NOTIFICATIONS_SINKS_PATH,
      },
    ] : [
      {
        label: intl.formatMessage({ id: 'notifications.dispatched.tab.company' }),
        path: DISPATCHED_NOTIFICATIONS_PATH,
      },
      {
        label: intl.formatMessage({ id: 'notifications.sinks.tab.company' }),
        path: NOTIFICATIONS_SINKS_PATH,
      },
      {
        label: intl.formatMessage({ id: 'notifications.sinks.tab.global' }),
        path: NOTIFICATIONS_GLOBAL_SINKS_PATH,
      },
      {
        label: intl.formatMessage({ id: 'notifications.templates.tab' }),
        path: NOTIFICATIONS_TEMPLATES_PATH,
      },
    ]

    return tabsList
  }, [intl, usecase])

  const content = useMemo(() => {
    if (pathMap[USE_CASE_NOTIFICATIONS_DISPATCHED_PATH] || pathMap[DISPATCHED_NOTIFICATIONS_PATH]) {
      return <DispatchedNotificationsContainer />
    }

    if (pathMap[USE_CASE_NOTIFICATIONS_ROUTINGS_PATH]) {
      return <NotificationsRoutingsContainer />
    }

    if (pathMap[USE_CASE_NOTIFICATIONS_SINKS_PATH] || pathMap[NOTIFICATIONS_SINKS_PATH]) {
      return <NotificationsSinksContainer />
    }

    if (pathMap[NOTIFICATIONS_GLOBAL_SINKS_PATH]) {
      return <NotificationsGlobalSinksContainer />
    }

    if (pathMap[NOTIFICATIONS_TEMPLATES_PATH]) {
      return <NotificationsTemplatesContainer />
    }

    return <DispatchedNotificationsContainer />
  }, [
    pathMap,
  ])

  const pageBarRightSideBlocks = useMemo(() => {
    if (pathMap[DISPATCHED_NOTIFICATIONS_PATH]) {
      return (
        <ButtonComponent
          name='refreshDispatchedNotificationsButton'
          onClick={onRefreshDispatchedNotifications}
          color='secondary'
          StartIconComponent={ProgressIcon}
          label={intl.formatMessage({ id: 'common.refresh' })}
          loading={isRefreshingDispatchedNotifications}
        />
      )
    }

    if (pathMap[USE_CASE_NOTIFICATIONS_DISPATCHED_PATH]) {
      return (
        <>
          <ButtonComponent
            name='refreshDispatchedNotificationsButton'
            onClick={onRefreshDispatchedNotifications}
            color='secondary'
            StartIconComponent={ProgressIcon}
            label={intl.formatMessage({ id: 'common.refresh' })}
            loading={isRefreshingDispatchedNotifications}
          />
          <CallToActionButtonComponent
            name='sendNotificationButton'
            onClick={onSendNotificationButtonClick}
            StartIconComponent={ShareIcon}
            label={intl.formatMessage({ id: 'notifications.dispatched.send_notification' })}
          />
        </>
      )
    }

    if (pathMap[USE_CASE_NOTIFICATIONS_ROUTINGS_PATH]) {
      return (
        <>
          <ButtonComponent
            name='setupDefaultRoutingsButton'
            onClick={onSetupDefaultRoutingsButtonClick}
            color='secondary'
            label={intl.formatMessage({ id: 'notifications.routings.setup_default' })}
            loading={isSettingUpRoutings}
            disabled={listOfRoutings.length > 0}
            disabledTooltip={intl.formatMessage({ id: 'notifications.routings.setup_default_disabled' })}
          />

          <CallToActionButtonComponent
            name='createNotificationsRoutingButton'
            StartIconComponent={PlusIcon}
            onClick={onCreateRoutingButtonClick}
            label={intl.formatMessage({ id: 'notifications.routings.add' })}
          />
        </>
      )
    }

    if (pathMap[USE_CASE_NOTIFICATIONS_SINKS_PATH] || pathMap[NOTIFICATIONS_SINKS_PATH] || pathMap[NOTIFICATIONS_GLOBAL_SINKS_PATH]) {
      return (
        <CallToActionButtonComponent
          name='createNotificationsSinkButton'
          onClick={onCreateSinkButtonClick}
          StartIconComponent={PlusIcon}
          label={intl.formatMessage({ id: 'notifications.sinks.add' })}
        />
      )
    }

    if (pathMap[NOTIFICATIONS_TEMPLATES_PATH]) {
      return (
        <CallToActionButtonComponent
          name='createNotificationsTemplateButton'
          onClick={onCreateTemplateButtonClick}
          StartIconComponent={PlusIcon}
          label={intl.formatMessage({ id: 'notifications.templates.add_template' })}
        />
      )
    }

    return null
  }, [
    intl,
    pathMap,
    isSettingUpRoutings,
    isRefreshingDispatchedNotifications,
    listOfRoutings,
    onSetupDefaultRoutingsButtonClick,
    onCreateTemplateButtonClick,
    onCreateSinkButtonClick,
    onCreateRoutingButtonClick,
    onSendNotificationButtonClick,
    onRefreshDispatchedNotifications,
  ])

  const pageTitle = useMemo(() => {
    if (pathMap[USE_CASE_NOTIFICATIONS_DISPATCHED_PATH]) {
      return intl.formatMessage({ id: 'notifications.dispatched.browser_tab.title.use_case' })
    }

    if (pathMap[DISPATCHED_NOTIFICATIONS_PATH]) {
      return intl.formatMessage({ id: 'notifications.dispatched.browser_tab.title.company' })
    }

    if (pathMap[USE_CASE_NOTIFICATIONS_ROUTINGS_PATH]) {
      return intl.formatMessage({ id: 'notifications.routings.browser_tab.title' })
    }

    if (pathMap[USE_CASE_NOTIFICATIONS_SINKS_PATH]) {
      return intl.formatMessage({ id: 'notifications.sinks.browser_tab.title.use_case' })
    }

    if (pathMap[NOTIFICATIONS_SINKS_PATH]) {
      return intl.formatMessage({ id: 'notifications.sinks.browser_tab.title.company' })
    }

    if (pathMap[NOTIFICATIONS_GLOBAL_SINKS_PATH]) {
      return intl.formatMessage({ id: 'notifications.sinks.browser_tab.title.global' })
    }

    if (pathMap[NOTIFICATIONS_TEMPLATES_PATH]) {
      return intl.formatMessage({ id: 'notifications.templates.browser_tab.title' })
    }

    return intl.formatMessage({ id: 'notifications.browser_tab.title' })
  }, [intl, pathMap])

  const customTitle = (usecase && useCaseItem && useCaseItem.name) ? intl.formatMessage({ id: 'notifications.drill_down.title' }, {
    name: (
      <Box component='span' fontWeight={300}>
        {useCaseItem.name}
      </Box>
    ),
  }) : undefined

  return (
    <PageLayoutContainer
      title={pageTitle}
      customTitle={customTitle}
      tabs={tabs}
      pageBarRightSideBlocks={pageBarRightSideBlocks}

    >
      {content}
    </PageLayoutContainer>
  )
}

export default NotificationsContainer
