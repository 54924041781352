import React from 'react'
import moment from 'moment'
import validator from 'validator'

import { IntlShape } from 'react-intl'
import { isRegularCell } from '@utils/data-grid.utils'
import { GridColDef, GridColType } from '@mui/x-data-grid-premium'
import {
  EMPTY_CSV_VALUE,
  PROCESSING_STATES,
  FILE_EXTENSION_TO_ICON_MAP,
  MAX_EDIT_FILE_SIZE_IN_BYTES,
  MAX_PREVIEW_FILE_SIZE_IN_BYTES,
  SUPPORTED_PREVIEW_FILE_TYPES,
  SUPPORTED_PREVIEW_FILE_TYPES_LIST,
  FILE_BROWSER_SORTING_DELIMITER_OPTIONS_LIST,
  SFTP_FOLDER_NAME,
  FILE_BROWSER_PREVIEW_OPTIONS_LIST,
} from '@constants/files.constants'

import { humanFileSize } from '@utils/common.utils'
import { formatDate } from '@utils/moment.utils'

import {
  generateDateTimeColumnDefinition,
  generateNumeralColumnDefinition,
  generateRegularColumnDefinition,
} from '@utils/data-grid-cells.utils'

import IntlFormatChipComponent from '@base/utils/IntlFormatChip'

export interface FileRecord {
  fileSizeBytes?: number
  fileName: string
  processingStatus?: PROCESSING_STATES
}

/**
 * Returns file extension
 *
 * @param fileName File name
 * @param isDirectory Is directory
 *
 * @returns file extension
 */
export const getFileExtension = (fileName: string, isDirectory = false) => {
  const parts = fileName.split('.')

  if (isDirectory) {
    return undefined
  }

  if (parts.length === 1) {
    return undefined
  }

  return (parts.pop() || '').toLowerCase()
}

/**
 * Get file name by file path
 * @param filePath file path
 * @returns file name
 */
export const getNameByFilePath = (filePath: string) => {
  const pathParts = filePath.split('/')
  const name = pathParts[pathParts.length - 1]

  return name
}

/**
 * Get PFP URL by bucket and path
 * @param bucket Bucket name
 * @param path file path
 * @returns PFP URL
 */
export const getPfpUrl = (bucket: string, path?: string) => {
  if (!bucket || !path) {
    return 'n/a'
  }

  return `pfp://${bucket}/${path}`
}

/**
 * Parse PFP path
 * @param pfpPath PFP path
 * @returns bucket, path, name
 */
export const parsePfpPath = (pfpPath: string) => {
  const [bucket, ...pathParts] = pfpPath.replace('pfp://', '').split('/')

  const path = pathParts.join('/')
  const name = getNameByFilePath(path)

  return { bucket, path, name }
}

/**
 * Returns true if file extension is supported for preview
 * @param fileExtension file extension
 * @returns true if file extension is supported for preview
 */
export const canPreviewFileWithExtension = (fileExtension?: string) => {
  if (!fileExtension) {
    return false
  }

  return (SUPPORTED_PREVIEW_FILE_TYPES_LIST.includes(fileExtension as SUPPORTED_PREVIEW_FILE_TYPES))
}

/**
 * Returns icon for file extension
 * @param fileExtension any file extension
 * @returns icon for file extension
 */
export const getFileIcon = (fileExtension?: string) => {
  if (!fileExtension) {
    return FILE_EXTENSION_TO_ICON_MAP.default
  }

  // @ts-ignore
  return FILE_EXTENSION_TO_ICON_MAP[fileExtension] || FILE_EXTENSION_TO_ICON_MAP.default
}

/**
 * Detects and configures column type based on the value
 * @param value Sample value to detect type
 * @returns Column type configuration
 */
export const detectColumnType = (value: string): GridColType | 'float' => {
  const isNumber = validator.isNumeric(value)
  const isFloat = validator.isFloat(value)
  const isInt = validator.isInt(value)
  const isDate = moment(value).isValid()

  if (isFloat && !isInt) {
    return 'float'
  }

  if (isNumber) {
    return 'number'
  }

  if (isDate) {
    return 'dateTime'
  }

  return 'string'
}

/**
 * Detects and configures column type based on the value
 * @param value Sample value to detect type
 * @returns Column type configuration
 */
export const getColumnConfigByType = (intl: IntlShape, column: string, type: GridColType | 'float'): Partial<GridColDef> => {
  const base = {
    intl,
    field: column,
    headerName: column,
    gridColDefOverrides: {
      minWidth: 174,
      width: undefined,
    },
  }

  if ((type === 'number') || (type === 'float')) {
    return generateNumeralColumnDefinition({
      ...base,
      float: type === 'float',
      gridColDefOverrides: {
        ...base.gridColDefOverrides,
        valueGetter: (cellValue: string) => {
          if (cellValue === '') {
            return null
          }

          const value = type === 'float' ? Number.parseFloat(cellValue) : Number.parseInt(cellValue, 10)

          if (isNaN(value)) {
            return null
          }

          return value
        },
      },
    })
  }

  if (type === 'dateTime' || type === 'date') {
    return generateDateTimeColumnDefinition({
      ...base,
      gridColDefOverrides: {
        ...base.gridColDefOverrides,
        valueFormatter: (value: Date) => {
          if (!value) {
            return null
          }

          return formatDate(intl, value.toString())
        },
      },
    })
  }

  return generateRegularColumnDefinition({
    ...base,
  })
}

/**
 * Generates columns for the data grid.
 * @param columns list of columns
 * @param row first row of data
 */
export const generateColumnsDefinitionsFromRow = ({
  intl,
  columns,
  firstRow,
  isEditable = false,
  useDynamicTyping = false,
}: {
  intl: IntlShape
  columns?: string[]
  firstRow?: Record<string, string | number>
  isEditable?: boolean
  useDynamicTyping?: boolean
}) => {
  if (!columns || columns.length === 0) {
    return []
  }

  return columns.map((column) => {
    const colDef = {
      field: column,
      headerName: column,
      type: 'string',
      editable: isEditable,
      width: 200,
      cellClassName: (params) => {
        if (!isRegularCell(params)) {
          return ''
        }

        const className = (params.value && params.value !== EMPTY_CSV_VALUE) ? '' : 'preview-empty-cell'

        return className
      },
    } as GridColDef

    if (firstRow && useDynamicTyping) {
      const value = firstRow[column] as string
      const type = detectColumnType(value)
      const typeConfig = getColumnConfigByType(intl, column, type)

      Object.assign(colDef, typeConfig)
    }

    return colDef
  })
}

/**
 * Returns true if processing status is not allowed for CSV preview
 * @param processingStatus processing status
 * @returns true if processing status is not allowed for CSV preview
 */
export const isProcessingStatusNotAllowedForCsvPreview = (processingStatus: PROCESSING_STATES) => {
  return [
    PROCESSING_STATES.ERROR,
    PROCESSING_STATES.QUEUED,
    PROCESSING_STATES.RUNNING,
  ].includes(processingStatus)
}

/**
 * Returns true if processing status is not allowed for CSV edit
 * @param processingStatus processing status
 * @returns true if processing status is not allowed for CSV edit
 */
export const isProcessingStatusNotAllowedForCsvEdit = (processingStatus: PROCESSING_STATES) => {
  return [
    PROCESSING_STATES.ERROR,
    PROCESSING_STATES.QUEUED,
    PROCESSING_STATES.RUNNING,
    PROCESSING_STATES.SUCCEEDED,
  ].includes(processingStatus)
}

/**
 * Returns true if file size is not allowed for preview
 * @param fileSize file size
 * @returns true if file size is not allowed for preview
 */
export const isFileSizeNotAllowedForPreview = (fileSize: number) => {
  return fileSize > MAX_PREVIEW_FILE_SIZE_IN_BYTES
}

/**
 * Returns true if file size is not allowed for edit
 * @param fileSize file size
 * @returns true if file size is not allowed for edit
 */
export const isFileSizeNotAllowedForEdit = (fileSize: number) => {
  return fileSize > MAX_EDIT_FILE_SIZE_IN_BYTES
}

/**
 * Returns true if file extension is not allowed
 * @param fileNameWithPath file name with path
 * @returns true if file extension is not allowed
 */
export const isFileExtNotAllowed = (fileNameWithPath = '', allowedExt = [SUPPORTED_PREVIEW_FILE_TYPES.CSV]) => {
  const fileName = getNameByFilePath(fileNameWithPath)
  const fileExt = getFileExtension(fileName, false)

  return !allowedExt.includes(fileExt as SUPPORTED_PREVIEW_FILE_TYPES)
}

/**
 * Returns CSV preview button label
 * @param intl intl
 * @param file File record
 * @returns CSV preview button label
 */
export const getCsvPreviewButtonLabel = (intl: IntlShape, {
  fileSizeBytes,
  fileName,
  processingStatus,
} : {
  fileSizeBytes?: number
  fileName: string
  processingStatus?: PROCESSING_STATES
}) => {
  const formattedFileSize = fileSizeBytes ? humanFileSize(fileSizeBytes, {
    maximumFractionDigits: 2,
  }) : 0

  if (isFileExtNotAllowed(fileName)) {
    return intl.formatMessage({ id: 'common.tables.actions.previewIsNotSupported' })
  }

  if (processingStatus && isProcessingStatusNotAllowedForCsvPreview(processingStatus)) {
    return intl.formatMessage({ id: 'fileManager.preview.dialog.fileStatus' })
  }

  if (isFileSizeNotAllowedForPreview(fileSizeBytes!)) {
    return intl.formatMessage({
      id: 'fileManager.preview.dialog.limit',
    }, {
      actualSize: formattedFileSize,
      limit: humanFileSize(MAX_PREVIEW_FILE_SIZE_IN_BYTES),
    })
  }

  return intl.formatMessage({ id: 'common.tables.actions.preview' })
}

/**
 * Returns CSV edit button label
 * @param intl intl
 * @param file File record
 * @returns CSV edit button label
 */
export const getCsvEditButtonLabel = (intl: IntlShape, {
  fileSizeBytes,
  fileName,
  processingStatus,
} : FileRecord) => {
  const formattedFileSize = fileSizeBytes ? humanFileSize(fileSizeBytes, {
    maximumFractionDigits: 2,
  }) : 0

  if (isFileExtNotAllowed(fileName)) {
    return intl.formatMessage({ id: 'fileManager.edit.dialog.notSupported' })
  }

  if (processingStatus && isProcessingStatusNotAllowedForCsvEdit(processingStatus)) {
    return intl.formatMessage({ id: 'fileManager.edit.dialog.fileStatus' })
  }

  if (isFileSizeNotAllowedForEdit(fileSizeBytes!)) {
    return intl.formatMessage({
      id: 'fileManager.edit.dialog.limit',
    }, {
      actualSize: formattedFileSize,
      limit: humanFileSize(MAX_EDIT_FILE_SIZE_IN_BYTES),
    })
  }

  return intl.formatMessage({ id: 'common.tables.actions.edit' })
}

/**
 * Returns true if CSV preview is disabled
 * @param record File record
 * @returns true if CSV preview is disabled
 */
export const isCsvPreviewDisabled = (record: FileRecord) => {
  return (record.processingStatus && isProcessingStatusNotAllowedForCsvPreview(record.processingStatus)) ||
          isFileSizeNotAllowedForPreview(record.fileSizeBytes!) ||
          isFileExtNotAllowed(record.fileName)
}

/**
 * Returns true if CSV edit is disabled
 * @param record File record
 * @returns true if CSV edit is disabled
 * */
export const isCsvEditDisabled = (record: FileRecord) => {
  return (record.processingStatus && isProcessingStatusNotAllowedForCsvEdit(record.processingStatus)) ||
          isFileSizeNotAllowedForEdit(record.fileSizeBytes!) ||
          isFileExtNotAllowed(record.fileName)
}

/**
 * Returns true if file preview is disabled
 * @param record File record
 * @returns true if CSV preview is disabled
 */
export const isFilePreviewDisabled = (record: FileRecord) => {
  return isFileSizeNotAllowedForPreview(record.fileSizeBytes!) || isFileExtNotAllowed(record.fileName, SUPPORTED_PREVIEW_FILE_TYPES_LIST)
}

/**
 * Returns File Preview button label
 * @param intl intl
 * @param file File record
 * @returns file preview button label
 */
export const getFilePreviewButtonLabel = (intl: IntlShape, {
  fileSizeBytes,
  fileName,
} : FileRecord) => {
  const formattedFileSize = fileSizeBytes ? humanFileSize(fileSizeBytes, {
    maximumFractionDigits: 2,
  }) : 0

  if (isFileExtNotAllowed(fileName, SUPPORTED_PREVIEW_FILE_TYPES_LIST)) {
    return intl.formatMessage({ id: 'common.tables.actions.filePreviewIsNotSupported' })
  }

  if (isFileSizeNotAllowedForPreview(fileSizeBytes!)) {
    return intl.formatMessage({
      id: 'fileManager.preview.dialog.limit',
    }, {
      actualSize: formattedFileSize,
      limit: humanFileSize(MAX_PREVIEW_FILE_SIZE_IN_BYTES),
    })
  }

  return intl.formatMessage({ id: 'common.tables.actions.preview' })
}

/**
 * Returns delimiter options for file preview
 * @param intl intl
 * @param autoDetectedDelimiter auto detected delimiter
 * @returns delimiter options for file preview
 */
export const getFilePreviewDelimiterOptions = (intl: IntlShape, autoDetectedDelimiter?: string) => {
  return FILE_BROWSER_SORTING_DELIMITER_OPTIONS_LIST.map((option) => {
    return {
      value: option.value,
      label: intl.formatMessage({
        id: option.label,
      }, {
        symbol: <IntlFormatChipComponent variant='code'>{option.symbol || autoDetectedDelimiter}</IntlFormatChipComponent>,
      }),
    }
  })
}

/**
 * Returns file preview preview mode options
 * @param intl intl
 * @returns file preview preview mode options
 */
export const getFilePreviewPreviewModeOptions = (intl: IntlShape) => {
  return FILE_BROWSER_PREVIEW_OPTIONS_LIST.map((option) => {
    return {
      value: option.value,
      label: option.beta ? (
        <>
          {intl.formatMessage({ id: option.label })}
          <IntlFormatChipComponent variant='primary'>
            {intl.formatMessage({ id: 'common.beta' })}
          </IntlFormatChipComponent>
        </>
      ) : (
        intl.formatMessage({ id: option.label })
      ),
    }
  })
}

/**
 * Returns use case SFTP folder
 * @param companySlug company slug
 * @param useCaseSlug use case slug
 * @returns use case SFTP folder
 */
export const getUseCaseSftFolder = (companySlug: string, useCaseSlug: string) => {
  return `${companySlug}/${useCaseSlug}`
}

/**
 * Returns SFTP search pattern
 * @param companySlug company slug
 * @param useCaseSlug use case slug
 * @returns SFTP search pattern
 */
export const getSftpSearchPattern = (companySlug: string, useCaseSlug: string) => {
  return `${companySlug}/${useCaseSlug}/**/*`
}

/**
 * Returns SFTP tree path
 * @param path path
 * @param companySlug company slug
 * @param useCaseSlug use case slug
 * @returns SFTP tree path
 */
export const getSftpTreePath = (path: string, companySlug: string, useCaseSlug: string) => {
  const sftpFolder = getUseCaseSftFolder(companySlug, useCaseSlug)

  return path.replace(sftpFolder, SFTP_FOLDER_NAME)
}

/**
 * Returns SFTP path by tree path
 * @param path path
 * @param companySlug company slug
 * @param useCaseSlug use case slug
 * @returns SFTP path by tree path
 */
export const getSftpPathByTreePath = (path: string, companySlug: string, useCaseSlug: string) => {
  const sftpFolder = getUseCaseSftFolder(companySlug, useCaseSlug)

  return path.replace(SFTP_FOLDER_NAME, sftpFolder)
}
