import React from 'react'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'
import { FormikProps } from 'formik'
import get from 'lodash.get'

import AutocompleteChipsFieldComponent from '@base/autocomplete/AutocompleteChipsField'
import TextFieldComponent from '@base/forms/TextField'
import SelectFieldComponent from '@base/forms/SelectField'
import IconButtonComponent from '@base/buttons/IconButton'
import DeleteIcon from '@icons/delete.icon'
import PlusIcon from '@icons/plus.icon'

import { FormLayoutItem } from '@base/forms/FormLayout'
import { EVALUATION_PROFILE_FILTER_OPERATORS, EVALUATION_PROFILE_FILTER_OPERATORS_OPTIONS_LIST } from '@constants/use-cases.constants'
import { useSelector } from '@redux/hooks'
import { getUseCaseAvailableMetaDataColumns } from '@redux/modules/use-case/use-case.selectors'

interface MetaDataFilterFieldProps {
  name: string
  form: FormikProps<any>
  remove: (index: number) => void
  push: (value: UseCase.EvaluationProfileMetadataItem) => void
}

const MetaDataFilterField: React.FC<MetaDataFilterFieldProps> = ({
  name,
  remove,
  push,
  form,
}) => {
  const intl = useIntl()
  const useCaseAvailableColumns = useSelector(getUseCaseAvailableMetaDataColumns)
  const globalError = typeof form.errors[name] === 'string' ? form.errors[name] : ''
  const fieldValues = form.values[name] as UseCase.EvaluationProfileMetadataItem[]
  const handleRemove = (index: number) => {
    remove(index)
  }

  const handleAddPair = () => {
    push({
      metaDataColumn: '',
      filterOperator: EVALUATION_PROFILE_FILTER_OPERATORS.EQUALS,
      filterValue: '',
    })
  }

  return (
    <FormLayoutItem xs={12} container={true} data-testid={name}>
      {
        fieldValues?.map((pair, index) => (
          <FormLayoutItem
            xs={12}
            key={index}
            container={true}
          >
            <FormLayoutItem xs={12} container={true}>
              <FormLayoutItem
                xs={true}
              >
                <AutocompleteChipsFieldComponent<string>
                  freeSolo={true}
                  name={`${name}.${index}.metaDataColumn`}
                  label={intl.formatMessage({ id: 'connect.modal.evaluationProfile.metadataFilter.column' })}
                  floatingHelp={intl.formatMessage({ id: 'connect.modal.evaluationProfile.metadataFilter.column.help' })}
                  placeholder={intl.formatMessage({ id: 'connect.modal.evaluationProfile.metadataFilter.column.placeholder' })}
                  value={pair.metaDataColumn ? [pair.metaDataColumn] : []}
                  errors={[((get(form.errors, `${name}.${index}.metaDataColumn`, '') as string) || globalError) as string] as string[]}
                  options={useCaseAvailableColumns}
                  getOptionLabel={(item: string) => item}
                  handleChangeCallback={(e: React.SyntheticEvent, selectedValue, newValue) => {
                    form.setFieldValue(`${name}.${index}.metaDataColumn`, selectedValue)
                  }}
                />
              </FormLayoutItem>
              <FormLayoutItem
                xs={true}
              >
                <SelectFieldComponent
                  name={`${name}.${index}.filterOperator`}
                  label={intl.formatMessage({ id: 'connect.modal.evaluationProfile.metadataFilter.operator' })}
                  placeholder={intl.formatMessage({ id: 'connect.modal.evaluationProfile.metadataFilter.operator' })}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  value={pair.filterOperator}
                  useIntlForLabel={true}
                  labelKey='labelKey'
                  options={EVALUATION_PROFILE_FILTER_OPERATORS_OPTIONS_LIST}
                />
              </FormLayoutItem>
              <FormLayoutItem
                xs={true}
              >
                <TextFieldComponent
                  name={`${name}.${index}.filterValue`}
                  label={intl.formatMessage({ id: 'connect.modal.evaluationProfile.metadataFilter.value' })}
                  placeholder={intl.formatMessage({ id: 'connect.modal.evaluationProfile.metadataFilter.value' })}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  value={pair.filterValue}
                  touched={get(form.touched, `${name}.${index}.filterValue`, false) as boolean}
                  errors={get(form.errors, `${name}.${index}.filterValue`, '') as string}
                />
              </FormLayoutItem>
              <FormLayoutItem
                xs='auto'
              >
                <Box
                  display='flex'
                  marginTop='18px'
                >
                  <IconButtonComponent
                    name={`removeFilter-${index}`}
                    color='secondary'
                    onClick={() => handleRemove(index)}
                    label={intl.formatMessage({ id: 'connect.modal.evaluationProfile.metadataFilter.remove' })}
                    IconComponent={DeleteIcon}
                  />
                </Box>
              </FormLayoutItem>
            </FormLayoutItem>
          </FormLayoutItem>
        ))
      }

      <FormLayoutItem xs={12}>
        <IconButtonComponent
          name='addFilter'
          onClick={handleAddPair}
          label={intl.formatMessage({ id: 'connect.modal.evaluationProfile.metadataFilter.add_filter' })}
          IconComponent={PlusIcon}
        />
      </FormLayoutItem>
    </FormLayoutItem>
  )
}

export default MetaDataFilterField
