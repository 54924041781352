import React, { useState, useLayoutEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { Box } from '@mui/material'

import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { COMPANY_MODAL_NAME } from '@constants/modals.constants'

import CompanyModalContainer, { CompanyModalPayload } from '@containers/modals/company-modal/CompanyModal.container'
import PageLayoutContainer from '@containers/application/PageLayout'
import FormDialogComponent from '@base/dialogs/FormDialog'
import IntlFormatBoldComponent from '@base/utils/IntlFormatBold'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'
import CompaniesOverviewComponent from '@containers/pages/(company-global)/companies/companies-overview'
import CallToActionButtonComponent from '@base/pagebar/CallToActionButton'
import TextFieldComponent from '@base/forms/TextField'
import PlusIcon from '@icons/plus.icon'

import { cloneCompanyAction, deleteCompanyAction, requestCompaniesListAction } from '@redux/modules/customer/customer.actions'

import {
  getCompaniesList,
  getCurrentUserInfo,
  isFetchingCompaniesList,
  isSubmitting,
} from '@redux/modules/customer/customer.selectors'

const CompaniesContainer = ({ keycloak } : { keycloak: KeycloakTypes.Keycloak }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [dialogState, setDialogState] = useState({
    open: false,
    companyId: '',
    companyName: '',
  })

  const [cloneDialogState, setCloneDialogState] = useState({
    open: false,
    companyId: '',
    companyName: '',
    cloneName: '',
  })

  const companiesList = useSelector(getCompaniesList)
  const isFetching = useSelector(isFetchingCompaniesList)
  const isSubmittingData = useSelector(isSubmitting)
  const modalPageName = useSelector(getOpenedModal)
  const userInfo = useSelector(getCurrentUserInfo)

  const onCreateButtonClick = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: COMPANY_MODAL_NAME,
        modalDetails: {
          record: null,
          returnTo: '',
        } as CompanyModalPayload,
      }),
    )
  }

  const pageBarRightSideBlocks = (
    <CallToActionButtonComponent
      name='createCompanyButton'
      StartIconComponent={PlusIcon}
      onClick={onCreateButtonClick}
      label={intl.formatMessage({ id: 'companies.elements.add_company' })}
    />
  )

  useLayoutEffect(() => {
    dispatch(
      requestCompaniesListAction(),
    )
  }, [dispatch])

  const handleEdit = useCallback((record: Customer.CompanyItem) => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: COMPANY_MODAL_NAME,
        modalDetails: {
          record,
          returnTo: '',
        } as CompanyModalPayload,
      }),
    )
  }, [dispatch])

  const handleCloneDialogClose = () => {
    setTimeout(() => {
      setCloneDialogState({
        open: false,
        companyId: '',
        companyName: '',
        cloneName: '',
      })
    }, 0)
  }

  const handleClone = () => {
    dispatch(
      cloneCompanyAction({
        companyId: cloneDialogState.companyId,
        cloneName: cloneDialogState.cloneName,
      }),
    )

    handleCloneDialogClose()
  }

  const handleDelete = (recordId: string) => {
    const shouldLogOut = userInfo.companyId === recordId

    dispatch(
      deleteCompanyAction({
        companyId: recordId,
      }),
    )

    setTimeout(() => {
      setDialogState({
        open: false,
        companyId: '',
        companyName: '',
      })

      if (shouldLogOut) {
        keycloak.logout()
      }
    }, 0)
  }

  const handleCompanyDelete = useCallback((record: Customer.CompanyItem) => {
    setDialogState({
      open: true,
      companyId: record.companyId,
      companyName: record.name,
    })
  }, [])

  const handleCompanyClone = useCallback((record: Customer.CompanyItem) => {
    setCloneDialogState({
      open: true,
      companyId: record.companyId,
      companyName: record.name,
      cloneName: '',
    })
  }, [])

  const setCloneName = (cloneName: string) => {
    setCloneDialogState({
      ...cloneDialogState,
      cloneName,
    })
  }

  return (
    <PageLayoutContainer
      pageBarRightSideBlocks={pageBarRightSideBlocks}
      title={intl.formatMessage({ id: 'companies.browser_tab.title' })}
    >
      <CompaniesOverviewComponent
        list={companiesList}
        handleEdit={handleEdit}
        handleDelete={handleCompanyDelete}
        handleClone={handleCompanyClone}
        isFetching={isSubmittingData || isFetching}
      />

      <CompanyModalContainer
        open={modalPageName === COMPANY_MODAL_NAME}
      />

      <TextConfirmationDialogComponent
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, companyId: '', companyName: '' })}
        onSubmit={() => handleDelete(dialogState.companyId)}
        confirmationText={dialogState.companyName}
        confirmationInputLabel={intl.formatMessage({ id: 'companies.table.dialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'companies.table.dialog.content' }, {
            name: <Box component='strong'>{dialogState.companyName}</Box>,
          })
        }
      />

      <FormDialogComponent
        open={cloneDialogState.open}
        onClose={handleCloneDialogClose}
        onSubmit={handleClone}
        disableSubmitButton={!(cloneDialogState.companyId) || !(cloneDialogState.cloneName)}
        title={intl.formatMessage({ id: 'companies.table.clone_dialog.title' })}
        description={
          intl.formatMessage({ id: 'companies.table.clone_dialog.content' }, {
            name: <IntlFormatBoldComponent>{cloneDialogState.companyName}</IntlFormatBoldComponent>,
          })
        }
      >
        <TextFieldComponent
          name='cloneName'
          value={cloneDialogState.cloneName}
          label={intl.formatMessage({ id: 'companies.table.clone_dialog.confirmation' })}
          onBlur={(e) => {
            setCloneName(e.target.value)
          }}
          onChange={(e) => {
            setCloneName(e.target.value)
          }}
        />
      </FormDialogComponent>
    </PageLayoutContainer>
  )
}

export default CompaniesContainer
