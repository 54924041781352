import React, { useMemo } from 'react'

import {
  Box, SxProps,
  Theme, Typography,
  useTheme,
} from '@mui/material'

import ButtonComponent from '@base/buttons/Button'
import ExpandMoreIcon from '@icons/expandMore.icon'
import ExpandLessIcon from '@icons/expandLess.icon'

import { ButtonComponentProps } from '@base/buttons/Button/Button.component'
import { TrackingEventNameProps } from '@utils/tracking.utils'

export interface DropdownButtonProps {
  /**
   * Click handler
   */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  /**
   * Overline text
   */
  overline?: string
  /**
   * Open state
   */
  open?: boolean
  /**
   * If 'true', the button will be outlined
   */
  outlined?: boolean
  /**
   * Ref to be forwarded to the button
   */
  handleRef?: React.Ref<any>
  /**
   * Data test id
   */
  dataTestId?: string
  /**
   * Button name
   */
  name: string
  /**
   * Button label
   */
  label: string | React.ReactNode
  /**
   * If 'true', the button will be disabled
   */
  disabled?: boolean
  /**
   * Aria label which would be used for accessibility instead of the button label
   */
  ariaLabel?: string
  /**
   * Max width before ellipsis is applied
   */
  maxWidth?: string
  /**
   * If 'true', the button will be bold
   */
  bold?: boolean
  /**
   * Button color
   *
   * @default 'tertiary'
   */
  color?: ButtonComponentProps['color']
  /**
   * Tracking props to be passed to the tracking event
   */
  trackingProps?: Partial<TrackingEventNameProps>
  /**
   * Custom styles
   */
  sx?: SxProps<Theme>
}

export const DropdownButtonComponent: React.FC<DropdownButtonProps> = ({
  dataTestId,
  onClick,
  name,
  open,
  label,
  disabled,
  overline,
  outlined,
  handleRef,
  ariaLabel,
  maxWidth,
  bold,
  trackingProps,
  color = 'tertiary',
  sx,
}) => {
  const theme = useTheme()
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e)
    }
  }

  const arrows = open ? ExpandLessIcon : ExpandMoreIcon
  const buttonSx = useMemo(() => ({
    ...sx,
    ...(overline ? {
      justifyContent: 'flex-start',
    } : {}),
    ...(open && outlined ? {
      outline: `1px solid ${theme.palette.new.business_black}`,
      backgroundColor: theme.palette.new.smokey_silver,
    } : {
      outline: `1px solid ${theme.palette.new.transparent}`,
    }),
  }), [open, outlined, overline, sx, theme])

  return (
    <Box
      data-testid={dataTestId || DropdownButtonComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
        maxWidth: '100%',
        zIndex: 3,
      }}
    >
      {
        overline ? (
          <Typography
            variant='overline'
            sx={{
              marginBottom: theme.spacing(0.2),
              marginLeft: '12px',
            }}
          >
            {overline}
          </Typography>
        ) : (
          null
        )
      }

      <ButtonComponent
        name={name}
        disabled={disabled}
        ref={handleRef}
        ariaLabel={ariaLabel}
        onClick={handleClick}
        color={color}
        rounded={true}
        label={label}
        EndIconComponent={arrows}
        maxWidth={maxWidth}
        bold={bold}
        sx={buttonSx}
        trackingProps={trackingProps}
      />
    </Box>
  )
}

export default DropdownButtonComponent
