export const BASE_URL = 'baseURL'
export const HEADERS = 'headers'
export const PARAMS = 'params'
export const TIMEOUT = 'timeout'
export const COMMON = 'common'

const DELETE = 'delete'
const GET = 'get'
const HEAD = 'head'
const POST = 'post'
const PUT = 'put'
const PATCH = 'patch'

export const AXIOS_TIMEOUT_IN_MS = 3600000
export const AXIOS_UPLOAD_TIMEOUT_IN_MS = 7200000

export const HEADER_KEYS = [COMMON, DELETE, GET, HEAD, POST, PUT, PATCH]

/* API RESOURCE KEYS */
export const KEYCLOAK = 'KEYCLOAK'
export const PIPELINES = 'PIPELINES'
export const USE_CASE = 'USE_CASE'
export const CUSTOMER = 'CUSTOMER'
export const FILES = 'FILES'
export const HERMES = 'HERMES'
export const HERA = 'HERA'
export const CHAT = 'CHAT'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const SNAPSHOTS = 'SNAPSHOTS'

/* AUTHENTICATION TOKEN KEYS */
export const AUTH_TOKEN = 'Authorization'
